export function immutableSplice(
  arr: any[],
  start: number,
  deleteCount: number,
  ...items: any
): any[] {
  return [...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount)];
}

export const updateArrayObject = (
  array: { id: string; [key: string]: any }[],
  object: { id: string; [key: string]: any }
) => {
  const i = array.findIndex((item) => item.id === object.id);
  return i > -1 ? immutableSplice(array, i, 1, object) : [...array, object];
};
