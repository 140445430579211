import React, { useState } from "react";
import moment, { Moment } from "moment";
import { TimeSlotButton, AddTimeslot } from "./TimeSlotComponents";
import uuid from "uuid";
import SettingsInput, { AddFlowButton } from "./SettingsInput";
import DayViewer from "./DayViewer";
import Dropdown from "components/basic/Dropdown";

interface Props {
  onSave?: (data: FullYearData) => void;
}

export type Schedule = {
  name: string;
  id: string;
  PeriodSetting: PeriodSetting[];
};

export type PeriodSetting = {
  id: string;
  calendar?: { label: string; id: string };
  type: PeriodType;
  timeslots: TimeSlot[];
};

export type PeriodType = "Weekends" | "Weekdays" | "Holidays" | "Custom";

export type TimeSlot = {
  id: string;
  startTime: Moment;
  endTime: Moment;
  settingID: string;
};

export type Setting = {
  flow: number;
  id: string;
  name: string;
  color: string;
};

const ScheduleInput: React.FC<Props> = ({ onSave }) => {
  const [schedule, setschedule] = useState<Schedule>(predefinedSchedules[0]);
  const [activePeriodID, setActivePeriodID] = useState<string>(
    predefinedSchedules[0].PeriodSetting[0].id
  );
  const [settings, setsettings] = useState<Setting[]>(intialFlow);

  ////Whenever new time period is input / updated
  const newPeriodTimeSetting = (newTimeslot: TimeSlot) => {
    //make sure the settings fit full day, the update the settings:
    const curPeriodSetting = schedule.PeriodSetting.find((ps) => ps.id === activePeriodID);
    const curPeriodSettingIndex = schedule.PeriodSetting.findIndex(
      (ps) => ps.id === activePeriodID
    );
    if (curPeriodSetting) {
      let timeslots = curPeriodSetting.timeslots;
      timeslots = getCleanTimeslots(timeslots, newTimeslot);
      const newPeriodSetting = {
        ...curPeriodSetting,
        timeslots,
      };
      if (curPeriodSetting)
        setschedule({
          ...schedule,
          PeriodSetting: [
            ...schedule.PeriodSetting.slice(0, curPeriodSettingIndex),
            newPeriodSetting,
            ...schedule.PeriodSetting.slice(curPeriodSettingIndex + 1),
          ],
        });
    }
  };
  const activePeriod = schedule.PeriodSetting.find((p) => p.id === activePeriodID);

  return (
    <>
      <label className="text-xs font-bold">Predefined schedule</label>
      <Dropdown
        className="px-4 py-1 border border-gray-400 my-1 text-sm"
        menuClassName="w-full"
        selectedID={schedule.id}
        onSelect={(option) => {
          const newSchedule = predefinedSchedules.find(
            (schedule) => schedule.id === option.id
          );
          if (newSchedule) {
            setschedule(newSchedule);
          }
        }}
        options={predefinedSchedules.map((s) => ({
          id: s.id,
          display: s.name,
          val: s,
        }))}
      />
      <div className="flex mt-4">
        <div className="w-1/2 pr-4 min-h-64 flex flex-col">
          <label className="text-xs font-bold mb-2">Schedule</label>
          <div className="bg-gray-100 flex-grow relative pb-10">
            <div className="bg-gray-300 flex">
              {schedule.PeriodSetting.map((daySetting) => {
                const isActive = activePeriodID === daySetting.id;
                return (
                  <button
                    key={daySetting.id}
                    className={`${tw.dayBtn} ${
                      isActive ? tw.activeDayBtn : tw.inactiveDayBtn
                    }`}
                    onClick={() => setActivePeriodID(daySetting.id)}
                  >
                    {daySetting.type}
                  </button>
                );
              })}
            </div>
            {activePeriod && (
              <div className="pt-4">
                <DayViewer timeslots={activePeriod.timeslots} settings={settings} />
              </div>
            )}
            <div className="px-4 pb-4">
              {activePeriod &&
                activePeriod.timeslots
                  .sort((a, b) => {
                    return a.endTime.valueOf() - b.endTime.valueOf();
                  })
                  .map((slot) => {
                    const setting = settings.find((s) => s.id === slot.settingID);
                    if (setting)
                      return (
                        <TimeSlotButton
                          key={slot.id}
                          slot={slot}
                          setting={setting}
                          onAdd={(newtimeslot) => {
                            newPeriodTimeSetting(newtimeslot);
                          }}
                          settings={settings}
                        />
                      );
                    else return null;
                  })}
            </div>
            <div className="absolute bottom-0 left-0 mb-4 w-full px-4">
              <AddTimeslot
                onAdd={(newtimeslot) => {
                  newPeriodTimeSetting(newtimeslot);
                }}
                settings={settings}
              />
            </div>
          </div>
        </div>

        <div className="w-1/2 min-h-64 pl-4 flex flex-col">
          <label className="text-xs font-bold mb-2">Settings</label>
          <div className="pb-10 bg-gray-100 relative flex-grow">
            <SettingsInput
              settings={settings}
              updateSettings={(newSettings) => setsettings(newSettings)}
            />
            <div className="absolute bottom-0 mb-4 left-0 w-full px-4">
              <AddFlowButton
                settings={settings}
                updateSettings={(newSettings) => setsettings(newSettings)}
              />
            </div>
          </div>
        </div>
      </div>
      {onSave && (
        <button
          className={`py-1 border border-gray-400 my-2`}
          onClick={() => {
            const flow = getFinalYearData(schedule, settings);
            onSave(flow);
          }}
        >
          Save json
        </button>
      )}
    </>
  );
};

const tw = {
  dayBtn: "px-4 py-2 text-xs font-bold focus:outline-none",
  activeDayBtn: "border-b-2 border-cm-blue",
  inactiveDayBtn: "opacity-75",
  timeslotBtn: "w-1/2 border border-gray-300 bg-white px-2 py-1 text-center text-xs",
};

export default ScheduleInput;

const intialFlow = [
  { flow: 60, id: "high", name: "High", color: "#636EFA" },
  { flow: 40, id: "medium", name: "Medium", color: "#FF6692" },
  { flow: 20, id: "low", name: "Low", color: "#FECB52" },
  { flow: 0.5, id: "off", name: "Off", color: "#555555" },
];

const predefinedSchedules: Schedule[] = [
  {
    name: "Office building",
    id: "ob",
    PeriodSetting: [
      {
        id: "weekday",
        type: "Weekdays",
        timeslots: [
          {
            id: uuid(),
            startTime: moment("06:00", "hh:mm"),
            endTime: moment("9:00", "HH:mm"),
            settingID: "medium",
          },
          {
            id: uuid(),
            startTime: moment("9:00", "hh:mm"),
            endTime: moment("17:00", "HH:mm"),
            settingID: "high",
          },
          {
            id: uuid(),
            startTime: moment("17:00", "hh:mm"),
            endTime: moment("22:00", "HH:mm"),
            settingID: "medium",
          },
        ],
      },
      {
        id: "weekend",
        type: "Weekends",
        timeslots: [
          {
            id: "1",
            startTime: moment("07:00", "hh:mm"),
            endTime: moment("19:00", "HH:mm"),
            settingID: "low",
          },
        ],
      },
      {
        id: "holiday",
        type: "Holidays",
        calendar: {
          label: "Weekends and Swedish National Holidays",
          id: "weekend-sweholiday",
        },
        timeslots: [
          {
            id: uuid(),
            startTime: moment("06:00", "hh:mm"),
            endTime: moment("9:00", "HH:mm"),
            settingID: "low",
          },
          {
            id: uuid(),
            startTime: moment("9:00", "hh:mm"),
            endTime: moment("17:00", "HH:mm"),
            settingID: "medium",
          },
          {
            id: uuid(),
            startTime: moment("17:00", "hh:mm"),
            endTime: moment("22:00", "HH:mm"),
            settingID: "low",
          },
        ],
      },
    ],
  },
  {
    name: "Department store",
    id: "ds",
    PeriodSetting: [
      {
        id: "weekend",
        type: "Weekends",
        timeslots: [
          {
            id: uuid(),
            startTime: moment("06:00", "hh:mm"),
            endTime: moment("9:00", "HH:mm"),
            settingID: "low",
          },
          {
            id: uuid(),
            startTime: moment("9:00", "hh:mm"),
            endTime: moment("11:00", "HH:mm"),
            settingID: "medium",
          },
          {
            id: uuid(),
            startTime: moment("11:00", "hh:mm"),
            endTime: moment("20:00", "HH:mm"),
            settingID: "high",
          },
          {
            id: uuid(),
            startTime: moment("20:00", "hh:mm"),
            endTime: moment("22:00", "HH:mm"),
            settingID: "low",
          },
        ],
      },
      {
        id: "weekday",
        type: "Weekdays",
        timeslots: [
          {
            id: uuid(),
            startTime: moment("09:00", "hh:mm"),
            endTime: moment("12:00", "HH:mm"),
            settingID: "low",
          },
          {
            id: uuid(),
            startTime: moment("12:00", "hh:mm"),
            endTime: moment("16:00", "HH:mm"),
            settingID: "medium",
          },
          {
            id: uuid(),
            startTime: moment("16:00", "hh:mm"),
            endTime: moment("20:00", "HH:mm"),
            settingID: "high",
          },
          {
            id: uuid(),
            startTime: moment("20:00", "hh:mm"),
            endTime: moment("22:00", "HH:mm"),
            settingID: "low",
          },
        ],
      },
      {
        id: "holiday",
        type: "Holidays",
        calendar: {
          label: "Weekends and Swedish National Holidays",
          id: "weekend-sweholiday",
        },

        timeslots: [
          {
            id: uuid(),
            startTime: moment("06:00", "hh:mm"),
            endTime: moment("9:00", "HH:mm"),
            settingID: "low",
          },
          {
            id: uuid(),
            startTime: moment("9:00", "hh:mm"),
            endTime: moment("11:00", "HH:mm"),
            settingID: "medium",
          },
          {
            id: uuid(),
            startTime: moment("11:00", "hh:mm"),
            endTime: moment("20:00", "HH:mm"),
            settingID: "high",
          },
          {
            id: uuid(),
            startTime: moment("20:00", "hh:mm"),
            endTime: moment("22:00", "HH:mm"),
            settingID: "low",
          },
        ],
      },
    ],
  },
  {
    name: "Always on",
    id: "ed",
    PeriodSetting: [
      {
        id: "weekend",
        type: "Weekends",
        timeslots: [
          {
            id: uuid(),
            startTime: moment("0:00", "hh:mm"),
            endTime: moment("24:00", "HH:mm"),
            settingID: "medium",
          },
        ],
      },
      {
        id: "weekday",
        type: "Weekdays",
        timeslots: [
          {
            id: uuid(),
            startTime: moment("0:00", "hh:mm"),
            endTime: moment("24:00", "HH:mm"),
            settingID: "medium",
          },
        ],
      },
      {
        id: "holiday",
        type: "Holidays",
        calendar: {
          label: "Weekends and Swedish National Holidays",
          id: "weekend-sweholiday",
        },
        timeslots: [
          {
            id: uuid(),
            startTime: moment("0:00", "hh:mm"),
            endTime: moment("24:00", "HH:mm"),
            settingID: "medium",
          },
        ],
      },
    ],
  },
];

//Inserts new timeslot into previous and makes sure no previous values overlap.
const getCleanTimeslots = (timeslots: TimeSlot[], newTimeslot: TimeSlot) => {
  const cleanTSStart: TimeSlot[] = [];
  const cleanTS = timeslots.reduce((prev, cur) => {
    //if cur is the one being updated:
    if (cur.id === newTimeslot.id) return prev;
    //if cur is between newTS completely, remove it
    if (
      cur.startTime.isBetween(newTimeslot.startTime, newTimeslot.endTime, undefined, "[]") &&
      cur.endTime.isBetween(newTimeslot.startTime, newTimeslot.endTime, undefined, "[]")
    )
      return prev;
    //if cur overlaps newTS completely, split it into two
    if (
      cur.startTime.isBefore(newTimeslot.startTime) &&
      cur.endTime.isAfter(newTimeslot.endTime)
    )
      return [
        ...prev,
        { ...cur, id: uuid(), endTime: newTimeslot.startTime },
        { ...cur, startTime: newTimeslot.endTime },
      ];

    //If overlap only one time with new TS:
    if (cur.startTime.isBetween(newTimeslot.startTime, newTimeslot.endTime, undefined, "[]"))
      return [...prev, { ...cur, startTime: newTimeslot.endTime.clone() }];
    if (cur.endTime.isBetween(newTimeslot.startTime, newTimeslot.endTime, undefined, "[]"))
      return [...prev, { ...cur, endTime: newTimeslot.startTime.clone() }];
    //no overlap..
    return [...prev, cur];
  }, cleanTSStart);

  return newTimeslot.settingID === "off" ? cleanTS : [...cleanTS, newTimeslot];
};

const getFinalYearData = (schedule: Schedule, settings: Setting[]) => {
  const momentIterater = moment().startOf("year");
  const endMoment = momentIterater.clone().add(1, "year");
  let flow: number[] = [];
  const dayCounter = { holiday: 0, weekday: 0, weekend: 0 };
  //Run once for each day during the year:
  while (momentIterater.isBefore(endMoment)) {
    //Check what type of day it is and get data fælowdata for the type of day.
    if (isHoliday(momentIterater, swedishHoliday)) {
      const period = schedule.PeriodSetting.find((p) => p.type === "Holidays");
      if (period) flow = [...flow, ...getDayData(period.timeslots, settings)];
      dayCounter.holiday++;
    } else if (isWeekend(momentIterater)) {
      const period = schedule.PeriodSetting.find((p) => p.type === "Weekends");
      if (period) flow = [...flow, ...getDayData(period.timeslots, settings)];
      dayCounter.weekend++;
    } else {
      const period = schedule.PeriodSetting.find((p) => p.type === "Weekdays");
      if (period) flow = [...flow, ...getDayData(period.timeslots, settings)];
      dayCounter.weekday++;
    }
    momentIterater.add(1, "day");
  }
  // console.log({ dayCounter });
  console.log({ flow });
  return { flow } as FullYearData;
};

const getDayData = (timeslots: TimeSlot[], settings: Setting[]) => {
  //Get data for all hours during a single day
  const flow: number[] = [];
  const time = moment("00:00", "HH:mm");
  const endTime = time.clone().add(1, "day");
  while (time.isBefore(endTime)) {
    const settingID = getSettingID(time, timeslots);
    const setting = settings.find((s) => s.id === settingID);
    flow.push(setting ? setting.flow : 0);
    time.add(1, "hour");
  }
  return flow;
};

//get the setting ID for a given time, based on timeslots
const getSettingID = (time: Moment, slots: TimeSlot[]) => {
  const slot = slots.find((ts) => time.isBetween(ts.startTime, ts.endTime, undefined, "(]"));
  return slot ? slot.settingID : "off";
};

const isWeekend = (date: Moment) => {
  const day = date.isoWeekday();
  return day > 5;
};

const isHoliday = (date: Moment, holidays: Moment[]) => {
  let holiday = false;
  swedishHoliday.forEach((day) => {
    if (day.isSame(date, "day")) holiday = true;
  });
  return holiday;
};

const swedishHoliday = [
  moment("6/1", "DD/MM"), //Epiphany
  moment("1/1", "DD/MM"), //new years day
  moment("31/12", "DD/MM"), //New years eve
  moment("24/12", "DD/MM"), //Christmas eve
  moment("25/12", "DD/MM"), //Christmas day
  moment("26/12", "DD/MM"), //Seccond christmas day
  moment("6/6", "DD/MM"), //National day
  // easter stuff......
];

type FullYearData = {
  flow: number[];
};
