import React, { useState } from "react";
import { Machine, MachineOption } from "../../../../model/dataTypes";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../../../reducers";
import { InputNum } from "../../basic/Input";
import {
  InputActionTypes,
  ADD_CUSTOM_MACHINE,
  UPDATE_CUSTOM_MACHINE,
} from "../../../../actions/inputActionTypes";
import firebase from "../../../../fb/firebase";
import uuid from "uuid/v4";
import Dropdown from "components/basic/Dropdown";
import Modal from "components/basic/Modal";

interface Props {
  onFinish: () => void;
  baseMachine?: Machine;
  editMode?: "duplicate" | "update"; //should a new machine be made or the old edited?
}

const CustomMachineInput: React.FC<Props> = ({ onFinish, baseMachine, editMode }) => {
  const dispatch = useDispatch();

  const [customMachine, setCustomMachine] = useState<Machine>(
    baseMachine
      ? {
          ...baseMachine,
          id: editMode === "update" ? baseMachine.id : uuid(),
          name: `${baseMachine.name} ${editMode === "update" ? "edited" : "copy"}`,
        }
      : getEmptyCustomMachine()
  );

  const [savingMachine, setSavingMachine] = useState(false);
  const setBaseMachine = (machine: Machine) => {
    const name = `Customised ${machine.name}`;
    setCustomMachine({
      ...machine,
      name,
      id: customMachine.id,
    });
  };

  const addMachineToApp = (machineOption: MachineOption) => {
    if (editMode && editMode === "update")
      dispatch<InputActionTypes>({
        type: UPDATE_CUSTOM_MACHINE,
        payload: { machineOption },
      });
    else
      dispatch<InputActionTypes>({
        type: ADD_CUSTOM_MACHINE,
        payload: { machineOption },
      });
    onFinish();
  };

  const onAddCustomMachine = (saveMachine: boolean) => {
    //check valid machine here..
    if (!savingMachine) {
      const machineOption: MachineOption = {
        label: customMachine.name,
        selected: true,
        filtered: false,
        id: customMachine.id,
        machine: customMachine,
        saved: saveMachine,
      };
      if (saveMachine) {
        setSavingMachine(true);
        firebase
          .saveCustomMachine(machineOption.machine)
          .then(() => {
            addMachineToApp(machineOption);
            setSavingMachine(false);
          })
          .catch((error) => {
            console.log(error);
            setSavingMachine(false);
          });
      } else {
        addMachineToApp(machineOption);
      }
    }
  };

  const setName = (name: string) => {
    setCustomMachine({ ...customMachine, name });
  };

  return (
    <Modal onClose={() => onFinish()}>
      <div className="z-50 bg-white px-8 py-8 flex flex-col w-4/5 md:w-3/4">
        <div className="text-xl font-bold mb-4">Input custom machine</div>
        {!baseMachine && (
          <>
            <div className="text-xs">
              <b>Select a base machine</b> <i>optional</i>
            </div>
            <MachineSelecter
              onSelectMachine={(machine) => {
                setBaseMachine(machine);
              }}
            />
          </>
        )}
        <div className="flex flex-col">
          <label className={twStyles.label}>Custom machine name</label>
          <input
            type="text"
            className="px-2 py-1 focus:outline-none border border-gray-400"
            value={customMachine.name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex py-4">
          <div className="flex-1 flex flex-col pr-4">
            <div className="font-bold">Heat exchanger dimensions</div>
            <label className={twStyles.label}>Height</label>
            <InputNum
              className={twStyles.numberInput}
              incrementBy={0.1}
              unit={"m"}
              showButtons
              value={customMachine.heatExhangerDimensions.height}
              onChange={(v) => {
                setCustomMachine({
                  ...customMachine,
                  heatExhangerDimensions: {
                    ...customMachine.heatExhangerDimensions,
                    height: v,
                  },
                });
              }}
            />
            <label className={twStyles.label}>Depth</label>
            <InputNum
              className={twStyles.numberInput}
              incrementBy={0.1}
              unit={"m"}
              showButtons
              value={customMachine.heatExhangerDimensions.depth}
              onChange={(v) => {
                setCustomMachine({
                  ...customMachine,
                  heatExhangerDimensions: {
                    ...customMachine.heatExhangerDimensions,
                    depth: v,
                  },
                });
              }}
            />
            <label className={twStyles.label}>Length</label>
            <InputNum
              className={twStyles.numberInput}
              incrementBy={0.1}
              unit={"m"}
              showButtons
              value={customMachine.heatExhangerDimensions.length}
              onChange={(v) => {
                setCustomMachine({
                  ...customMachine,
                  heatExhangerDimensions: {
                    ...customMachine.heatExhangerDimensions,
                    length: v,
                  },
                });
              }}
            />
          </div>

          <div className="flex-1 flex flex-col pl-4 pr-4">
            <div className="font-bold">Outer dimensions</div>
            <label className={twStyles.label}>Height</label>
            <InputNum
              className={twStyles.numberInput}
              incrementBy={0.1}
              unit={"m"}
              showButtons
              value={customMachine.outerDimensions.height}
              onChange={(v) => {
                setCustomMachine({
                  ...customMachine,
                  outerDimensions: { ...customMachine.outerDimensions, height: v },
                });
              }}
            />
            <label className={twStyles.label}>Depth</label>
            <InputNum
              className={twStyles.numberInput}
              incrementBy={0.1}
              unit={"m"}
              showButtons
              value={customMachine.outerDimensions.depth}
              onChange={(v) => {
                setCustomMachine({
                  ...customMachine,
                  outerDimensions: { ...customMachine.outerDimensions, depth: v },
                });
              }}
            />
            <label className={twStyles.label}>Length</label>
            <InputNum
              className={twStyles.numberInput}
              incrementBy={0.1}
              unit={"m"}
              showButtons
              value={customMachine.outerDimensions.length}
              onChange={(v) => {
                setCustomMachine({
                  ...customMachine,
                  outerDimensions: { ...customMachine.outerDimensions, length: v },
                });
              }}
            />
          </div>

          <div className="flex-1 flex flex-col pl-4">
            <div className="font-bold">Flow</div>
            <label className={twStyles.label}>Optimal Flow</label>
            <InputNum
              className={twStyles.numberInput}
              unit={"m³/s"}
              incrementBy={0.1}
              showButtons
              value={customMachine.optimalFlow}
              onChange={(v) => {
                setCustomMachine({ ...customMachine, optimalFlow: v });
              }}
            />
            <label className={twStyles.label}>Min Flow</label>
            <InputNum
              className={twStyles.numberInput}
              unit={"m³/s"}
              incrementBy={0.1}
              showButtons
              value={customMachine.minFlow}
              onChange={(v) => {
                setCustomMachine({ ...customMachine, minFlow: v });
              }}
            />
            <label className={twStyles.label}>Max Flow</label>
            <InputNum
              className={twStyles.numberInput}
              incrementBy={0.1}
              unit={"m³/s"}
              showButtons
              value={customMachine.maxFlow}
              onChange={(v) => {
                setCustomMachine({ ...customMachine, maxFlow: v });
              }}
            />
          </div>
        </div>
        <div className="flex w-full mt-2">
          <button
            onClick={() => onAddCustomMachine(false)}
            className={`${twStyles.btn}  flex-1 mr-2`}
          >
            {editMode === "update" ? "Update custom" : "Add custom"}
          </button>
          <button
            onClick={() => onAddCustomMachine(true)}
            className={`${twStyles.btn} flex-1 ml-2 `}
          >
            {editMode === "update" ? "Update and save custom" : "Add and save custom"}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const twStyles = {
  label: "text-xs font-bold mt-2",
  btn: "px-4 py-2 bg-cm-blue text-white font-bold text-xs rounded shadow",
  numberInput: "border border-gray-400 w-full",
};

const MachineSelecter: React.FC<{ onSelectMachine: (machine: Machine) => void }> = ({
  onSelectMachine,
}) => {
  const { machineOptions } = useSelector((state: StoreState) => state.input);
  const onSelect = (id: string) => {
    const selectedOption = machineOptions.find((option) => option.id === id);
    selectedOption && onSelectMachine(selectedOption.machine);
  };
  return (
    <Dropdown
      className="px-4 py-2 text-xs bg-cm-blue rounded text-white font-medium w-40"
      placeholder="Select machine"
      onSelect={(option) => {
        onSelect(option.id);
      }}
      options={machineOptions.map((MO) => ({ id: MO.id, display: MO.machine.name, val: MO }))}
    />
  );
};

export default CustomMachineInput;

const getEmptyCustomMachine = () =>
  ({
    outerDimensions: {
      depth: 0,
      height: 0,
      length: 0,
    },
    heatExhangerDimensions: {
      depth: 0,
      height: 0,
      length: 0,
    },
    id: uuid(),
    optimalFlow: 0.5,
    maxFlow: 1,
    minFlow: 0.1,
    name: "Custom machine 1",
  } as Machine);
