import React from "react";
import { LoggedAppUse } from "model/dataTypes";
import linkExternal from "../../resources/linkExternal.svg";
import { deleteAppUse, updateAppUse } from "actions/fsActions";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  item?: LoggedAppUse;
}

const InfoPane: React.FC<Props> = ({ item, className }) => {
  const deleteDate = item?.created.clone().add(14, "days");

  return (
    <div className={`${className} flex`}>
      {item ? (
        <div className="flex flex-col text-gray-text p-8">
          <div className="mb-8 flex flex-col">
            <span className="text-xs leading-tight mr-3 text-gray-600">
              Email address
            </span>
            <a href={`mailto:${item.mail}`} className=" hover:text-blue-600">
              {item.mail}
            </a>
          </div>

          <div className="mb-8 flex flex-col">
            <span className="text-xs leading-tight mr-3 text-gray-600">
              Simulation run at
            </span>
            <span>{item.created.format("ddd, MMM Do, HH:mm")}</span>
          </div>

          <div className="mb-8 flex flex-col">
            <span className="text-xs leading-tight mr-3 text-gray-600">
              Simulation link
            </span>
            <a
              className="hover:text-blue-600"
              href={item.simulationLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.simulationLink}{" "}
              <img src={linkExternal} alt="" className="inline h-4 w-4" />
            </a>
          </div>

          <div className="mb-8 flex flex-col">
            <span className="text-xs leading-tight mr-3 text-gray-600">
              User contact
            </span>
            <span className="flex items-center">
              <input
                type="checkbox"
                className="mr-2"
                checked={item.isContacted}
                value="userContact"
                onChange={() => {
                  updateAppUse({ ...item, isContacted: !item.isContacted });
                }}
              />
              The user has {!item.isContacted && "not "}been contacted
            </span>
          </div>

          <div className="flex text-xs text-gray-500 mt-auto mb-0">
            This entry will be auto-deleted {deleteDate?.format("ddd, MMM Do")}
            <span
              onClick={() => deleteAppUse(item.id)}
              className="text-red-500 hover:text-red-700 underline cursor-pointer ml-3"
            >
              delete now
            </span>
          </div>
        </div>
      ) : (
        <div className="text-sm text-gray-600 m-auto">
          Select an entry in the sidebar to see more info
        </div>
      )}
    </div>
  );
};

export default InfoPane;
