import React, { useState } from "react";
import Modal from "components/basic/Modal";
import SidebarList from "./SidebarList";
import InfoPane from "./InfoPane";
import { LoggedAppUse } from "model/dataTypes";
import { updateAppUse } from "actions/fsActions";
import moment from "moment";
import { useLoggedAppUse } from "fb/useFirestore";

interface Props {
  onClose: () => void;
}

const LoggedUseModal: React.FC<Props> = ({ onClose }) => {
  const [selectedItem, setSelectedItem] = useState<LoggedAppUse>();
  const appUse = useLoggedAppUse().sort(chronological);

  return (
    <Modal onClose={onClose}>
      <div
        className="w-5/6 bg-white z-40 flex rounded overflow-hidden"
        style={{ height: "80vh", minHeight: "400px" }}
      >
        {appUse.length > 0 ? (
          <>
            <SidebarList
              items={appUse}
              selectItem={(updated: LoggedAppUse) => {
                setSelectedItem(updated);
                if (updated.isNew) updateAppUse({ ...updated, isNew: false });
              }}
              selectedItem={selectedItem}
              className="w-1/3 h-full"
            />
            <InfoPane
              item={appUse.find((item) => item.id === selectedItem?.id)}
              className="w-2/3"
            />
          </>
        ) : (
          <div className="w-full h-full flex items-center justify-center">
            No app uses has been logged during the past 30 days.
          </div>
        )}
      </div>
    </Modal>
  );
};

export default LoggedUseModal;

const chronological = (a: LoggedAppUse, b: LoggedAppUse) => {
  return moment(a.created).isAfter(b.created)
    ? -1
    : moment(a.created).isBefore(b.created)
    ? 1
    : 0;
};
