import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";
import CMLogo from "./basic/CMLogo";
import userIcon from "../resources/userIcon.svg";
import HoverMenu from "./basic/HoverMenu";
import { logOut } from "actions/fsActions";

interface Props {
  showModal: () => void;
}

const Header: React.FC<Props> = ({ showModal }) => {
  const isAuthenticated = useSelector(
    (state: StoreState) => state.user.authenticated
  );

  const dispatch = useDispatch();

  const adminOptions = [
    <div
      className="py-2 px-6 cursor-pointer hover:bg-gray-100 text-xs text-gray-700 w-32"
      onClick={showModal}
    >
      See app uses
    </div>,
    <div
      className="py-2 px-6 cursor-pointer hover:bg-gray-100 text-xs text-gray-700 w-32"
      onClick={() => {
        dispatch(logOut());
      }}
    >
      logout
    </div>,
  ];

  return (
    <div
      className="fixed w-full flex justify-between items-center top-0 left-0 px-4 sm:px-24 xl:px-48"
      style={{
        zIndex: 5,
        backgroundColor: "#b0b0b0",
        boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.7)",
        borderBottom: "0px solid rgba(176, 176, 176, 1)",
      }}
    >
      <div className="flex items-end py-4 px-4">
        <a href="https://climatemachines.com">
          <CMLogo />
        </a>
        <h1 className="text-xs sm:text-lg text-white ml-6 -mb-2 font-bold">
          Product configurator
        </h1>
      </div>
      {isAuthenticated && (
        <HoverMenu options={adminOptions} className="bg-white rounded-full">
          <img src={userIcon} alt="" className="w-6 h-6" />
        </HoverMenu>
      )}
    </div>
  );
};

export default Header;
