import React, { useEffect } from "react";
import InputContainer from "../basic/InputContainer";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../reducers";
import MachineFilter from "./machineFilter/MachineFilter";
import { getMachines } from "../../../actions/fsActions";
import AdminOptions from "./admin/AdminOptions";
import MachineList from "./MachineList";

const MachineSelector: React.FC<{}> = () => {
  const dispatch = useDispatch();

  const { autoSuggestMachine, machineOptions } = useSelector(
    (state: StoreState) => state.input
  );
  const amountSelectedMachines = machineOptions.filter(
    (option) => option.selected && !option.filtered
  ).length;

  const useAdminMode = useSelector((state: StoreState) => state.user.useAdminMode);

  useEffect(() => {
    if (machineOptions.length === 0) {
      dispatch(getMachines());
    }
  }, [machineOptions, dispatch]);

  return (
    <InputContainer
      headline="Select machines"
      number={1}
      info={
        <div className="w-48 text-xs">
          Select one or more of our default machines to use for simulation.
        </div>
      }
      isActive={autoSuggestMachine || amountSelectedMachines > 0}
    >
      <div className="pb-16">
        <div className="rounded border border-gray-200 bg-light-gray">
          <MachineFilter />
          <MachineList />
        </div>
        {!useAdminMode && (
          <div className="italic text-xs mt-4">
            *All machines can be customized to fit your demand - contact us to learn more about
            our custom solutions.
          </div>
        )}
        {useAdminMode && <AdminOptions />}
      </div>
    </InputContainer>
  );
};

export default MachineSelector;
