import React, { useState } from "react";
import { LoadScript, Autocomplete } from "@react-google-maps/api";

interface Props {
  onSelect: (loc: { lat: number; lng: number } | null) => void;
}
const libraries = ["places"];

const LocationInput: React.FC<Props> = ({ onSelect }) => {
  const [autocomplete, setautocomplete] = useState<google.maps.places.Autocomplete | null>(
    null
  );
  return (
    <LoadScript
      id="script-loader"
      libraries={libraries}
      googleMapsApiKey="AIzaSyCvQF74ysWaGwhthXGTV5QMiiSxI08NJTY"
    >
      <Autocomplete
        onLoad={ac => {
          setautocomplete(ac);
        }}
        onPlaceChanged={() => {
          if (autocomplete) {
            const place = autocomplete.getPlace();
            if (place.geometry) {
              const loc = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              };
              onSelect(loc);
            } else {
              onSelect(null);
            }
          }
        }}
      >
        <input
          className={`${twStyles.inputBox}`}
          type="text"
          placeholder={"eg. Stockholm..."}
        />
      </Autocomplete>
    </LoadScript>
  );
};
const twStyles = {
  inputBox: "focus:outline-none border border-gray-400 px-4 py-2 w-full",
};

export default LocationInput;
