import { useEffect } from "react";

export const useClickOutsideEffect = (
  ref: React.RefObject<HTMLDivElement> | React.RefObject<HTMLElement>[],
  onClickOutside: () => void
) => {
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      let clickedInside = false;
      if (Array.isArray(ref)) {
        ref.forEach((r) => {
          if (r.current && e.target instanceof Node && r.current.contains(e.target)) {
            clickedInside = true;
          }
        });
      } else {
        if (ref.current && e.target instanceof Node && ref.current.contains(e.target)) {
          clickedInside = true;
        }
      }
      if (!clickedInside) onClickOutside();
    };
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref, onClickOutside]);
};
