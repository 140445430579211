import { Condition, MachineOption } from "../model/dataTypes";
export const TOGGLE_MACHINE = "TOGGLE_MACHINE";
export const LOAD_MACHINES = "LOAD_MACHINES";
export const TOGGLE_AUTO_SUGGEST_MACHINE = "TOGGLE_AUTO_SUGGEST_MACHINE";
export const UPDATE_CONDITION = "UPDATE_CONDITION";
export const ADD_CONDITION = "ADD_CONDITION";
export const REMOVE_CONDITION = "REMOVE_CONDITION";
export const UPDATE_FLOW_FILTER = "UPDATE_FLOW_FILTER";
export const UPDATE_DIMENSION_FILTER = "UPDATE_DIMENSION_FILTER";
export const LOAD_SAVED_INPUT = "LOAD_SAVED_INPUT";
export const ERROR_LOADING_SAVED_INPUT = "ERROR_LOADING_SAVED_INPUT";
export const ADD_CUSTOM_MACHINE = "ADD_CUSTOM_MACHINE";
export const UPDATE_CUSTOM_MACHINE = "UPDATE_CUSTOM_MACHINE";

interface ToggleMachineAction {
  type: typeof TOGGLE_MACHINE;
  payload: { activated: boolean; machine: MachineOption };
}

interface LoadMachinesAction {
  type: typeof LOAD_MACHINES;
  payload: { machines: MachineOption[] };
}

interface ToggleSuggestMachineAction {
  type: typeof TOGGLE_AUTO_SUGGEST_MACHINE;
  payload: boolean;
}

interface UpdateConditionAction {
  type: typeof UPDATE_CONDITION;
  payload: { editedCondition: Condition };
}

interface AddContitionAction {
  type: typeof ADD_CONDITION;
  payload: { condition: Condition; position?: number };
}

interface RemoveContitionAction {
  type: typeof REMOVE_CONDITION;
  payload: { id: string };
}

interface UpdateFlowFilter {
  type: typeof UPDATE_FLOW_FILTER;
  payload: { minFlow: number; maxFlow: number };
}

interface UpdateDimensionsFilterAction {
  type: typeof UPDATE_DIMENSION_FILTER;
  payload: {
    maxDepth: number | null;
    maxLength: number | null;
    maxHeight: number | null;
  };
}

interface LoadSavedInputAction {
  type: typeof LOAD_SAVED_INPUT;
  payload: {
    machineOptions: MachineOption[];
    conditions: Condition[];
  };
}
interface ErrorLoadingSavedInputAction {
  type: typeof ERROR_LOADING_SAVED_INPUT;
}

interface AddCustomMachine {
  type: typeof ADD_CUSTOM_MACHINE;
  payload: {
    machineOption: MachineOption;
  };
}

interface UpdateCustomMachine {
  type: typeof UPDATE_CUSTOM_MACHINE;
  payload: {
    machineOption: MachineOption;
  };
}

export type InputActionTypes =
  | LoadMachinesAction
  | ToggleMachineAction
  | ToggleSuggestMachineAction
  | UpdateConditionAction
  | AddContitionAction
  | RemoveContitionAction
  | UpdateFlowFilter
  | UpdateDimensionsFilterAction
  | LoadSavedInputAction
  | ErrorLoadingSavedInputAction
  | AddCustomMachine
  | UpdateCustomMachine;
