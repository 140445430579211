import React from "react";
import { useSavedSimulations } from "fb/useFirestore";
import { Link } from "react-router-dom";

interface Props {
  onClose: () => void;
}

const ViewSimulations: React.FC<Props> = ({ onClose }) => {
  const { savedSimulations } = useSavedSimulations();
  return (
    <div
      className="z-50 bg-white px-8 py-4 flex flex-col w-4/5 lg:w-3/4 overflow-auto"
      style={{ maxHeight: "90vh" }}
    >
      <div className="text-xl mb-4 font-medium">Saved Simulations</div>
      {savedSimulations.map((sim) => {
        const machineSimulated = sim.machineOptions.filter((o) => o.selected).length;
        return (
          <div
            key={sim.id}
            className="flex justify-between my-1 px-4 py-2 bg-gray-100 rounded"
          >
            <span>{sim.created.format("DD/MM YYYY hh:mm")}</span>
            <span>{machineSimulated} machines</span>
            <span>{sim.conditions.length} cases</span>
            <Link to={`/saved/${sim.id}`} target="_blank">
              Open simulation
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default ViewSimulations;
