import {
  InputActionTypes,
  LOAD_MACHINES,
  TOGGLE_AUTO_SUGGEST_MACHINE,
  TOGGLE_MACHINE,
  UPDATE_CONDITION,
  ADD_CONDITION,
  REMOVE_CONDITION,
  UPDATE_FLOW_FILTER,
  UPDATE_DIMENSION_FILTER,
  LOAD_SAVED_INPUT,
  ADD_CUSTOM_MACHINE,
  ERROR_LOADING_SAVED_INPUT,
  UPDATE_CUSTOM_MACHINE,
} from "../actions/inputActionTypes";
import { immutableSplice } from "../utility/Array";
import { Condition, MachineOption } from "../model/dataTypes";
import { FirestoreActionTypes } from "../actions/firestoreActionTypes";

export type InputState = {
  machineOptions: MachineOption[];
  autoSuggestMachine: boolean;
  conditions: Condition[];
  flowFilter: { minFlow: number; maxFlow: number };
  dimensionFilter: {
    maxDepth: number | null;
    maxLength: number | null;
    maxHeight: number | null;
  };
  savedInputLoaded: boolean;
};

const initialState: InputState = {
  machineOptions: [],
  autoSuggestMachine: false,
  conditions: [],
  flowFilter: { minFlow: 0, maxFlow: 16 },
  dimensionFilter: { maxDepth: null, maxLength: null, maxHeight: null },
  savedInputLoaded: false,
};

export function inputReducer(
  state = initialState,
  action: InputActionTypes | FirestoreActionTypes
): InputState {
  switch (action.type) {
    case TOGGLE_AUTO_SUGGEST_MACHINE:
      return { ...state, autoSuggestMachine: action.payload };
    case LOAD_MACHINES:
      //dont overwrite if already loaded from saved simulation:
      return {
        ...state,
        machineOptions: !state.savedInputLoaded
          ? action.payload.machines
          : state.machineOptions,
      };
    case TOGGLE_MACHINE:
      const { activated, machine } = action.payload;
      const toggledMachine = { ...machine, selected: activated };
      const mIndex = state.machineOptions.findIndex((m) => m.id === machine.id);
      return {
        ...state,
        machineOptions: immutableSplice(state.machineOptions, mIndex, 1, toggledMachine),
      };
    case UPDATE_CONDITION:
      const { editedCondition } = action.payload;
      const cIndex = state.conditions.findIndex((c) => {
        return c.id === editedCondition.id;
      });
      return {
        ...state,
        conditions: immutableSplice(state.conditions, cIndex, 1, editedCondition),
      };
    case ADD_CONDITION:
      const i = action.payload.position;
      const newConditions = i
        ? immutableSplice(state.conditions, i, 0, action.payload.condition)
        : [...state.conditions, action.payload.condition];
      return { ...state, conditions: newConditions };
    case REMOVE_CONDITION:
      return {
        ...state,
        conditions: state.conditions.filter((c) => c.id !== action.payload.id),
      };
    case UPDATE_FLOW_FILTER:
      return {
        ...state,
        flowFilter: action.payload,
        machineOptions: getFilteredMachines(
          state.machineOptions,
          action.payload,
          state.dimensionFilter
        ),
      };
    case UPDATE_DIMENSION_FILTER:
      return {
        ...state,
        dimensionFilter: action.payload,
        machineOptions: getFilteredMachines(
          state.machineOptions,
          state.flowFilter,
          action.payload
        ),
      };
    case LOAD_SAVED_INPUT:
      return {
        ...state,
        machineOptions: action.payload.machineOptions,
        conditions: action.payload.conditions,
        autoSuggestMachine: false,
        savedInputLoaded: true,
      };
    case ERROR_LOADING_SAVED_INPUT:
      return {
        ...state,
      };
    case ADD_CUSTOM_MACHINE:
      return {
        ...state,
        machineOptions: [...state.machineOptions, action.payload.machineOption],
      };
    case UPDATE_CUSTOM_MACHINE:
      return {
        ...state,
        machineOptions: [
          ...state.machineOptions.filter((mo) => mo.id !== action.payload.machineOption.id),
          action.payload.machineOption,
        ],
      };
    default:
      return state;
  }
}

const getFilteredMachines = (
  machineOptions: MachineOption[],
  flowFilter: InputState["flowFilter"],
  dimensionFilter: InputState["dimensionFilter"]
) => {
  return machineOptions.map((option) => {
    const fFilter =
      flowFilter.minFlow > option.machine.optimalFlow ||
      flowFilter.maxFlow < option.machine.optimalFlow;

    const dFilter =
      (dimensionFilter.maxLength &&
        dimensionFilter.maxLength < option.machine.outerDimensions.length) ||
      (dimensionFilter.maxHeight &&
        dimensionFilter.maxHeight < option.machine.outerDimensions.height) ||
      (dimensionFilter.maxDepth &&
        dimensionFilter.maxDepth < option.machine.outerDimensions.depth);
    return { ...option, filtered: fFilter || !!dFilter };
  });
};
