import React from "react";

interface Props {
  number: number;
  isActive: boolean;
  hideHr?: boolean;
}

const StatusNumber: React.FC<Props> = ({ number, isActive, hideHr }) => {
  return (
    <div className="hidden sm:flex flex-none w-12 md:w-20 lg:w-32 justify-center">
      <div className="flex flex-col items-center w-8 h-full">
        <RoundNumber isActive={isActive} number={number} />

        {!hideHr && (
          <hr className={"border-solid flex-grow w-0 border-l border-gray-400 my-4"}></hr>
        )}
      </div>
    </div>
  );
};

export default StatusNumber;

export const RoundNumber: React.FC<Props> = ({ isActive, number }) => {
  return (
    <div
      className={`rounded-full h-8 w-8 flex items-center justify-center text-white ${
        isActive ? "bg-cm-blue" : "bg-gray-400"
      }`}
    >
      {number}
    </div>
  );
};
