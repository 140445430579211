export const fbConfig = {
  apiKey: "AIzaSyArUILZEXqYtR9n5rW1cYVkYJgEgIVTx7w",
  authDomain: "climatemachines.firebaseapp.com",
  databaseURL: "https://climatemachines.firebaseio.com",
  projectId: "climatemachines",
  storageBucket: "climatemachines.appspot.com",
  messagingSenderId: "53459581968",
  appId: "1:53459581968:web:ceeb1a51524b02e92ede95",
  measurementId: "G-LEBY08N8P3",
};
