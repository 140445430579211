import React, { useState, useEffect } from "react";

interface Props {
  unit: string;
  value: number | null;
  placeholder?: string;
  onChange: (v: number) => void;
  showButtons?: boolean;
  onlyPositive?: boolean;
  incrementBy?: number;
  className?: string;
}

export const InputNum: React.FC<Props> = ({
  unit,
  value,
  onChange,
  showButtons,
  onlyPositive,
  placeholder,
  className,
  incrementBy = 1,
}) => {
  const [displayVal, setdisplayVal] = useState<string>(
    typeof value === "number" ? value.toString() : ""
  );

  useEffect(() => {
    if (value !== null) setdisplayVal(value.toString());
  }, [value]);

  return (
    <div
      className={`flex items-center justify-between px-1
    ${className ? className : ""}`}
    >
      <div className="flex flex-col h-6 w-6 text-xs">
        {showButtons && (
          <>
            <svg
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                let newValue = value ? value + incrementBy : 1;
                newValue = parseFloat(newValue.toFixed(2));
                onChange(newValue);
                setdisplayVal(newValue.toString());
              }}
              width="24px"
              fill="currentColor"
              height="24px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.57804987,13.6200909 L10.6278453,8.57029543 C11.3882393,7.80990152 12.6165679,7.80990152 13.3769618,8.57029543 L18.4267573,13.6200909 C19.6550859,14.8484195 18.7777083,16.9541258 17.0424504,16.9541258 L6.94285946,16.9541258 C5.20760155,16.9541258 4.34972124,14.8484195 5.57804987,13.6200909 Z"
                id="Shape"
                fillRule="nonzero"
              ></path>
            </svg>

            <svg
              className="cursor-pointer"
              onClick={(e) => {
                e.preventDefault();
                let newValue = value ? value - incrementBy : 0;
                newValue = parseFloat(newValue.toFixed(2));
                onChange(newValue);
                setdisplayVal(
                  newValue < 0 && onlyPositive ? "0" : newValue.toString()
                );
              }}
              width="24px"
              fill="currentColor"
              height="24px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.57804987,13.6200909 L10.6278453,8.57029543 C11.3882393,7.80990152 12.6165679,7.80990152 13.3769618,8.57029543 L18.4267573,13.6200909 C19.6550859,14.8484195 18.7777083,16.9541258 17.0424504,16.9541258 L6.94285946,16.9541258 C5.20760155,16.9541258 4.34972124,14.8484195 5.57804987,13.6200909 Z"
                id="Shape"
                fillRule="nonzero"
                transform="translate(12.000000, 12.477063) scale(1, -1) translate(-12.000000, -12.477063) "
              ></path>
            </svg>
          </>
        )}
      </div>
      <div className="flex-grow flex items-center justify-end py-1">
        <input
          className="w-full bg-transparent text-right focus:outline-none"
          type="text"
          value={displayVal}
          placeholder={placeholder}
          onChange={(e) => {
            let val = parseFloat(e.target.value);
            val = parseFloat(val.toFixed(2));
            onlyPositive && (val = Math.abs(val));
            onChange(val);
            const displayVal = e.target.value.replace(/[^\d|.|-]/, "");
            setdisplayVal(displayVal);
          }}
        />
        <div>{unit}</div>
      </div>
    </div>
  );
};

interface InputTextProps {
  value: string;
  className?: string;
  placeholder?: string;
  onChange: (v: string) => void;
}
export const InputText: React.FC<InputTextProps> = ({
  value,
  onChange,
  placeholder,
  className,
}) => {
  return (
    <input
      className={`${
        twStyles.inputBox
      } px-2 py-1 border border-white  bg-transparent ${
        className ? className : ""
      }`}
      type="text"
      value={value}
      placeholder={placeholder}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
  );
};

const twStyles = {
  inputBox: "focus:outline-none",
  inputBoxStd: "border border-gray-400 px-4 py-2",
};
