import React, { useState } from "react";
import ScheduleInput from "./input/operationInput/schedule/ScheduleInput";
import LocationInput from "./input/operationInput/location/LocationInput";
import {
  getClosestWeatherStations,
  LocationResponse,
  WeatherData,
  getWeatherData,
} from "api/WeatherAPI";
import Weather from "./input/operationInput/weather/Weather";

interface Props {}

const DynamicTestPage = (props: Props) => {
  const [locationResponse, setlocationResponse] = useState<LocationResponse | null>(null);
  const [weatherData, setWeatherData] = useState<WeatherData | null>(null);

  const download = (jsonData: { [key: string]: number[] }, fileName: string) => {
    let dataStr = JSON.stringify(jsonData);
    let dataUri = "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);

    let exportFileDefaultName = `${fileName}.json`;

    let linkElement = document.createElement("a");
    linkElement.setAttribute("href", dataUri);
    linkElement.setAttribute("download", exportFileDefaultName);
    linkElement.click();
  };

  return (
    <div className="px-16 py-8">
      <div className="w-full my-4 flex flex-col ant-select-overwrite">
        <ScheduleInput
          onSave={(flow) => {
            download(flow, "flowdata");
          }}
        />
      </div>
      <div className="w-full my-16 flex flex-col">
        <div className="text-2xl">WEATHER INPUT</div>
        <label className="text-xs font-bold">Location</label>
        <LocationInput
          onSelect={(location) => {
            if (location) {
              getClosestWeatherStations(location).then((res) => {
                setlocationResponse(res);
              });
            } else {
              setlocationResponse(null);
            }
          }}
        />
        {locationResponse && (
          <div className="my-1">
            Closest weather station: {locationResponse.location.name},{" "}
            {locationResponse.location.distance.toFixed(1)}km away.
          </div>
        )}
        <Weather avgWeather={locationResponse?.weather} />
      </div>
      <button
        onClick={() => {
          locationResponse &&
            getWeatherData(locationResponse.location.name)
              .then((res) => {
                setWeatherData(res);
              })
              .catch((e) => {
                console.log(e);
              });
        }}
        className="px-2 py-1 bg-cm-blue text-white rounded mr-4"
      >
        Get weatherdata
      </button>
      {weatherData && (
        <button
          className="px-2 py-1 bg-cm-blue text-white rounded"
          onClick={() => download(weatherData, "weatherdata")}
        >
          Download JSON
        </button>
      )}
      {weatherData && (
        <>
          <div className="w-3/4 mx-auto bg-gray-100 p-4 overflow-auto h-128 my-4">
            {JSON.stringify(weatherData, null, 1)}
          </div>
        </>
      )}
    </div>
  );
};

export default DynamicTestPage;
