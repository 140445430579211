import { APIResult, Condition, Machine } from "../model/dataTypes";

export const RESULTS_LOADED = "RESULTS_LOADED";
export const ERROR_LOADING_SIMULATION = "ERROR_LOADING_SIMULATION";
export const SIMULATION_REQUESTED = "SIMULATION_REQUESTED";

interface ResultsLoadedAction {
  type: typeof RESULTS_LOADED;
  payload: APIResult;
}

interface SimulationErrorAction {
  type: typeof ERROR_LOADING_SIMULATION;
  payload: { errorMessage: string };
}

interface SimulationRequested {
  type: typeof SIMULATION_REQUESTED;
  payload: { conditions: Condition[]; machines: Machine[] };
}
export type ResultActionTypes =
  | ResultsLoadedAction
  | SimulationErrorAction
  | SimulationRequested;
