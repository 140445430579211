import React, { useState } from "react";
import { Machine } from "../../../model/dataTypes";
import { MachineIcon } from "../../basic/MachineIcon";
import { useSelector } from "react-redux";
import { StoreState } from "reducers";
import HoverMenu from "components/basic/HoverMenu";
import CustomMachineInput from "./admin/CustomMachineInput";

interface Props {
  machine: Machine;
  onSelect: () => void;
  checked: boolean;
  allowEdit?: true;
  saved?: boolean;
  filtered?: boolean;
}

const MachineCard: React.FC<Props> = ({
  machine,
  onSelect,
  checked,
  filtered,
  allowEdit,
  saved,
}) => {
  const useAdminMode = useSelector((state: StoreState) => state.user.useAdminMode);
  const [editingMachine, setEditingMachine] = useState(false);
  const [customEditMode, setCustomEditMode] = useState<"duplicate" | "update">("duplicate");

  const renderAdminOptions = () => {
    const menu = [
      <div
        onClick={(e) => {
          setCustomEditMode("duplicate");
          setEditingMachine(true);
        }}
        className="py-2 px-6 cursor-pointer hover:bg-gray-100 text-xs text-gray-700"
      >
        Create copy
      </div>,
    ];
    if (saved === false)
      menu.push(
        <div
          onClick={(e) => {
            setCustomEditMode("update");
            setEditingMachine(true);
          }}
          className="py-2 px-6 cursor-pointer hover:bg-gray-100 text-xs text-gray-700"
        >
          Edit
        </div>
      );
    return (
      <div className="absolute top-0 right-0 mt-2 mr-2">
        <HoverMenu options={menu}>
          <svg
            className="w-4 h-4"
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
          </svg>
        </HoverMenu>
      </div>
    );
  };

  return (
    <div
      key={machine.id}
      className={`relative bg-white ${tw.tableCard} ${
        filtered
          ? "opacity-50 bg-simulation-gray"
          : checked
          ? "shadow-md border-cm-blue"
          : "shadow border-gray-200"
      }`}
    >
      <div
        className={`w-full rounded flex py-3 border-2 relative hover:shadow-lg ${
          checked ? "border-cm-blue" : "border-gray-100"
        }`}
        onClick={() => onSelect()}
      >
        <div className="border-r border-gray-400 px-2 flex items-center">
          <MachineIcon machineID={machine.id} className="w-20" />
        </div>
        <div className="px-4 flex-grow">
          <div className="font-bold mb-1">{machine.name}</div>

          <div className="flex w-full text-xs">
            <div className="flex-1 pr-3">
              <div className="font-bold">Flow</div>
              <div className={`flex items-center justify-between`}>
                <div>Optimal flow</div>
                <div>{machine.optimalFlow.toFixed(1)}m³/s</div>
              </div>
              <div className={`flex items-center justify-between`}>
                <div>Minimum flow</div>
                <div>{machine.minFlow.toFixed(1)}m³/s</div>
              </div>
              <div className={`flex items-center justify-between`}>
                <div>Maximum flow</div>
                <div>{machine.maxFlow.toFixed(1)}m³/s</div>
              </div>
            </div>

            {useAdminMode && (
              <div className="flex-1 px-3">
                <div className="font-bold">Heat Exchanger size</div>
                <div className={`flex items-center justify-between`}>
                  <div>Height:</div>
                  <div>{machine.heatExhangerDimensions.height.toFixed(3)}m</div>
                </div>
                <div className={`flex items-center justify-between`}>
                  <div>Depth:</div>
                  <div>{machine.heatExhangerDimensions.depth.toFixed(3)}m</div>
                </div>
                <div className={`flex items-center justify-between`}>
                  <div>Length:</div>
                  <div>{machine.heatExhangerDimensions.length.toFixed(3)}m</div>
                </div>
              </div>
            )}

            <div className="flex-1 pl-3">
              <div className="font-bold">Machine size</div>
              <div className={`flex items-center justify-between`}>
                <div>Height:</div>
                <div>{machine.outerDimensions.height.toFixed(3)}m</div>
              </div>
              <div className={`flex items-center justify-between`}>
                <div>Depth:</div>
                <div>{machine.outerDimensions.depth.toFixed(3)}m</div>
              </div>
              <div className={`flex items-center justify-between`}>
                <div>Length:</div>
                <div>{machine.outerDimensions.length.toFixed(3)}m</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {useAdminMode && allowEdit && renderAdminOptions()}
      {checked && (
        <svg
          className="absolute w-8 top-0 -mt-3 left-0 -ml-3"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path
            d="M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z"
            id="Shape"
            fill="#5BC4EC"
          ></path>
          <path
            d="M9.29,16.29 L5.7,12.7 C5.31,12.31 5.31,11.68 5.7,11.29 C6.09,10.9 6.72,10.9 7.11,11.29 L10,14.17 L16.88,7.29 C17.27,6.9 17.9,6.9 18.29,7.29 C18.68,7.68 18.68,8.31 18.29,8.7 L10.7,16.29 C10.32,16.68 9.68,16.68 9.29,16.29 Z"
            id="Path"
            fill="#FFFFFF"
          ></path>
        </svg>
      )}
      {editingMachine && (
        <CustomMachineInput
          baseMachine={machine}
          onFinish={() => {
            setEditingMachine(false);
          }}
          editMode={customEditMode}
        />
      )}
    </div>
  );
};

const tw = {
  tableCard: "cursor-pointer w-full rounded border",
};

export default MachineCard;
