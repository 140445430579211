import React, { useEffect } from "react";
import Modal from "./basic/Modal";
import { useParams, useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { loadSavedSimulation } from "../actions/fsActions";
import { StoreState } from "../reducers";
import MainPage from "./MainPage";
import LoadingIcon from "./basic/LoadingIcon/LoadingIcon";

interface Props {}

const SavedSimulation: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { fbuuid } = useParams<{ fbuuid: string | undefined }>();
  const { loadingSavedSimulation } = useSelector((state: StoreState) => state.result);
  const { savedInputLoaded } = useSelector((state: StoreState) => state.input);
  useEffect(() => {
    if (fbuuid) dispatch(loadSavedSimulation(fbuuid));
  }, [fbuuid, dispatch]);

  return (
    <>
      {fbuuid && !savedInputLoaded ? (
        <Modal
          onClose={() => {
            if (!loadingSavedSimulation) history.push("/");
          }}
        >
          <div className="text-gray-700 w-3/4 z-50 bg-white h-64 flex items-center justify-center">
            {loadingSavedSimulation ? (
              <>
                <span className="font-medium mr-2">Loading saved simulation</span>
                <LoadingIcon />
              </>
            ) : (
              <span>Loading error...</span>
            )}
          </div>
        </Modal>
      ) : null}
      <MainPage />
    </>
  );
};

export default SavedSimulation;
