import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../reducers";
import {
  composeMail,
  saveAppUse,
  saveSimulation,
} from "../../actions/fsActions";
import Toast from "components/basic/Toast";
import LoadingIcon from "../basic/LoadingIcon/LoadingIcon";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import QR from "qrcode";
import { SET_QR_CODE, SHOW_PDF_SETTINGS } from "actions/pdfActionTypes";
import firebase from "../../fb/firebase";
import { mailAddresses, mailSubject, mailBody } from "utility/NewUseMail";

interface Props {
  refToPrint: React.RefObject<any>;
}

const SaveView: React.FC<Props> = ({ refToPrint }) => {
  const dispatch = useDispatch();
  const { fbUUID, savingSimulation } = useSelector(
    (state: StoreState) => state.result
  );
  const isAuthenticated = useSelector(
    (state: StoreState) => state.user.authenticated
  );

  const handlePrint = useReactToPrint({
    content: () => refToPrint.current,
  });

  const [email, setEmail] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [qrCodeImg, setqrCodeImg] = useState<string | null>(null);

  const link = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);

  const baseURL = window.location.protocol + "//" + window.location.host;
  const simulationLink = `${baseURL}/saved/${fbUUID}`;

  const copyLinkToClipboard = () => {
    if (link && link.current) {
      link.current.select();
      document.execCommand("copy");
      Toast("Link copied to clipboard");
    }
  };

  useEffect(() => {
    if (fbUUID) {
      QR.toDataURL(simulationLink).then((url) => {
        setqrCodeImg(url);
      });
    }
  }, [fbUUID, simulationLink]);

  const handleSave = () => {
    const { isValid, msg } = checkEmailValidity();
    setEmailIsValid(isValid);
    setEmailErrorMsg(msg);

    if (isValid) {
      saveAppUse(email, simulationLink);

      handlePrint();

      // send mail to admins
      mailAddresses.forEach((mail) => {
        composeMail(mail, mailSubject, mailBody);
      });
    }
  };

  const checkEmailValidity = () => {
    const inputIsNotEmpty = !!emailRef.current?.value.length;
    return {
      isValid: emailRef.current
        ? inputIsNotEmpty && emailRef.current.checkValidity()
        : false,
      msg: emailRef.current?.validationMessage || "",
    };
  };

  return (
    <div className="container mt-8 mx-auto text-gray-text">
      <div className="lg:px-16 flex flex-wrap justify-center">
        {isAuthenticated && (
          <div className={`${twStyles.col}`}>
            <div className="text-xl border-b border-gray-200">Export</div>
            <button
              className={`my-6 ${twStyles.exportBtn}`}
              onClick={() => {
                dispatch({ type: SET_QR_CODE, payload: qrCodeImg });
                dispatch({ type: SHOW_PDF_SETTINGS });
                firebase.logEvent("generate_pdf");
              }}
            >
              GENERATE PDF
            </button>
            <ReactToPrint
              trigger={() => (
                <button className={`my-6 ${twStyles.exportBtn}`}>
                  PRINT RESULTS
                </button>
              )}
              content={() => refToPrint.current}
            />
          </div>
        )}
        <div className={`${twStyles.col}`}>
          <div className="text-xl border-b border-gray-200">Save</div>

          {fbUUID ? (
            <div className="text-xs">
              <div className="font-bold mt-6 mb-1">Simulation URL</div>
              <div className={`${twStyles.shareBox}`}>
                <input
                  type="text"
                  value={simulationLink}
                  readOnly
                  ref={link}
                  className="focus:outline-none flex-grow truncate mr-2"
                />
                <button
                  onClick={(e) => {
                    copyLinkToClipboard();
                  }}
                  className={`${twStyles.blueBtn}`}
                >
                  COPY
                </button>
              </div>
              {!isAuthenticated && (
                <>
                  <div className="font-bold mt-6 mb-1">Save this result</div>
                  <p className="text-xs text-gray-600 mb-4">
                    Please input your email below to save the result. Your email
                    may be used for providing additional simulation support.
                    Emails are stored for 30 days.
                  </p>
                  <div
                    className={`${
                      emailIsValid ? twStyles.shareBox : twStyles.shareBoxError
                    } text-sm`}
                  >
                    <input
                      ref={emailRef}
                      type="email"
                      placeholder="Mail address"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      className="focus:outline-none flex-grow pr-2"
                    />
                    <button
                      className={`${twStyles.blueBtn}`}
                      onClick={handleSave}
                    >
                      SAVE
                    </button>
                  </div>

                  {!!emailErrorMsg && (
                    <span className="text-xs text-red-300">
                      {emailErrorMsg}
                    </span>
                  )}
                </>
              )}

              <div className="w-full flex justify-center">
                {qrCodeImg && <img src={qrCodeImg} alt="qr code" />}
              </div>
            </div>
          ) : (
            <div className="text-xs">
              <div className="font-bold mt-6 mb-1">
                Save this simulation for later
              </div>
              <button
                className={`relative ${twStyles.blueBtn} ${
                  savingSimulation ? "cursor-not-allowed opacity-75" : ""
                }`}
                onClick={() => {
                  !savingSimulation && dispatch(saveSimulation());
                }}
              >
                SAVE
                {savingSimulation && (
                  <span className="absolute right-0 mr-1 top-1/2 -mt-2">
                    <LoadingIcon />
                  </span>
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const twStyles = {
  exportBtn: "text-xs font-bold w-40 py-2 border border-gray-400 block",
  shareBox: "flex w-full border border-gray-200 py-2 px-4",
  shareBoxError: "flex w-full border border-red-200 py-2 px-4",
  blueBtn: "text-white w-20 py-1 bg-cm-blue cursor-pointer shadow",
  col: "w-full sm:w-1/2 lg:w-2/5 px-8",
};

export default SaveView;
