import { Condition } from "model/dataTypes";

const CheckECool = (condition: Condition) => {
  return condition.extractAir >= 22 && condition.outsideAir >= 16;
};

export const hasManualEcool = (condition: Condition) => {
  return typeof condition.evapCooling === "number";
};

export const manualEcoolActive = (condition: Condition) => {
  return typeof condition.evapCooling === "number" && condition.evapCooling > 0;
};

export default CheckECool;
