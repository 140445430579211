import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";
import { fbConfig } from "./fbconfig";
import {
  Condition,
  LoggedAppUse,
  Machine,
  MachineOption,
  SavedSimulation,
  SimJob,
} from "../model/dataTypes";
import moment from "moment";
import GetSimulationsRequests from "utility/GetSimulationsRequests";

type FSSavedSimulation = {
  conditions: Condition[];
  machineOptions: MachineOption[];
  created: firebase.firestore.Timestamp;
  simJob_refs: string[];
};
type SimRequest = {
  conditions: Condition[];
  machines: Machine[];
  isAdmin: boolean;
};

class Firebase {
  constructor() {
    app.initializeApp(fbConfig);
    this.analytics = app.analytics();
    this.auth = app.auth();
    this.db = app.firestore();
    // app.functions();
    // .useFunctionsEmulator("http://localhost:5001");
    // this.functions = app.functions();
  }
  auth: app.auth.Auth;
  db: app.firestore.Firestore;
  analytics: app.analytics.Analytics;

  saveSimulation(
    conditions: Condition[],
    machineOptions: MachineOption[]
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      this.db
        .collection("simulations")
        .add({
          conditions,
          machineOptions,
          created: app.firestore.Timestamp.fromDate(new Date()),
        })
        .then((doc) => resolve(doc.id))
        .catch((err) => reject("Error saving simulation"));
    });
  }

  composeMail(
    to: string | string[],
    subject: string,
    body: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      this.db
        .collection("outgoingMail")
        .add({
          from: "info@climatemachines.com",
          to,
          message: {
            subject,
            html: body,
          },
        })
        .then(() => resolve())
        .catch((error) => reject(`Error composing outgoing mail - ${error}`));
    });
  }

  saveAppUse(mail: string, simulationLink: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.db
        .collection("loggedAppUses")
        .add({
          mail,
          simulationLink,
          created: app.firestore.Timestamp.fromDate(new Date()),
          isNew: true,
          isContacted: false,
        })
        .then(() => resolve())
        .catch((error) => reject(`Error saving App use - ${error}`));
    });
  }

  updateAppUse(entry: LoggedAppUse): Promise<void> {
    return new Promise((resolve, reject) => {
      this.db
        .collection("loggedAppUses")
        .doc(entry.id)
        .update({
          isNew: entry.isNew || false,
          isContacted: entry.isContacted || false,
        })
        .then(() => resolve())
        .catch((error) => reject(`Error updating app use - ${error}`));
    });
  }

  deleteAppUse(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.db
        .collection("loggedAppUses")
        .doc(id)
        .delete()
        .then(() => resolve())
        .catch((error) => reject(`Error deleting app use - ${error}`));
    });
  }

  logEvent(eventName: string, eventParams?: { [key: string]: any }) {
    this.analytics.logEvent(eventName, eventParams);
  }

  getSimulation(uuid: string): Promise<SavedSimulation> {
    return new Promise((resolve, reject) => {
      this.db
        .collection("simulations")
        .doc(uuid)
        .get()
        .then((doc) => {
          const data = doc.data() as FSSavedSimulation;
          const savedSimulation: SavedSimulation = {
            id: doc.id,
            ...data,
            created: moment(data.created.toDate()),
          };
          if (doc.exists && data) resolve(savedSimulation);
          else reject("error getting the simulation");
        })
        .catch((err) => reject(err));
    });
  }

  getMachines(): Promise<Machine[]> {
    return new Promise((resolve, reject) => {
      this.db
        .collection("machines")
        .orderBy("maxFlow")
        .get()
        .then((querySnapshot) => {
          const machines: Machine[] = [];
          querySnapshot.forEach((doc) => {
            const data = doc.data() as Machine;
            if (doc.exists && data) machines.push(data);
          });
          resolve(machines);
        })
        .catch((error) => {
          reject("DB Error getting machines");
        });
    });
  }

  saveCustomMachine(customMachine: Machine): Promise<void> {
    return new Promise((resolve, reject) => {
      this.db
        .collection("customMachines")
        .doc(customMachine.id)
        .set(customMachine)
        .then(() => resolve())
        .catch(reject);
    });
  }

  delteCustomMachine(id: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.db
        .collection("customMachines")
        .doc(id)
        .delete()
        .then(() => resolve())
        .catch(reject);
    });
  }

  requestSimulation(
    conditions: Condition[],
    machines: Machine[]
  ): Promise<void> {
    //uplaod
    return new Promise(async (resolve, reject) => {
      try {
        const batch = this.db.batch();
        const requests = GetSimulationsRequests(conditions, machines);
        for (let i = 0; i < requests.length; i++) {
          const request = requests[i];
          const doc = await this.db
            .collection("numerous_simulation_jobs")
            .doc(request.id)
            .get();
          const job = doc.data() as SimJob | undefined;
          if (!doc.exists || job?.status === "failed") {
            //document does not exist - create it....
            // console.log("create new request:");
            // console.log({ request });
            const timestampNow = moment();
            const newRequestJobRef = this.db
              .collection("numerous_simulation_jobs")
              .doc(request.id);
            batch.set(newRequestJobRef, {
              submitted: app.firestore.Timestamp.fromDate(
                timestampNow.toDate()
              ),
              modified: app.firestore.Timestamp.fromDate(timestampNow.toDate()),
              status: "submitted",
              spec: request.spec,
            });
          }
        }
        await batch.commit();
        resolve();
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  }
}

const FBInstance = new Firebase();

export default FBInstance;
