import { LoggedAppUse } from "model/dataTypes";
import React from "react";
import ListItem from "./ListItem";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  items: LoggedAppUse[];
  selectItem: (updated: LoggedAppUse) => void;
  selectedItem?: LoggedAppUse;
}

const SidebarList: React.FC<Props> = ({
  items,
  selectItem,
  selectedItem,
  className,
}) => {
  return (
    <div
      className={`${className} border-r border-gray-200 overflow-y-scroll flex flex-col`}
    >
      {items.map((loggedUse) => {
        return (
          <div
            key={loggedUse.id}
            onClick={() => {
              selectItem(loggedUse);
            }}
          >
            <ListItem
              item={loggedUse}
              selectedItem={selectedItem?.id === loggedUse.id}
            />
          </div>
        );
      })}
      <div className="text-xs text-gray-500 p-2">
        Entries are stored for 30 days
      </div>
    </div>
  );
};

export default SidebarList;
