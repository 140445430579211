import React, { useMemo } from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import { ConditionResult } from "model/dataTypes";
import parseDisplayValue from "utility/parseDisplayValue";

interface Props {
  conditionResult: ConditionResult;
}

const SimResultTablePDF = ({ conditionResult }: Props) => {
  const styles = useMemo(() => {
    return StyleSheet.create({
      td: {
        paddingHorizontal: 5,
        paddingVertical: 5,
        width: "16.6%",
      },
    });
  }, []);
  const isCoolinng = conditionResult.sum_power_total < 0;

  return (
    <View style={{ fontSize: 7, paddingLeft: 20, width: "100%" }}>
      <Text style={{ fontSize: 10, paddingTop: 5, paddingBottom: 5 }}>
        Simulation Results
      </Text>
      <View
        style={{ flexDirection: "row", backgroundColor: "#fff", width: "100%" }}
      >
        <Text style={styles.td}>
          {isCoolinng ? "Cooling capacity" : "Heat Recovered"}
        </Text>
        <Text style={styles.td}>Efficiency</Text>
        <Text style={styles.td}>Supply temp</Text>
        <Text style={styles.td}>Supply humidity</Text>
        <Text style={styles.td}>Outside PD (heat exchanger)</Text>
        <Text style={styles.td}>Extract PD (heat exchanger)</Text>
      </View>
      <View style={{ flexDirection: "row", width: "100%" }}>
        <Text style={styles.td}>
          {parseDisplayValue(
            Math.abs(conditionResult.sum_power_total),
            "sum_power_total"
          )}
        </Text>
        <Text style={styles.td}>
          {parseDisplayValue(
            Math.abs(conditionResult.sum_outside_eff_total),
            "sum_outside_eff_total"
          )}
        </Text>
        <Text style={styles.td}>
          {parseDisplayValue(
            conditionResult.supply_temp_val,
            "supply_temp_val"
          )}
        </Text>
        <Text style={styles.td}>
          {parseDisplayValue(conditionResult.supply_hum_val, "supply_hum_val")}
        </Text>
        <Text style={styles.td}>
          {parseDisplayValue(
            conditionResult.sum_outside_pd_total,
            "sum_outside_pd_total"
          )}
        </Text>
        <Text style={styles.td}>
          {parseDisplayValue(
            conditionResult.sum_extract_pd_total,
            "sum_extract_pd_total"
          )}
        </Text>
      </View>
    </View>
  );
};

export default SimResultTablePDF;
