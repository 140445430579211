import React from "react";
import { WeatherAverages } from "api/WeatherAPI";

interface Props {
  avgWeather?: WeatherAverages | null;
}

const Weather: React.FC<Props> = ({ avgWeather }) => {
  const weatherKeys: (keyof WeatherAverages)[] = avgWeather
    ? (Object.keys(avgWeather) as (keyof WeatherAverages)[])
    : [];
  return (
    <>
      <label className="text-xs font-bold mt-6">Average Temperature</label>
      {avgWeather ? (
        <table className="mt-2">
          <thead>
            <tr className="border-b border-t border-gray-400">
              <td className={`${twStyles.td}  pl-0`}>Season</td>
              <td className={`${twStyles.td} `}>Day</td>
              <td className={`${twStyles.td} `}>Night</td>
            </tr>
          </thead>
          <tbody>
            {weatherKeys.map(key => (
              <tr>
                <td className={`${twStyles.td} pl-0`}>{key}</td>
                <td className={twStyles.td}>{avgWeather[key].day_average.toFixed(1)}°C</td>
                <td className={twStyles.td}>{avgWeather[key].night_average.toFixed(1)}°C</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="text-xs">Select a location to preview weather....</div>
      )}
    </>
  );
};

const twStyles = {
  td: "px-2 text-xs",
};

export default Weather;
