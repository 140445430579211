import React from "react";
import StatusNumber, { RoundNumber } from "../statusNumber/StatusNumber";
import InfoTag from "../../basic/InfoTag";

interface Props {
  headline: string;
  number: number;
  isActive: boolean;
  preventOverflow?: boolean;
  subheadline?: string;
  info?: React.ReactNode;
}

const InputContainer: React.FC<Props> = ({
  number,
  isActive,
  headline,
  children,
  subheadline,
  preventOverflow,
  info,
}) => {
  return (
    <div className="sm:flex min-h-64 relative">
      <StatusNumber number={number} isActive={isActive} />
      <div
        className={`pr-2 pl-2 sm:pl-0 sm:flex-grow sm:pr-8 ${
          preventOverflow ? "overflow-hidden" : ""
        }`}
      >
        <div className="flex items-center">
          <div className="block sm:hidden mr-3">
            <RoundNumber number={number} isActive={isActive} />
          </div>
          <h1 className=" text-2xl leading-tight mr-3">{headline}</h1>
          {info && <InfoTag mt={20}>{info}</InfoTag>}
        </div>
        {subheadline && <div className="text-xs text-gray-600 font-bold">{subheadline}</div>}
        <hr className="border-gray-200 my-4" />
        {children}
      </div>
    </div>
  );
};

export default InputContainer;
