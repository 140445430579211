import React, { useMemo } from "react";
import { Page, Text, View, Image, StyleSheet } from "@react-pdf/renderer";
import PdfFooter from "./PdfFooter";
import PdfHeader from "./PdfHeader";

export type RowValue = { value: string; type: "IMG" | "TEXT" };
export type Column = { colName: string; rowValues: RowValue[] };

export const PageA4: React.FC<{
  projectName?: string;
  author: string;
  machineID?: string;
  machineName?: string;
  simulationLink: string;
  dimensions: {
    outer: {
      depth: number;
      height: number;
      length: number;
    };
    heatExchanger: {
      depth: number;
      height: number;
      length: number;
    };
  };
}> = ({
  children,
  projectName,
  machineID,
  machineName,
  author,
  simulationLink,
  dimensions,
}) => {
  const styles = useMemo(() => {
    return StyleSheet.create({
      page: {
        backgroundColor: "#FAF9F9",
        paddingBottom: 60,
        paddingTop: 100,
        width: "100%",
        fontFamily: "Roboto",
        fontWeight: "normal",
        fontStyle: "normal",
      },
    });
  }, []);

  return (
    <Page size="A4" style={styles.page} wrap>
      {children}
      <PdfHeader
        projectName={projectName}
        machineID={machineID}
        machineName={machineName}
        author={author}
        simulationLink={simulationLink}
        dimensions={dimensions}
      />
      <PdfFooter />
    </Page>
  );
};

// Create Table
export const PdfTable: React.FC<{
  dataColumns: Column[];
  headline: string;
  subHeadline?: string;
  colWidth?: (number | null)[];
}> = ({ dataColumns, headline, subHeadline, colWidth }) => {
  const rowsAmount =
    dataColumns.length > 0 ? dataColumns[1].rowValues.length : 0;

  const styles = useMemo(() => {
    return StyleSheet.create({
      section: {
        margin: 10,
        padding: 10,
      },
      table: {
        marginVertical: 10,
      },
      headerTd: {
        backgroundColor: "#EDF2F7",
        // borderTop: 1,
        // borderColor: "#E2E8F0",
      },
      tableRow: {
        flexDirection: "row",
      },
      td: {
        fontSize: 8,
        fontStyle: "bold",
        width: 80,
        textAlign: "left",
        paddingHorizontal: 5,
        paddingVertical: 10,
        borderRight: 1,
        borderBottom: 1,
        borderColor: "#E2E8F0",
      },
      headline: {
        fontSize: 14,
        fontStyle: "bold",
      },
      subHeadline: {
        fontSize: 9,
      },
    });
  }, []);

  let rows: RowValue[][] = [];
  for (let i = 0; i < rowsAmount; i++) {
    rows = [...rows, dataColumns.map((col) => col.rowValues[i])];
  }
  return (
    <View style={styles.section} wrap={false}>
      <Text style={styles.headline}>{headline}</Text>
      {subHeadline && <Text style={styles.subHeadline}>{subHeadline}</Text>}
      <View style={styles.table}>
        <View style={styles.tableRow}>
          {dataColumns.map((col, i) => {
            const cW = colWidth && colWidth[i];
            const width = cW ? cW : styles.td.width ? styles.td.width : 80;
            return (
              <Text
                style={[
                  styles.td,
                  styles.headerTd,
                  {
                    width: width,
                  },
                ]}
              >
                {col.colName}
              </Text>
            );
          })}
        </View>
        {rows.map((row) => (
          <View style={styles.tableRow}>
            {row.map((td, i) => {
              const cW = colWidth && colWidth[i];
              const width = cW ? cW : styles.td.width ? styles.td.width : 80;
              return td && td.type === "IMG" ? (
                <Image
                  style={[
                    styles.td,
                    {
                      paddingHorizontal: 10,
                      width: width,
                    },
                  ]}
                  src={td.value}
                />
              ) : (
                <Text
                  style={[
                    styles.td,
                    {
                      width: width,
                    },
                  ]}
                >
                  {td && td.value}
                </Text>
              );
            })}
          </View>
        ))}
      </View>
    </View>
  );
};
