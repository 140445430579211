import { Machine, Condition, SimulationSpec } from "model/dataTypes";
import getSimID from "./GetSimID";
import CheckECool, { hasManualEcool } from "./CheckECool";

export default (conditions: Condition[], machines: Machine[]) => {
  const requests: {
    id: string;
    spec: SimulationSpec;
  }[] = [];
  machines.forEach((machine) => {
    conditions.forEach((condition) => {
      let humidityTarget = -1;
      if (typeof condition.evapCooling === "number" && condition.evapCooling > 0)
        humidityTarget = condition.evapCooling;

      requests.push({
        id: getSimID(machine, condition, humidityTarget),
        spec: getSimSpec(machine, condition, humidityTarget),
      });

      if (CheckECool(condition) && !hasManualEcool(condition))
        requests.push({
          id: getSimID(machine, condition, 80),
          spec: getSimSpec(machine, condition, 80),
        });
    });
  });
  return requests;
};

export const getSimSpec = (
  machine: Machine,
  condition: Condition,
  humidityTarget: number
): SimulationSpec => {
  return {
    depth: machine.heatExhangerDimensions.depth,
    length: machine.heatExhangerDimensions.length,
    height: machine.heatExhangerDimensions.height,
    H_rel0_outside_air: condition.outsideHumidity,
    H_rel0_extract_air: condition.extractHumidity,
    T0_outside_air: condition.outsideAir,
    T0_extract_air: condition.extractAir,
    V_flow_outside_air: condition.outsideFlow,
    V_flow_extract_air: condition.extractFlow,
    relH_stage1: humidityTarget,
    relH_stage2: humidityTarget,
  };
};
