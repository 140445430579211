import React, { useState } from "react";
import Modal from "../../../basic/Modal";
import CustomMachineInput from "./CustomMachineInput";
import ViewCustomMachine from "./ViewCustomMachine";
import ViewSimulations from "./ViewSimulations";

interface Props {}

const AdminOptions: React.FC<Props> = () => {
  const [viewCustom, setviewCustom] = useState<
    "VIEW-MACHINES" | "NEW" | "VIEW-SIMULATIONS" | null
  >(null);
  return (
    <div className="py-4 flex">
      {viewCustom === "NEW" && <CustomMachineInput onFinish={() => setviewCustom(null)} />}
      {viewCustom === "VIEW-MACHINES" && (
        <Modal onClose={() => setviewCustom(null)}>
          <ViewCustomMachine onClose={() => setviewCustom(null)} />
        </Modal>
      )}
      {viewCustom === "VIEW-SIMULATIONS" && (
        <Modal onClose={() => setviewCustom(null)}>
          <ViewSimulations onClose={() => setviewCustom(null)} />
        </Modal>
      )}
      <button
        onClick={() => {
          setviewCustom("NEW");
        }}
        className="px-4 py-2 rounded bg-cm-blue text-xs font-bold text-white"
      >
        Input custom machine
      </button>
      <button
        onClick={() => {
          setviewCustom("VIEW-MACHINES");
        }}
        className={`ml-4 ${tw.button}`}
      >
        Show custom machines
      </button>
      <button
        onClick={() => {
          setviewCustom("VIEW-SIMULATIONS");
        }}
        className={`ml-4 ${tw.button}`}
      >
        Show saved simulations
      </button>
    </div>
  );
};

const tw = {
  button: "px-4 py-2 rounded bg-cm-blue text-xs font-bold text-white",
};

export default AdminOptions;
