import { RootState } from "../reducers";
import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import firebase from "../fb/firebase";
import {
  SIMULATION_SAVED,
  SIMULATION_SAVED_FAIL,
  START_LOAD_SAVED_SIMULATION,
  START_SAVING_SIMULATION,
  FirestoreActionTypes,
  ERROR_LOADING_MACHINES,
} from "./firestoreActionTypes";
import {
  InputActionTypes,
  LOAD_SAVED_INPUT,
  LOAD_MACHINES,
  ERROR_LOADING_SAVED_INPUT,
} from "./inputActionTypes";
import {
  UserActionTypes,
  AUTHENTICATE_FAILED,
  AUTHENTICATE_SUCCESS,
  START_LOGIN,
  AUTHENTICATE_ERROR,
} from "./userActionTypes";
import { LoggedAppUse, MachineOption } from "../model/dataTypes";
import {
  ERROR_LOADING_SIMULATION,
  ResultActionTypes,
  SIMULATION_REQUESTED,
} from "./resultActionTypes";

export const saveAppUse = (mail: string, simulationLink: string) => {
  firebase.saveAppUse(mail, simulationLink).catch((error) => {
    console.error(error);
  });
};

export const updateAppUse = (entry: LoggedAppUse) => {
  firebase.updateAppUse(entry).catch((error) => console.error(error));
};

export const deleteAppUse = (id: string) => {
  firebase.deleteAppUse(id).catch((error) => console.error(error));
};

export const composeMail = async (
  to: string | string[],
  subject: string,
  body: string
) => {
  firebase
    .composeMail(to, subject, body)
    .catch((error) => console.error(error));
};

export const saveSimulation =
  (): ThunkAction<
    void,
    RootState,
    { firebase: typeof firebase },
    Action<string>
  > =>
  async (dispatch, getState, { firebase }) => {
    const { savingSimulation } = getState().result;

    //avoid double calls:
    if (savingSimulation) return;

    const { conditions, machineOptions } = getState().input;
    dispatch<FirestoreActionTypes>({ type: START_SAVING_SIMULATION });

    firebase
      .saveSimulation(conditions, machineOptions)
      .then((uuid) => {
        dispatch<FirestoreActionTypes>({
          type: SIMULATION_SAVED,
          payload: { UUID: uuid },
        });
        firebase.logEvent("simulation_saved");
      })
      .catch((error) => {
        dispatch<FirestoreActionTypes>({
          type: SIMULATION_SAVED_FAIL,
          payload: { errorMessage: "Simulation save failed" },
        });
      });
  };

export const loadSavedSimulation =
  (
    uuid: string
  ): ThunkAction<
    void,
    RootState,
    { firebase: typeof firebase },
    Action<string>
  > =>
  async (dispatch, getState, { firebase }) => {
    dispatch<FirestoreActionTypes>({
      type: START_LOAD_SAVED_SIMULATION,
      payload: { UUID: uuid },
    });

    firebase
      .getSimulation(uuid)
      .then((data) => {
        //Reload saved input state:
        dispatch<InputActionTypes>({ type: LOAD_SAVED_INPUT, payload: data });
      })
      .catch((error) => {
        dispatch<InputActionTypes>({
          type: ERROR_LOADING_SAVED_INPUT,
        });
      });
  };

export const setAuthListener =
  (): ThunkAction<
    void,
    RootState,
    { firebase: typeof firebase },
    Action<string>
  > =>
  async (dispatch, getState, { firebase }) => {
    firebase.auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch<UserActionTypes>({ type: AUTHENTICATE_SUCCESS });
      } else {
        dispatch<UserActionTypes>({ type: AUTHENTICATE_FAILED });
      }
    });
  };

export const authenticateUser =
  (
    email: string,
    password: string
  ): ThunkAction<
    void,
    RootState,
    { firebase: typeof firebase },
    Action<string>
  > =>
  async (dispatch, getState, { firebase }) => {
    //start logging in goes here....
    dispatch<UserActionTypes>({ type: START_LOGIN });

    firebase.auth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        dispatch<UserActionTypes>({ type: AUTHENTICATE_SUCCESS });
      })
      .catch((error) => {
        console.log({ error });
        dispatch<UserActionTypes>({
          type: AUTHENTICATE_ERROR,
          payload: error.message || "Error logging in.",
        });
      });
  };

export const logOut =
  (): ThunkAction<
    void,
    RootState,
    { firebase: typeof firebase },
    Action<string>
  > =>
  async (dispatch, getState, { firebase }) => {
    firebase.auth
      .signOut()
      .then(() => {})
      .catch((error) => {});
  };

export const getMachines =
  (): ThunkAction<
    void,
    RootState,
    { firebase: typeof firebase },
    Action<string>
  > =>
  async (dispatch, getState, { firebase }) => {
    firebase
      .getMachines()
      .then((machines) => {
        const machineOptions: MachineOption[] = machines.map((machine) => ({
          label: machine.name,
          selected: false,
          filtered: false,
          id: machine.id,
          machine,
        }));

        dispatch<InputActionTypes>({
          type: LOAD_MACHINES,
          payload: { machines: machineOptions },
        });
      })
      .catch((error) => {
        dispatch<FirestoreActionTypes>({
          type: ERROR_LOADING_MACHINES,
        });
      });
  };

export const requestSimulationAction =
  (): ThunkAction<
    void,
    RootState,
    { firebase: typeof firebase },
    Action<string>
  > =>
  async (dispatch, getState, { firebase }) => {
    const { requestingSimulation } = getState().result;
    //avoid double calls:
    if (requestingSimulation) return;

    const { conditions, machineOptions } = getState().input;
    //get machines selected:
    const machines = machineOptions
      .filter((m) => m.selected)
      .map((m) => m.machine);
    if (machines.length < 1) {
      dispatch<ResultActionTypes>({
        type: ERROR_LOADING_SIMULATION,
        payload: { errorMessage: "No machines selected" },
      });
      return;
    }

    try {
      //get all firestore
      dispatch<FirestoreActionTypes>({ type: "START_REQUESTING_SIMULATION" });
      //Start the API call/calls goes here...
      await firebase.requestSimulation(conditions, machines);
      firebase.logEvent("simulation_requested", {
        nr_of_conditions: conditions.length,
        nr_of_machines: machines.length,
        conditions,
        machines,
      });

      //on success:
      dispatch<ResultActionTypes>({
        type: SIMULATION_REQUESTED,
        payload: { conditions: conditions, machines: machines },
      });
    } catch (error) {
      console.log(error);
      dispatch<ResultActionTypes>({
        type: ERROR_LOADING_SIMULATION,
        payload: { errorMessage: "Simulation error" },
      });
    }
  };
