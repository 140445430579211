import React from "react";
import { View, StyleSheet, Text, Image, Page } from "@react-pdf/renderer";
import CMLogo from "resources/CMLogo.png";

interface Props {}

const FrontPage: React.FC<Props> = () => {
  return (
    <Page size="A4" style={styles.page}>
      <View style={styles.section} wrap={false}>
        <Image src={CMLogo} style={styles.logo} />
        <Text style={styles.headline}>Climate machines simulation results</Text>
      </View>
    </Page>
  );
};

export default FrontPage;

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#fff",
  },
  logo: {
    width: "80%",
    paddingBottom: "25px",
  },
  section: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "90vh",
    padding: 10,
  },
  headline: {
    fontSize: 20,
    fontStyle: "bold",
    color: "#2F3438",
  },
  subHeadline: {
    fontSize: 9,
  },
});
