import { createStore, compose, applyMiddleware } from "redux";
import { rootReducer } from "./reducers";
import thunk from "redux-thunk";
import firebase from "./fb/firebase";

//hack for devtools in TS
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunk.withExtraArgument({ firebase })];

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

export default store;
