import {
  ResultActionTypes,
  ERROR_LOADING_SIMULATION,
  SIMULATION_REQUESTED,
} from "../actions/resultActionTypes";
import {
  FirestoreActionTypes,
  SIMULATION_SAVED,
  SIMULATION_SAVED_FAIL,
  START_LOAD_SAVED_SIMULATION,
  START_SAVING_SIMULATION,
  START_REQUESTING_SIMULATION,
} from "../actions/firestoreActionTypes";
import { Condition, Machine } from "../model/dataTypes";
import {
  ERROR_LOADING_SAVED_INPUT,
  InputActionTypes,
  TOGGLE_MACHINE,
  ADD_CONDITION,
  LOAD_SAVED_INPUT,
} from "../actions/inputActionTypes";

export type ResultState = {
  conditions: Condition[] | null;
  machines: Machine[] | null;
  showResults: boolean;
  requestingSimulation: boolean;
  selectedMachine: Machine | null;
  simulationError: null | string;
  fbUUID: null | string;
  savingSimulation: boolean;
  loadingSavedSimulation: boolean;
  simJob_refs: null | string[];
};

const initialState: ResultState = {
  machines: null,
  conditions: null,
  showResults: false,
  requestingSimulation: false,
  selectedMachine: null,
  simulationError: null,
  fbUUID: null,
  savingSimulation: false,
  loadingSavedSimulation: false,
  simJob_refs: null,
};

export function resultReducer(
  state = initialState,
  action: ResultActionTypes | FirestoreActionTypes | InputActionTypes
): ResultState {
  switch (action.type) {
    case ERROR_LOADING_SIMULATION:
      return {
        ...state,
        requestingSimulation: false,
        showResults: false,
        simulationError: action.payload.errorMessage,
      };
    case START_SAVING_SIMULATION:
      return {
        ...state,
        savingSimulation: true,
      };
    case SIMULATION_SAVED:
      return {
        ...state,
        savingSimulation: false,
        fbUUID: action.payload.UUID,
      };
    case SIMULATION_SAVED_FAIL:
      return { ...state, savingSimulation: false };
    case START_LOAD_SAVED_SIMULATION:
      return { ...state, loadingSavedSimulation: true, fbUUID: action.payload.UUID };
    case LOAD_SAVED_INPUT:
      const SelectedMachines = action.payload.machineOptions
        .filter((mo) => mo.selected)
        .map((mo) => mo.machine);
      return {
        ...state,
        machines: SelectedMachines,
        conditions: action.payload.conditions,
        showResults: true,
        requestingSimulation: false,
      };
    case ERROR_LOADING_SAVED_INPUT:
      return { ...state, loadingSavedSimulation: false, fbUUID: null };
    case START_REQUESTING_SIMULATION:
      return {
        ...state,
        requestingSimulation: true,
        showResults: false,
        simulationError: null,
      };
    case TOGGLE_MACHINE:
      return {
        ...state,
        simulationError: null,
      };
    case ADD_CONDITION:
      return {
        ...state,
        simulationError: null,
      };
    case SIMULATION_REQUESTED:
      return {
        ...state,
        machines: action.payload.machines,
        conditions: action.payload.conditions,
        showResults: true,
        requestingSimulation: false,
        fbUUID: null,
      };
    default:
      return state;
  }
}
