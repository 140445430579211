import custom from "resources/machines/png/custom.png";
import unit500 from "resources/machines/png/unit-500.png";
import unit1000 from "resources/machines/png/unit-1000.png";
import module2000 from "resources/machines/png/module-2000.png";
import module3000 from "resources/machines/png/module-3000.png";
import module4000 from "resources/machines/png/module-4000.png";
import custom5000low from "resources/machines/png/custom-5000-low.png";
import custom5000medium from "resources/machines/png/custom-5000-medium.png";
import custom5000high from "resources/machines/png/custom-5000-high.png";
import custom10000low from "resources/machines/png/custom-10000-low.png";
import custom10000medium from "resources/machines/png/custom-10000-medium.png";
import custom10000high from "resources/machines/png/custom-10000-high.png";
import custom15000low from "resources/machines/png/custom-15000-low.png";
import custom15000medium from "resources/machines/png/custom-15000-medium.png";
import custom15000high from "resources/machines/png/custom-15000-high.png";

export const getMachineImagePng = (machineID: string) => {
  let src: string;

  switch (machineID) {
    case "Unit-500":
      src = unit500;
      break;
    case "Unit-1000":
      src = unit1000;
      break;
    case "Module-2000":
      src = module2000;
      break;
    case "Module-3000":
      src = module3000;
      break;
    case "Module-4000":
      src = module4000;
      break;
    case "Custom-5000-Low":
      src = custom5000low;
      break;
    case "Custom-5000-Medium":
      src = custom5000medium;
      break;
    case "Custom-5000-High":
      src = custom5000high;
      break;
    case "Custom-10000-Low":
      src = custom10000low;
      break;
    case "Custom-10000-Medium":
      src = custom10000medium;
      break;
    case "Custom-10000-High":
      src = custom10000high;
      break;
    case "Custom-15000-Low":
      src = custom15000low;
      break;
    case "Custom-15000-Medium":
      src = custom15000medium;
      break;
    case "Custom-15000-High":
      src = custom15000high;
      break;
    default:
      src = custom;
  }
  return src;
};
