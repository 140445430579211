import React, { useState } from "react";
import { Condition } from "../../../model/dataTypes";
import { InputNum, InputText } from "../basic/Input";
import { useDispatch } from "react-redux";
import uuid from "uuid/v4";
import {
  InputActionTypes,
  ADD_CONDITION,
} from "../../../actions/inputActionTypes";
import HoverMenu from "components/basic/HoverMenu";

interface Props {
  conditionData: Condition;
  remove: (id: string) => void;
  update: (data: Condition) => void;
  isAdmin: boolean;
}

const EditingRow: React.FC<Props> = ({
  conditionData,
  update,
  remove,
  isAdmin,
}) => {
  const {
    id,
    name,
    outsideAir,
    outsideHumidity,
    outsideFlow,
    extractAir,
    extractHumidity,
    extractFlow,
    evapCooling,
  } = conditionData;
  const [isEditing, setisEditing] = useState<boolean>(false);
  const dispatch = useDispatch();
  const onChange = (newValue: number, key: keyof Condition) => {
    const newConditionValue: Condition = {
      ...conditionData,
      [key]: newValue,
    };
    update(newConditionValue);
  };
  const onChangeCaseName = (newName: string) => {
    const newConditionValue: Condition = { ...conditionData, name: newName };
    update(newConditionValue);
  };

  const menu = [
    <div
      onClick={(e) => {
        const conditionCopy = {
          ...conditionData,
          id: uuid(),
          name: `${conditionData.name} copy`,
        };
        dispatch<InputActionTypes>({
          type: ADD_CONDITION,
          payload: { condition: conditionCopy },
        });
      }}
      className="py-2 px-6 cursor-pointer hover:bg-gray-100 text-xs text-gray-700"
    >
      Duplicate
    </div>,
    <div
      className="py-2 px-6 cursor-pointer hover:bg-gray-100 text-red-500 text-xs"
      onClick={() => remove(id)}
    >
      Remove
    </div>,
  ];

  return (
    <tr
      className={`border-t-2 border-b-2 border-white ${
        isEditing ? "bg-gray-200" : "bg-simulation-gray"
      }  text-xs`}
      onMouseEnter={() => setisEditing(true)}
      onMouseLeave={() => setisEditing(false)}
    >
      <td className={twStyles.td}>
        <InputText
          className={"w-16 md:w-20 lg:w-24 "}
          value={name}
          onChange={(newName) => {
            onChangeCaseName(newName);
          }}
        />
      </td>
      <td className={twStyles.td} align="center">
        <InputNum
          showButtons={isEditing}
          className={twStyles.inputBox}
          unit={"°C"}
          value={outsideAir}
          onChange={(v) => {
            onChange(v, "outsideAir");
          }}
        />
      </td>
      <td className={twStyles.td} align="center">
        <InputNum
          showButtons={isEditing}
          className={twStyles.inputBox}
          unit={"%"}
          value={outsideHumidity}
          onChange={(v) => {
            onChange(v, "outsideHumidity");
          }}
        />
      </td>
      <td className={twStyles.td} align="center">
        <InputNum
          showButtons={isEditing}
          className={twStyles.inputBox}
          unit={"m³/s"}
          value={outsideFlow}
          onChange={(v) => {
            onChange(v, "outsideFlow");
          }}
        />
      </td>
      <td className={twStyles.td} align="center">
        <InputNum
          showButtons={isEditing}
          className={twStyles.inputBox}
          unit={"°C"}
          value={extractAir}
          onChange={(v) => {
            onChange(v, "extractAir");
          }}
        />
      </td>
      <td className={twStyles.td} align="center">
        <InputNum
          showButtons={isEditing}
          className={twStyles.inputBox}
          unit={"%"}
          value={extractHumidity}
          onChange={(v) => {
            onChange(v, "extractHumidity");
          }}
        />
      </td>
      <td className={twStyles.td} align="center">
        <InputNum
          showButtons={isEditing}
          className={twStyles.inputBox}
          unit={"m³/s"}
          value={extractFlow}
          onChange={(v) => {
            onChange(v, "extractFlow");
          }}
        />
      </td>
      {isAdmin && (
        <td className={twStyles.td} align="center">
          <InputNum
            showButtons={isEditing}
            className={twStyles.inputBox}
            unit={"%"}
            value={evapCooling || null}
            onChange={(v) => {
              onChange(v, "evapCooling");
            }}
          />
        </td>
      )}
      <td className={twStyles.td} align="center">
        <HoverMenu options={menu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
          </svg>
        </HoverMenu>
      </td>
    </tr>
  );
};

export const twStyles = {
  td: "px-1 lg:px-2 py-2",
  btn: "px-2 py-1 rounded border-2 border-gray-400",
  inputBox: "border border-white w-16 md:w-20",
};

export default EditingRow;
