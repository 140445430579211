import { LoggedAppUse } from "model/dataTypes";
import React from "react";

interface Props {
  item: LoggedAppUse;
  selectedItem: boolean;
}

const ListItem: React.FC<Props> = ({ item, selectedItem }) => {
  const date = item.created.format("ddd, MMM Do, HH:mm");

  return (
    <div
      className={`w-full flex items-center p-2 border-b border-gray-100 ${
        selectedItem ? "bg-blue-200" : "hover:bg-blue-100"
      } cursor-pointer`}
    >
      {item.isNew && (
        <div className="bg-blue-500 text-white text-xs py-1 px-3 rounded-full mr-3">
          New
        </div>
      )}
      <div>
        <h3
          className={`text-sm ${
            item.isContacted ? "text-gray-500" : "text-gray-900 font-medium"
          }`}
        >
          {item.mail}
        </h3>
        <span
          className={`text-xs ${
            item.isContacted ? "text-gray-500" : "text-gray-700"
          }`}
        >
          {date}
        </span>
      </div>
    </div>
  );
};

export default ListItem;
