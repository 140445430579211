import {
  UserActionTypes,
  AUTHENTICATE_FAILED,
  AUTHENTICATE_SUCCESS,
  START_LOGIN,
  USE_ADMIN_MODE,
  USE_NORMAL_MODE,
  AUTHENTICATE_ERROR,
} from "../actions/userActionTypes";

export type UserState = {
  authenticated: boolean;
  useAdminMode: boolean;
  loggingIn: boolean;
  loginError: null | string;
};

const initialState: UserState = {
  authenticated: false,
  useAdminMode: false,
  loggingIn: false,
  loginError: null,
};

export function userReducer(state = initialState, action: UserActionTypes): UserState {
  switch (action.type) {
    case AUTHENTICATE_FAILED:
      return {
        ...state,
        authenticated: false,
        loggingIn: false,
      };
    case AUTHENTICATE_ERROR:
      return {
        ...state,
        authenticated: false,
        loggingIn: false,
        loginError: action.payload,
      };
    case AUTHENTICATE_SUCCESS:
      return { ...state, authenticated: true, loggingIn: false };
    case START_LOGIN:
      return { ...state, loggingIn: true, loginError: null };
    case USE_ADMIN_MODE:
      return { ...state, useAdminMode: true };
    case USE_NORMAL_MODE:
      return { ...state, useAdminMode: false };
    default:
      return state;
  }
}
