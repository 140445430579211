import React, { useState, useRef, useEffect } from "react";
import { createPortal } from "react-dom";
import { usePagePosition } from "utility/usePagePosition";

interface Props {
  mt?: number;
  mx?: number;
  rightAlign?: boolean;
  className?: string;
  icon?: JSX.Element;
}

const InfoTag: React.FC<Props> = ({ children, mt, mx, rightAlign, className, icon }) => {
  const [hovered, sethovered] = useState(false);
  const [clicked, setclicked] = useState(false);

  //clickhandler for closing
  const ref = useRef<HTMLDivElement>(null);
  const { pagePosition, updatePosition } = usePagePosition(ref);

  const handleClick = (e: MouseEvent) => {
    if (ref.current && e.target instanceof Node && ref.current.contains(e.target)) {
      return;
    }
    setclicked(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    document.addEventListener("scroll", updatePosition);
    return () => {
      setclicked(false);
      document.removeEventListener("mousedown", handleClick);
      document.removeEventListener("scroll", updatePosition);
    };
  }, [updatePosition]);

  const marginLeft = rightAlign
    ? 0
    : mx
    ? pagePosition.marginLeft + mx
    : pagePosition.marginLeft;
  const marginRight = !rightAlign
    ? 0
    : mx
    ? pagePosition.marginRight + mx
    : pagePosition.marginRight;

  return (
    <div
      ref={ref}
      className={`h-4 w-4 rounded-full flex items-center justify-center
      ${className ? className : ""}`}
      onMouseEnter={() => {
        sethovered(true);
        updatePosition();
      }}
      onMouseLeave={() => {
        sethovered(false);
      }}
      onClick={() => {
        setclicked(true);
        updatePosition();
      }}
    >
      {icon ? icon : <InfoIcon />}
      {(hovered || clicked) && (
        <RootPortal>
          <div
            className={`absolute z-50 px-2 py-2 bg-white shadow-xl rounded border top-0 ${
              rightAlign ? "right-0" : "left-0"
            }`}
            style={{
              marginTop: mt ? pagePosition.marginTop + mt : pagePosition.marginTop,
              marginLeft,
              marginRight,
              zIndex: 1000,
            }}
          >
            {children}
          </div>
        </RootPortal>
      )}
    </div>
  );
};

export default InfoTag;

const InfoIcon = () => {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 0 24 24"
      width="24"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1-8h-2V7h2v2z" />
    </svg>
  );
};

const RootPortal: React.FC = ({ children }) => {
  const appRoot = document.getElementById("root");
  return appRoot && createPortal(children, appRoot);
};
