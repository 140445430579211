import React, { useMemo } from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import parseDisplayValue, { keysAllowed } from "utility/parseDisplayValue";
import { SFP_Table } from "reducers/pdfReducer";

interface Props {
  sfp: SFP_Table;
  caseInfo: {
    PD_outside: number;
    PD_extract: number;
    flow: number;
  };
}

const SFPTablePDF = ({ sfp, caseInfo }: Props) => {
  const styles = useMemo(() => {
    return StyleSheet.create({
      header: {
        backgroundColor: "#fff",
      },
      tdMedium: {
        padding: 5,
        width: "13%",
      },
      tdLarge: {
        padding: 5,
        width: "19.6%",
      },
    });
  }, []);
  const totalExtractPD = useMemo(
    () => sfp.component_pl_extract + sfp.external_pl_supply,
    [sfp]
  );
  const totalOutsidePD = useMemo(
    () => sfp.component_pl_outside + sfp.external_pl_supply,
    [sfp]
  );

  return (
    <View style={{ fontSize: 7, width: "100%" }}>
      {/* 1st row */}
      <View
        style={{
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Text style={[styles.tdMedium, styles.header]}>Flow Supply</Text>
        <Text style={[styles.tdMedium, styles.header]}>Flow Exhaust</Text>
        <Text style={[styles.tdMedium, styles.header]}>SFP max</Text>
        <Text style={[styles.tdLarge, styles.header]}>
          SPF ByPass (yearly mean)
        </Text>
        <Text style={[styles.tdLarge, styles.header]}>
          Component PD Outside
        </Text>
        <Text style={[styles.tdLarge, styles.header]}>
          Component PD Extract
        </Text>
      </View>
      <View style={{ flexDirection: "row", width: "100%" }}>
        <Text style={styles.tdMedium}>
          {parseDisplayValue(
            Math.abs(sfp.flow_supply),
            sfp.flow_unit as keysAllowed
          )}
        </Text>
        <Text style={styles.tdMedium}>
          {parseDisplayValue(
            Math.abs(sfp.flow_exhaust),
            sfp.flow_unit as keysAllowed
          )}
        </Text>
        <Text style={styles.tdMedium}>
          {parseDisplayValue(sfp.sfp_max, "SFP")}
        </Text>
        <Text style={styles.tdLarge}>
          {parseDisplayValue(sfp.sfp_bypass, "SFP")}
        </Text>
        <Text style={styles.tdLarge}>
          {parseDisplayValue(sfp.component_pl_outside, "extract_pd_val")}
        </Text>
        <Text style={styles.tdLarge}>
          {parseDisplayValue(sfp.component_pl_extract, "extract_pd_val")}
        </Text>
      </View>

      {/* 2nd row */}
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          marginTop: "15px",
        }}
      >
        <Text style={[styles.tdLarge, styles.header]}>External PD Supply</Text>
        {sfp.external_pl_exhaust !== undefined && (
          <Text style={[styles.tdLarge, styles.header]}>
            External PD Exhaust
          </Text>
        )}
        <Text style={[styles.tdLarge, styles.header]}>Total PD outside</Text>
        <Text style={[styles.tdLarge, styles.header]}>Total PD Extract</Text>
      </View>
      <View style={{ flexDirection: "row", width: "100%" }}>
        <Text style={styles.tdLarge}>
          {parseDisplayValue(sfp.external_pl_supply, "extract_pd_val")}
        </Text>
        {sfp.external_pl_exhaust !== undefined && (
          <Text style={styles.tdLarge}>
            {parseDisplayValue(sfp.external_pl_exhaust, "extract_pd_val")}
          </Text>
        )}
        <Text style={styles.tdLarge}>
          {parseDisplayValue(totalOutsidePD, "extract_pd_val")}
        </Text>
        <Text style={styles.tdLarge}>
          {parseDisplayValue(totalExtractPD, "extract_pd_val")}
        </Text>
      </View>
    </View>
  );
};

export default SFPTablePDF;
