export const AUTHENTICATE_FAILED = "AUTHENTICATE_FAILED";
export const AUTHENTICATE_ERROR = "AUTHENTICATE_ERROR";

export const AUTHENTICATE_SUCCESS = "AUTHENTICATE_SUCCESS";
export const START_LOGIN = "START_LOGIN";
export const USE_ADMIN_MODE = "USE_ADMIN_MODE";
export const USE_NORMAL_MODE = "USE_NORMAL_MODE";

interface StartLoginAction {
  type: typeof START_LOGIN;
}

interface AuthenticateFailedAction {
  type: typeof AUTHENTICATE_FAILED;
}

interface AuthenticateErrorAction {
  type: typeof AUTHENTICATE_ERROR;
  payload: string;
}
interface AuthenticateAdminAction {
  type: typeof AUTHENTICATE_SUCCESS;
}

interface UseAdminMode {
  type: typeof USE_ADMIN_MODE;
}
interface UseNormalMode {
  type: typeof USE_NORMAL_MODE;
}

export type UserActionTypes =
  | AuthenticateFailedAction
  | AuthenticateErrorAction
  | AuthenticateAdminAction
  | StartLoginAction
  | UseAdminMode
  | UseNormalMode;
