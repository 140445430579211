import { useState, useEffect } from "react";
import fb from "./firebase";
import {
  Machine,
  Condition,
  MachineOption,
  SavedSimulation,
  StaticSimResult,
  SimulationJob,
  LoggedAppUse,
} from "../model/dataTypes";
import moment from "moment";
import { useFirebase } from "./useFirebase";
import getSimID from "utility/GetSimID";
import { immutableSplice } from "utility/Array";
import CheckECool, {
  hasManualEcool,
  manualEcoolActive,
} from "utility/CheckECool";

export const useCustomMachines = () => {
  const [customMachines, setcustomMachines] = useState<Machine[]>([]);

  useEffect(() => {
    const unSubscribe = fb.db
      .collection("customMachines")
      .onSnapshot((snapshot) => {
        const loadedMachines: Machine[] = [];
        snapshot.forEach((doc) => {
          const machine = { id: doc.id, ...doc.data() } as Machine;
          loadedMachines.push(machine);
        });
        setcustomMachines(loadedMachines);
      });
    return () => {
      unSubscribe();
    };
  }, []);

  return { customMachines };
};

export const useLoggedAppUse = () => {
  const [appUse, setAppUse] = useState<LoggedAppUse[]>([]);

  useEffect(() => {
    const unSub = fb.db.collection("loggedAppUses").onSnapshot((snap) => {
      const appUses: LoggedAppUse[] = [];
      snap.forEach((doc) => {
        const data = doc.data();
        const entry = {
          ...data,
          id: doc.id,
          created: moment(data.created.toDate()),
        } as LoggedAppUse;
        appUses.push(entry);
      });
      setAppUse(appUses);
    });
    return () => {
      unSub();
    };
  }, []);

  return appUse;
};

type FSSavedSimulation = {
  conditions: Condition[];
  machineOptions: MachineOption[];
  created: firebase.firestore.Timestamp;
};

export const useSavedSimulations = () => {
  const [savedSimulations, setsavedSimulation] = useState<SavedSimulation[]>(
    []
  );

  useEffect(() => {
    const unSubscribe = fb.db
      .collection("simulations")
      .onSnapshot((snapshot) => {
        const loadedSimulations: SavedSimulation[] = [];
        snapshot.forEach((doc) => {
          const simulation = { ...doc.data() } as FSSavedSimulation;
          loadedSimulations.push({
            id: doc.id,
            ...simulation,
            created: moment(simulation.created.toDate()),
          } as SavedSimulation);
        });
        setsavedSimulation(loadedSimulations);
      });
    return () => {
      unSubscribe();
    };
  }, []);

  return { savedSimulations };
};

export const useSimulationResults = (
  machines: Machine[] | null,
  conditions: Condition[] | null
) => {
  const [rawResults, setRawResults] = useState<StaticSimResult[]>([]);
  const firebase = useFirebase();
  useEffect(() => {
    if (
      machines &&
      conditions &&
      machines.length > 0 &&
      conditions.length > 0
    ) {
      //make sure only present machine results are kept:
      setRawResults((prevRawRes) =>
        prevRawRes.filter((res) => machines.some((m) => m.id === res.machineID))
      );
      let unsubFunctions: (() => void)[] = [];
      machines.forEach((machine) => {
        conditions.forEach((condition) => {
          let humidityTarget = -1;
          if (
            typeof condition.evapCooling === "number" &&
            condition.evapCooling > 0
          )
            humidityTarget = condition.evapCooling;
          const simID = getSimID(machine, condition, humidityTarget);

          const unsub = firebase
            .firestore()
            .collection("numerous_simulation_jobs")
            .doc(simID)
            .onSnapshot((doc) => {
              if (doc.exists) {
                const job = doc.data() as SimulationJob;
                const rawResult: StaticSimResult = {
                  job,
                  machineID: machine.id,
                  conditionID: condition.id,
                  ECOOL: manualEcoolActive(condition),
                };
                setRawResults((prevRawRes) =>
                  updateStaticResultArray(prevRawRes, rawResult)
                );
              } else {
              }
            });
          unsubFunctions.push(unsub);
          if (CheckECool(condition) && !hasManualEcool(condition)) {
            const unsub = firebase
              .firestore()
              .collection("numerous_simulation_jobs")
              .doc(getSimID(machine, condition, 80))
              .onSnapshot((doc) => {
                if (doc.exists) {
                  const job = doc.data() as SimulationJob;
                  const rawResult: StaticSimResult = {
                    job,
                    machineID: machine.id,
                    conditionID: condition.id,
                    ECOOL: true,
                  };
                  setRawResults((prevRawRes) =>
                    updateStaticResultArray(prevRawRes, rawResult)
                  );
                } else {
                }
              });
            unsubFunctions.push(unsub);
          }
        });
      });
      return () => {
        unsubFunctions.forEach((unsub) => {
          unsub();
        });
      };
    }
  }, [conditions, machines, firebase]);
  return rawResults;
};

const updateStaticResultArray = (
  array: StaticSimResult[],
  object: StaticSimResult
) => {
  const i = array.findIndex(
    (item) =>
      item.conditionID === object.conditionID &&
      item.machineID === object.machineID &&
      object.ECOOL === item.ECOOL
  );
  return i > -1 ? immutableSplice(array, i, 1, object) : [...array, object];
};
