import React from "react";

// interface RowData<T> {}

export type Column = {
  title: string;
  id: string;
};

interface Props {
  columns: Column[];
}

export const Table: React.FC<Props> = ({ columns, children }) => {
  return (
    <table className="w-full">
      <thead>
        <tr>
          {columns.map((col, i) => (
            <th
              key={col.id}
              className={`px-2 py-2 border-b border-gray-200 text-xs lg:text-sm ${
                i > 0 ? "text-center" : ""
              }`}
            >
              {col.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};
