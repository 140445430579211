import React, { useRef, useEffect, useState, useCallback } from "react";
import { useClickOutsideEffect } from "utility/useClickOutside";
import { useMaxHeightTransition } from "utility/useMaxHeightTransition";
import { createPortal } from "react-dom";
import uuid from "uuid";
const appRoot = document.getElementById("root");

interface Props {
  className?: string;
  options: JSX.Element[];
}

const HoverMenu: React.FC<Props> = ({ className, options, children }) => {
  const iconRef = useRef<HTMLDivElement>(null);
  const floatingMenuRef = useRef<HTMLDivElement>(null);

  const [btnHovered, setBtnHovered] = useState(false);
  const [menuHovered, setMenuHovered] = useState(false);
  const [clicked, setClicked] = useState(false);
  const { open, setOpen, style } = useMaxHeightTransition("0", "400px");

  const handleClickOutside = useCallback(() => {
    setClicked(false);
  }, []);
  useClickOutsideEffect([iconRef, floatingMenuRef], handleClickOutside);

  useEffect(() => {
    const shouldBeOpen = clicked || btnHovered || menuHovered;
    if (shouldBeOpen && !open) updatePosition();
    setOpen(shouldBeOpen);
  }, [btnHovered, menuHovered, clicked, open, setOpen]);

  const [position, setPosition] = useState({ ml: 0, mt: 0 });
  const updatePosition = () => {
    if (iconRef.current) {
      const boundingRect = iconRef.current.getBoundingClientRect();
      const screenScrollPosLeft = window.pageXOffset || document.documentElement.scrollLeft;
      const screenScrollPosTop = window.pageYOffset || document.documentElement.scrollTop;
      setPosition({
        ml: boundingRect.left + screenScrollPosLeft,
        mt: boundingRect.top + screenScrollPosTop,
      });
    }
  };

  useEffect(() => {
    //get the absolute page position for the floating ref:
    if (iconRef.current) {
      const boundingRect = iconRef.current.getBoundingClientRect();
      const screenScrollPosLeft = window.pageXOffset || document.documentElement.scrollLeft;
      const screenScrollPosTop = window.pageYOffset || document.documentElement.scrollTop;
      setPosition({
        ml: boundingRect.left + screenScrollPosLeft,
        mt: boundingRect.top + screenScrollPosTop,
      });
    }
  }, [iconRef]);

  return (
    <div className={`relative`} ref={iconRef}>
      <div
        className={`${className ? className : ""} flex items-center cursor-pointer`}
        onClick={() => setClicked(!clicked)}
        onMouseEnter={() => setBtnHovered(true)}
        onMouseLeave={() => setBtnHovered(false)}
      >
        {children}
      </div>

      {open && (
        <RootPortal>
          <div
            ref={floatingMenuRef}
            style={{
              marginLeft: `${position.ml}px`,
              marginTop: `${position.mt}px`,
              ...style,
            }}
            className="absolute top-0 left-0 z-50"
            onMouseEnter={() => setMenuHovered(true)}
            onMouseLeave={() => setMenuHovered(false)}
          >
            <div
              className={`rounded border border-gray-200 shadow-lg overflow-hidden absolute max-w-sm left-0 z-10 bg-white top-0 mt-4`}
            >
              {options.length > 0 ? (
                options.map((option) => (
                  <div key={uuid()} onClick={() => setOpen(false)}>
                    {option}
                  </div>
                ))
              ) : (
                <div className={`py-2 px-6`}>no options...</div>
              )}
            </div>
          </div>
        </RootPortal>
      )}
    </div>
  );
};

export default HoverMenu;

const RootPortal: React.FC = ({ children }) => {
  return appRoot && createPortal(children, appRoot);
};
