import React from "react";
import MachineSelector from "./machinesSelector/MachineSelector";
import ConditionSelector from "./conditionSelector/ConditionSelector";
import SubmitSimulation from "./submit/SubmitSimulation";

interface Props {}

const InputWrapper: React.FC<Props> = () => {
  return (
    <div className={`text-gray-text font-sans pb-8 max-w-6xl w-full mx-auto`}>
      <MachineSelector />
      <ConditionSelector />
      {/* <OperationsInput /> */}
      <SubmitSimulation />
    </div>
  );
};

export default InputWrapper;
