import React from "react";
import { View, StyleSheet, Text, Image, Link } from "@react-pdf/renderer";
import CMLogo from "resources/logoBlue.png";

interface Props {}

const PdfFooter = (props: Props) => {
  return (
    <View fixed style={styles.footer}>
      <Image src={CMLogo} style={styles.logo} />
      <View>
        <Text style={styles.footerText}>
          Climate Machines FF AB, Hemvärnsgatan 9, 17154 Solna |{" "}
          <Link src="mailto:info@climatemachines.com">info@climatemachines.com</Link>
        </Text>
        <Text style={styles.footerText}>
          <Link src="https://climatemachines.com/">www.climatemachines.com</Link> | org.nr
          559171-7367
        </Text>
        <Text style={styles.footerText}>
          <Link src="https://climatemachines.web.app/">
            Created using Climate machines simulation tool
          </Link>
        </Text>
      </View>
    </View>
  );
};

export default PdfFooter;

// Create styles
const styles = StyleSheet.create({
  logo: {
    width: "25%",
    padding: 10,
  },
  footer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    height: 60,
    paddingHorizontal: 15,
    borderTop: 1,
    borderColor: "#a9a9a9",
  },
  addressLine: {
    flexDirection: "column",
  },
  footerText: {
    marginVertical: 1,
    fontSize: 6,
    color: "#9a9a9a",
  },
});
