import React, { useEffect } from "react";
import { Provider } from "react-redux";
import "./styles/tailwind.css";
import "./styles/transitions.css";
import "./styles/printStyles.css";
import store from "./store";
import MainPage from "./components/MainPage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SavedSimulation from "components/SavedSimulation";
import DynamicTestPage from "components/DynamicTestPage";
import FirebaseProvider from "fb/FirebaseProvider";
import { useDispatch, useSelector } from "react-redux";
import { setAuthListener } from "./actions/fsActions";
import { StoreState } from "./reducers";
import Login from "components/login/Login";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <FirebaseProvider>
        <Routes />
      </FirebaseProvider>
    </Provider>
  );
};

export default App;

const Routes: React.FC<{}> = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setAuthListener());
  }, [dispatch]);
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={NormalPage} />
        <Route path="/saved/:fbuuid?" component={SavedSimulation} />
        <Route exact path="/admin" component={AdminPage} />
        <Route exact path="/dynamic-test" component={DynamicTestPage} />
      </Switch>
    </Router>
  );
};

const NormalPage: React.FC<{}> = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "USE_NORMAL_MODE" });
  }, [dispatch]);
  return <MainPage />;
};

const AdminPage: React.FC<{}> = () => {
  const isAuthenticated = useSelector((state: StoreState) => state.user.authenticated);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isAuthenticated) dispatch({ type: "USE_ADMIN_MODE" });
  }, [isAuthenticated, dispatch]);
  return isAuthenticated ? <MainPage /> : <Login />;
};
