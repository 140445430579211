import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import uuid from "uuid";
const appRoot = document.getElementById("root");

const Toast: (message: string, time?: number) => void = (message, time) => {
  let div = document.getElementById("notification-center");
  if (!div) {
    div = document.createElement("div");
    div.setAttribute("id", "notification-center");
    if (appRoot) {
      appRoot.appendChild(div);
    } else {
      document.body.appendChild(div);
    }
  }
  const notificaionID = uuid();
  ReactDOM.render(<TheToast msg={message} time={time} notificaionID={notificaionID} />, div);
};

export default Toast;

const TheToast: React.FC<{ msg: string; time?: number; notificaionID: string }> = ({
  msg,
  time,
  notificaionID,
}) => {
  const [showMsg, setshowMsg] = useState(true);
  useEffect(() => {
    setshowMsg(true);
    setTimeout(() => setshowMsg(false), time ? time : 2000);
  }, [msg, notificaionID, time]);

  if (showMsg)
    return (
      <div className="fixed top-0 left-1/2 -ml-32 w-64 mt-4 py-2 px-4 shadow-lg text-sm bg-white border border-gray-300 rounded flex items-center z-50">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="text-green-500 mr-2"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            fill="currentColor"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z"
          />
        </svg>
        <div>{msg}</div>
      </div>
    );
  else return <div></div>;
};
