import React, { useState } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../../reducers";
import { Condition, ConditionResult } from "../../model/dataTypes";
import {
  ConditionResultsTable,
  ConditionResultsTableManual,
} from "./tables/ConditionResultTable";
import { useSimulationResults } from "fb/useFirestore";
import { OverallResultsTable } from "./tables/CompareResultsTables";
import DisclaimerBox from "components/DisclaimerBox";
import { hasManualEcool } from "utility/CheckECool";

interface Props {}

const Results: React.FC<Props> = () => {
  const { machines, conditions } = useSelector((state: StoreState) => state.result);
  const staticSimResults = useSimulationResults(machines, conditions);
  const [openCondition, setopenCondition] = useState<Condition | null>(null);
  const [selectedParam, setselectedParam] = useState<{
    display: string;
    key: keyof ConditionResult;
  }>({ display: "Efficiency", key: "sum_outside_eff_total" });

  return (
    <>
      <div
        className="text-gray-700 sm:px-4 py-16  print-no-padding print-bg-white print-center-content"
        style={{ backgroundColor: "#e6e6e6" }}
      >
        <div className="w-3/4 lg:w-2/5 mx-auto my-4 pb-2 text-xl border-b border-b-white no-print font-medium">
          Results
        </div>
        <div className="w-full xl:w-4/5 overflow-x-auto mx-auto relative px-4 py-4 bg-white shadow-lg print-hide-shadow">
          <div className="flex mb-4 no-print">
            <button
              className={` ${twStyles.btn} ${
                !openCondition ? twStyles.activeBtn : "text-gray-800"
              }`}
              onClick={() => setopenCondition(null)}
            >
              Compare conditions
            </button>
            {conditions &&
              conditions.map((condition) => {
                const isActive = openCondition && condition.id === openCondition.id;
                return (
                  <button
                    className={`${twStyles.btn} ${
                      isActive ? twStyles.activeBtn : "text-gray-800"
                    }`}
                    onClick={() => setopenCondition(condition)}
                    key={condition.id}
                  >
                    {condition.name}
                  </button>
                );
              })}
          </div>
          {staticSimResults && conditions && machines && (
            <div className="pb-3 text-xxs">
              {openCondition ? (
                hasManualEcool(openCondition) ? (
                  <ConditionResultsTableManual
                    condition={openCondition}
                    staticSimResults={staticSimResults}
                    machines={machines}
                  />
                ) : (
                  <ConditionResultsTable
                    condition={openCondition}
                    staticSimResults={staticSimResults}
                    machines={machines}
                  />
                )
              ) : (
                <OverallResultsTable
                  conditions={conditions}
                  machines={machines}
                  staticSimResults={staticSimResults}
                  selectedParameter={selectedParam}
                  selectParamOptions={[
                    { display: "Efficiency", key: "sum_outside_eff_total" },
                    { display: "Energy", key: "sum_power_total" },
                  ]}
                  onSelectParam={(newParam) => {
                    setselectedParam(newParam);
                  }}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <DisclaimerBox />
    </>
  );
};

export default Results;

const twStyles = {
  btn: "px-2 py-1 rounded shadow border focus:outline-none mr-2 text-xs font-bold",
  activeBtn: "text-white bg-cm-blue border-cm-blue shadow-lg",
};
