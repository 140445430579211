import { ConditionResult } from "model/dataTypes";

export type keysAllowed =
  | keyof ConditionResult
  | "SFP"
  | "m³/s"
  | "m³/h"
  | "l/s";

const parseDisplayValue = (value: string | number, key: keysAllowed) => {
  if (typeof value === "undefined") value = "unknown";
  switch (key) {
    case "sum_power_total":
    case "sum_power_stage2":
    case "sum_power_stage1":
      if (typeof value === "number") value = value.toFixed(1);
      value = `${value} kW`;
      break;
    case "sum_outside_eff_total":
    case "sum_outside_eff_stage1":
    case "sum_outside_eff_stage2":
    case "sum_extract_eff_total":
    case "sum_extract_eff_stage2":
    case "sum_extract_eff_stage1":
    case "supply_hum_val":
    case "outside_hum_val":
    case "intsup1_hum_val":
    case "exhaust_hum_val":
    case "intextract1_hum_val":
    case "intextract1vapor_hum_val":
    case "extractvapor_hum_val":
    case "extract_hum_val":
      if (typeof value === "number") value = value.toFixed(1);
      value = `${value} %`;
      break;
    case "supply_temp_val":
    case "outside_temp_val":
    case "intsup1_temp_val":
    case "exhaust_temp_val":
    case "intextract1_temp_val":
    case "extract_temp_val":
    case "intextract1vapor_temp_val":
    case "extractvapor_temp_val":
      if (typeof value === "number") value = value.toFixed(1);
      value = `${value} °C`;
      break;
    case "sum_outside_pd_total":
    case "outside_pd_val":
    case "sum_outside_pd_stage1":
    case "sum_outside_pd_stage2":
    case "sum_extract_pd_total":
    case "sum_extract_pd_stage1":
    case "sum_extract_pd_stage2":
    case "extract_pd_val":
      if (typeof value === "number") value = value.toFixed(0);
      value = `${value} Pa`;
      break;
    case "outside_w_val":
    case "m³/s":
    case "extract_w_val":
      if (typeof value === "number") value = value.toFixed(1);
      value = `${value} m³/s`;
      break;
    case "intextract1_vap_val":
    case "extract_vap_val":
      if (typeof value === "number") value = value.toFixed(1);
      value = `${value} g/kg`;
      break;
    case "SFP":
      value = `${value} kW/(m³/s)`;
      break;
    case "m³/h":
      if (typeof value === "number") value = value.toFixed(1);
      value = `${value} m³/h`;
      break;
    case "l/s":
      if (typeof value === "number") value = value.toFixed(1);
      value = `${value} l/s`;
  }
  return value;
};

export default parseDisplayValue;
