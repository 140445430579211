import React, { useState } from "react";
import { useCustomMachines } from "fb/useFirestore";
import MachineCard from "../MachineCard";
import { Machine, MachineOption } from "model/dataTypes";
import firebase from "fb/firebase";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "reducers";
import { InputActionTypes, ADD_CUSTOM_MACHINE } from "actions/inputActionTypes";

interface Props {
  onClose: () => void;
}

const ViewCustomMachine: React.FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const { customMachines } = useCustomMachines();
  const [selectedMachines, setSelectedMachines] = useState<Machine[]>([]);
  const { machineOptions } = useSelector((state: StoreState) => state.input);
  const [isLoading, setisLoading] = useState(false);

  const deleteSelectedMachines = () => {
    const promises: Promise<void>[] = [];
    setisLoading(true);
    selectedMachines.forEach(machine => {
      promises.push(firebase.delteCustomMachine(machine.id));
    });
    Promise.all(promises).then(() => {
      setSelectedMachines([]);
      setisLoading(false);
    });
  };

  const addToSimulation = () => {
    selectedMachines.forEach(machine => {
      const machineOption: MachineOption = {
        id: machine.id,
        label: machine.name,
        selected: true,
        filtered: false,
        machine,
      };
      dispatch<InputActionTypes>({
        type: ADD_CUSTOM_MACHINE,
        payload: { machineOption },
      });
    });
    setSelectedMachines([]);
    onClose();
  };

  return (
    <div className="z-50 bg-white px-8 py-8 flex flex-col w-4/5 lg:w-3/4">
      <div className="text-xl font-bold mb-8">Previously added custom machines</div>
      <div className={`h-96 mb-4 overflow-auto px-3 py-3 ${isLoading ? "opacity-50" : ""}`}>
        {customMachines.map(machine => {
          const isSelected = selectedMachines.some(m => m.id === machine.id);
          const isAddedToSimulation = machineOptions.some(o => o.id === machine.id);
          return (
            <div key={machine.id} className="relative">
              <MachineCard
                machine={machine}
                checked={isSelected}
                onSelect={() => {
                  isSelected
                    ? setSelectedMachines(selectedMachines.filter(m => m.id !== machine.id))
                    : setSelectedMachines([...selectedMachines, machine]);
                }}
              />
              {isAddedToSimulation && <div className={tw.rowOverlay}>Added to simulation</div>}
            </div>
          );
        })}
      </div>
      <div className="flex">
        <button
          className={`${tw.button} ${
            selectedMachines.length > 0 ? "" : "opacity-50"
          }  bg-cm-blue`}
          onClick={() => addToSimulation()}
        >
          {`Add ${selectedMachines.length > 0 ? selectedMachines.length : ""} selected`}
        </button>
        <button
          className={`ml-4 ${tw.button} ${
            selectedMachines.length > 0 ? "" : "opacity-50"
          } bg-red-500`}
          onClick={() => !isLoading && deleteSelectedMachines()}
        >
          {`Delete ${selectedMachines.length > 0 ? selectedMachines.length : ""} selected`}
        </button>
      </div>
    </div>
  );
};

const tw = {
  button: "px-4 py-2 rounded text-xs font-bold text-white",
  rowOverlay:
    "absolute z-10 top-0 left-0 w-full h-full bg-gray-600 opacity-90 text-white flex items-center justify-center font-bold",
};

export default ViewCustomMachine;
