import Axios from "axios";

const APIUrl = "http://104.155.127.96/api";

type LocationSearchResponse = {
  file_id: number;
  name: string;
  lat: number;
  lng: number;
  distance: number;
};

export type WeatherAverages = {
  winter: {
    season_average: number;
    day_average: number;
    night_average: number;
  };
  spring: {
    season_average: number;
    day_average: number;
    night_average: number;
  };
  summer: {
    season_average: number;
    day_average: number;
    night_average: number;
  };
  autumn: {
    season_average: number;
    day_average: number;
    night_average: number;
  };
};

type MetaDataResponse = { averages: WeatherAverages };

export type LocationResponse = {
  location: LocationSearchResponse;
  weather: WeatherAverages;
};

export const getClosestWeatherStations = (location: { lat: number; lng: number }) => {
  return new Promise<LocationResponse>(async (resolve, reject) => {
    try {
      const locSearch = await Axios.post<LocationSearchResponse[][]>(
        APIUrl + "/climatedata/closest_climate_file/",
        location
      );
      const locations = locSearch.data[0];
      if (locations.length > 0) {
        const weatherMetadata = await getWeatherMetadata(locations[0].name);
        resolve({ location: locations[0], weather: weatherMetadata.averages });
      } else {
        reject("No Locations found...");
      }
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getWeatherMetadata = (name: string) => {
  return new Promise<MetaDataResponse>((resolve, reject) => {
    Axios.post<MetaDataResponse>(APIUrl + "/climatedata/get_metadata/", { name })
      .then(res => {
        console.log(res);
        resolve(res.data);
      })
      .catch(error => reject(error));
  });
};

export type WeatherData = {
  [key: string]: number[];
};

export const getWeatherData = (name: string) => {
  return new Promise<WeatherData>((resolve, reject) => {
    Axios.post<WeatherData>(APIUrl + "/climatedata/get_csv_data/", {
      name,
      year_start: 0,
      year_end: 0,
    })
      .then(res => {
        console.log(res);
        const { Datasource, ...rest } = res.data;
        resolve(rest);
      })
      .catch(error => reject(error));
  });
};

export const getDailyWeather = () => {
  return new Promise((resolve, reject) => {});
};
