import { gtw } from "globalTWStyles";
import React, { useEffect, useState } from "react";
import CloseIcon from "./basic/Icons/CloseIcon";

const DisclaimerBox: React.FC<{}> = () => {
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  useEffect(() => {
    const disclaimerSeen = localStorage.getItem("disclaimerSeen");
    if (!disclaimerSeen) {
      setShowDisclaimer(true);
    }
  }, []);
  if (!showDisclaimer) return null;
  else
    return (
      <div
        className="fixed w-full bottom-0 left-0 flex justify-center z-20"
        style={{ marginBottom: "0" }}
      >
        <div className="w-full sm:w-3/4 shadow-lg rounded-t border border-gray-200 bg-white z-20 px-4 py-2 relative">
          <div className=" font-medium mb-1">Disclaimer</div>
          <div className="flex items-center">
            <div className="text-xs flex-grow">
              The result of the calculations assume an installation which are
              correct, optimized and well administrated. The result of the
              calculations is based on stardardized normal values, which means
              that the actual performance of an installation might vary from
              these predictions, from year to year and case to case.
              ClimateMachines cannot be held accountable for such deviations.
              The calculations and results are not guaranteed to be realized or
              met. ClimateMachines cannot be held accountable for errors
              regarding information or calculations.
            </div>
            <button
              className={`${gtw.btn} flex-none ml-4 flex flex-col items-center justify-center`}
              onClick={() => {
                setShowDisclaimer(false);
                localStorage.setItem("disclaimerSeen", "true");
              }}
            >
              <span>Ok</span>
              <span>Dont show again</span>
            </button>
            <div
              className="absolute top-0 right-0 h-4 w-4 mt-2 mr-2 cursor-pointer text-gray-600"
              onClick={() => {
                setShowDisclaimer(false);
              }}
            >
              <CloseIcon className="h-full w-full" />
            </div>
          </div>
        </div>
      </div>
    );
};

export default DisclaimerBox;
