import React, { useEffect, useMemo, useState } from "react";
import { PDFViewer, Document, View, Text, Font } from "@react-pdf/renderer";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../reducers";
import { PageA4 } from "./PdfComponents";
import FrontPage from "./FrontPage";
import {
  Machine,
  Condition,
  StaticSimResult,
  ConditionResult,
} from "model/dataTypes";
import { useSimulationResults } from "fb/useFirestore";
import LoadingIcon from "components/basic/LoadingIcon/LoadingIcon";
import CheckECool, { hasManualEcool } from "utility/CheckECool";
import ConditionTablePDF from "./ConditionTablePDF";
import SimResultTablePDF from "./SimResultTablePDF";
import SFPTablePDF from "./SFPTablePDF";
import { saveSimulation } from "actions/fsActions";

const host = window.location.protocol + "//" + window.location.host;

const PdfViewer: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { conditions, machines } = useSelector(
    (state: StoreState) => state.result
  );
  const { fbUUID, savingSimulation } = useSelector(
    (state: StoreState) => state.result
  );
  const [simulationLink, setSimulationLink] = useState<string>("");

  if (!fbUUID) {
    !savingSimulation && dispatch(saveSimulation());
  }

  useEffect(() => {
    setSimulationLink(`${host}/saved/${fbUUID}`);
  }, [fbUUID]);

  useEffect(() => {
    Font.register({
      family: "Roboto",
      fonts: [
        {
          src: `${host}/fonts/Roboto/Roboto-Light.ttf`,
          fontWeight: "light",
        },
        {
          src: `${host}/fonts/Roboto/Roboto-LightItalic.ttf`,
          fontWeight: "light",
          fontStyle: "italic",
        },
        {
          src: `${host}/fonts/Roboto/Roboto-Regular.ttf`,
          fontWeight: "normal",
        },
        {
          src: `${host}/fonts/Roboto/Roboto-Medium.ttf`,
          fontWeight: "medium",
        },
        {
          src: `${host}/fonts/Roboto/Roboto-Bold.ttf`,
          fontWeight: "bold",
        },
      ],
    });
  }, []);

  const staticSimResults = useSimulationResults(machines, conditions);
  let readyToDisplay = useMemo(() => {
    let ready = false;
    if (machines && conditions) {
      let expectedSimResults =
        machines.length * conditions.length +
        machines.length *
          conditions.filter((c) => !hasManualEcool(c) && CheckECool(c)).length;
      if (staticSimResults.length === expectedSimResults) ready = true;
    }
    return ready;
  }, [machines, conditions, staticSimResults]);

  if (!readyToDisplay || !machines || !conditions) {
    return (
      <div className="w-full h-full z-50 bg-white flex items-center justify-center">
        <LoadingIcon />
      </div>
    );
  } else {
    return (
      <ThePDF
        staticSimResults={staticSimResults}
        machines={machines}
        conditions={conditions}
        simulationLink={simulationLink}
      />
    );
  }
};

interface Props {
  machines: Machine[];
  conditions: Condition[];
  staticSimResults: StaticSimResult[];
  simulationLink: string;
}

const ThePDF: React.FC<Props> = ({
  machines,
  conditions,
  staticSimResults,
  simulationLink,
}) => {
  const { projectName, author, showSFP, sfp_tables } = useSelector(
    (state: StoreState) => state.pdf
  );

  return (
    <PDFViewer className="w-full h-full z-50">
      <Document>
        <FrontPage />
        {machines.map((machine) => {
          const caseInfo = staticSimResults
            .filter((res) => res.machineID === machine.id)
            .reduce(
              (prev, cur) => {
                const pd = cur.job.result?.sum_outside_pd_total;

                if (pd && pd > prev.PD_extract) {
                  return {
                    PD_outside: pd,
                    PD_extract: cur.job.result?.sum_extract_pd_total || 0,
                    flow: cur.job.spec.V_flow_extract_air,
                  };
                } else return prev;
              },
              {
                PD_outside: 0,
                PD_extract: 0,
                flow: 0,
              }
            );
          return (
            <PageA4
              key={machine.id}
              projectName={projectName}
              machineID={machine.id}
              machineName={machine.name}
              author={author}
              simulationLink={simulationLink}
              dimensions={{
                outer: machine.outerDimensions,
                heatExchanger: machine.heatExhangerDimensions,
              }}
            >
              {conditions.map((condition) => {
                let conRes = staticSimResults.filter(
                  (res) =>
                    res.machineID === machine.id &&
                    res.conditionID === condition.id
                );
                let res: ConditionResult | undefined;
                if (conRes.length > 1)
                  res = conRes.find((res) => res.ECOOL)?.job.result;
                else res = conRes[0].job.result;
                return (
                  <View
                    wrap={false}
                    key={condition.id}
                    style={{
                      paddingHorizontal: 25,
                      paddingVertical: 20,
                      width: "100%",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                      }}
                    >
                      {condition.name}
                    </Text>
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      <View
                        style={{
                          width: "25%",
                        }}
                      >
                        <ConditionTablePDF condition={condition} />
                      </View>
                      <View
                        style={{
                          width: "75%",
                        }}
                      >
                        {res && <SimResultTablePDF conditionResult={res} />}
                      </View>
                    </View>
                  </View>
                );
              })}
              {showSFP &&
                sfp_tables.map((sfp) => (
                  <View
                    key={sfp.id}
                    wrap={false}
                    style={{
                      paddingHorizontal: 25,
                      paddingVertical: 20,
                      width: "100%",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                        fontWeight: "bold",
                        paddingBottom: 7,
                      }}
                    >
                      {sfp.headline}
                    </Text>
                    <SFPTablePDF sfp={sfp} caseInfo={caseInfo} />
                  </View>
                ))}
            </PageA4>
          );
        })}
      </Document>
    </PDFViewer>
  );
};

export default PdfViewer;
