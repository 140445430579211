import React, { useMemo } from "react";
import { View, StyleSheet, Text, Image, Link } from "@react-pdf/renderer";
import moment, { Moment } from "moment";
import { getMachineImagePng } from "utility/GetMachineIcons";

const PdfHeader: React.FC<{
  projectName?: string;
  author: string;
  date?: Moment;
  machineID?: string;
  machineName?: string;
  simulationLink: string;
  dimensions: {
    outer: {
      depth: number;
      height: number;
      length: number;
    };
    heatExchanger: {
      depth: number;
      height: number;
      length: number;
    };
  };
}> = ({
  projectName,
  date,
  machineID,
  machineName,
  author,
  simulationLink,
  dimensions,
}) => {
  const theDate = useMemo(
    () => date?.format("YYYY-MM-DD") || moment().format("YYYY-MM-DD"),
    [date]
  );

  const { outer: outerDimensions, heatExchanger: heatExchangerDimensions } =
    dimensions;
  const displayHeatExchangerDimensions =
    heatExchangerDimensions.depth !== 0 &&
    heatExchangerDimensions.height !== 0 &&
    heatExchangerDimensions.length !== 0;
  const displayOuterDimensions =
    outerDimensions.depth !== 0 &&
    outerDimensions.height !== 0 &&
    outerDimensions.length !== 0;

  const styles = useMemo(
    () =>
      StyleSheet.create({
        header: {
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: projectName && !!author.length ? 105 : 100,
          paddingHorizontal: 25,
          paddingTop: 20,
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#F5F5F5",
        },
      }),
    [projectName, author.length]
  );
  const machineIMG = useMemo(
    () => machineID && getMachineImagePng(machineID),
    [machineID]
  );

  return (
    <View fixed style={styles.header}>
      <View style={{}}>
        <Text
          style={{
            fontSize: 18,
            fontWeight: "light",
            marginBottom: 3,
          }}
        >
          Simulation Results
        </Text>
        <View
          style={{ flexDirection: "row", alignItems: "center", fontSize: 8 }}
        >
          <View style={{ paddingRight: 16 }}>
            <Text style={{ paddingVertical: 2 }}>Date:</Text>
            {projectName && projectName.length > 0 && (
              <Text style={{ paddingVertical: 2 }}>Project:</Text>
            )}
            {author.length > 0 && (
              <Text style={{ paddingVertical: 2 }}>Author:</Text>
            )}
            <Text style={{ paddingVertical: 2 }}>Link:</Text>
          </View>
          <View style={{ fontWeight: "bold" }}>
            <Text style={{ paddingVertical: 2 }}>{theDate}</Text>
            {projectName && projectName.length > 0 && (
              <Text style={{ paddingVertical: 2 }}>{projectName}</Text>
            )}
            {author.length > 0 && (
              <Text style={{ paddingVertical: 2 }}>{author}</Text>
            )}
            <Text
              style={{
                paddingVertical: 2,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Link
                src={simulationLink}
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                {simulationLink}
              </Link>
            </Text>
          </View>
        </View>
      </View>

      <View
        style={{ flexDirection: "column", alignItems: "center", padding: 2 }}
      >
        {machineIMG && (
          <Image style={{ width: 40, height: 40 }} src={machineIMG} />
        )}
        {machineName && (
          <Text
            style={{
              fontSize: 8,
              marginTop: 2,
              fontWeight: "light",
              fontStyle: "italic",
            }}
          >
            {machineName}
          </Text>
        )}
        {displayHeatExchangerDimensions && (
          <Text
            style={{
              fontSize: 7,
              fontWeight: "light",
              marginTop: 3,
            }}
          >
            <Text style={{ color: "#737373" }}>Heat exchanger: </Text>
            {`${heatExchangerDimensions.length} x ${heatExchangerDimensions.height} x ${heatExchangerDimensions.depth}`}
          </Text>
        )}
        {displayOuterDimensions && (
          <Text
            style={{
              fontSize: 7,
              fontWeight: "light",
              marginTop: displayHeatExchangerDimensions ? 1 : 3,
            }}
          >
            <Text style={{ color: "#737373" }}>Outer dimensions: </Text>
            {`${outerDimensions.length} x ${outerDimensions.height} x ${outerDimensions.depth}`}
          </Text>
        )}
      </View>
    </View>
  );
};

export default PdfHeader;
