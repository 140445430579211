import React from "react";
import logo from "../../resources/logo.svg";

interface Props {}

const Footer: React.FC<Props> = () => {
  return (
    <div className="w-full flex justify-between items-center border-t border-gray-200 py-6 mt-16 text-xs">
      <a
        className="text-gray-text px-4"
        href="mailto: info@climatemachines.com"
      >
        info@climatemachines.com
      </a>
      <img className="px-4" src={logo} alt="logo" style={{ fill: "#5bc4ec" }} />
      <div className="px-4">
        <div className="mb-1">Version 0.1.1</div>
      </div>
    </div>
  );
};

export default Footer;
