import { SFP_Table } from "reducers/pdfReducer";

export const DISPLAY_PDF = "DISPLAY_PDF";
export const HIDE_PDF = "HIDE_PDF";
export const SET_PROJECT_NAME = "SET_PROJECT_NAME";
export const SET_AUTHOR = "SET_AUTHOR";
export const TOGGLE_SPECIFIC_FAN_POWER = "TOGGLE_SPECIFIC_FAN_POWER";
export const SET_QR_CODE = "SET_QR_CODE";
export const SHOW_PDF_SETTINGS = "SHOW_PDF_SETTINGS";
export const HIDE_PDF_SETINGS = "HIDE_PDF_SETINGS";
export const UPDATE_SFP_TABLE = "UPDATE_SFP_TABLE";
export const UPDATE_SFP_FLOW_UNIT = "UPDATE_SFP_FLOW_UNIT";
export const TOGGLE_SFP_EXT_PRESSURE = "TOGGLE_SFP_EXT_PRESSURE";
export const DELETE_FROM_SFP_TABLE = "DELETE_FROM_SFP_TABLE";

interface DisplayPDF {
  type: typeof DISPLAY_PDF;
}

interface SetQRCode {
  type: typeof SET_QR_CODE;
  payload: string | null;
}

interface HidePDF {
  type: typeof HIDE_PDF;
}

interface SetProjectName {
  type: typeof SET_PROJECT_NAME;
  payload?: string;
}

interface ToggleSFP {
  type: typeof TOGGLE_SPECIFIC_FAN_POWER;
  payload: boolean;
}

interface SetAuthor {
  type: typeof SET_AUTHOR;
  payload: string;
}

interface ShowPdfSettings {
  type: typeof SHOW_PDF_SETTINGS;
}

interface UpdateSFPTable {
  type: typeof UPDATE_SFP_TABLE;
  payload: SFP_Table;
}

interface UpdateSFPFlowUnit {
  type: typeof UPDATE_SFP_FLOW_UNIT;
  payload: { sfp: SFP_Table; updatedUnit: string };
}

interface ToggleSFPExtPressure {
  type: typeof TOGGLE_SFP_EXT_PRESSURE;
  payload: SFP_Table;
}

interface DeleteFromSFPTable {
  type: typeof DELETE_FROM_SFP_TABLE;
  payload: SFP_Table;
}
interface HidePdfSettings {
  type: typeof HIDE_PDF_SETINGS;
}

export type PdfActionTypes =
  | DisplayPDF
  | SetQRCode
  | HidePDF
  | SetProjectName
  | ShowPdfSettings
  | HidePdfSettings
  | SetAuthor
  | ToggleSFP
  | UpdateSFPTable
  | UpdateSFPFlowUnit
  | ToggleSFPExtPressure
  | DeleteFromSFPTable;
