import {
  PdfActionTypes,
  DISPLAY_PDF,
  HIDE_PDF,
  SET_PROJECT_NAME,
  SHOW_PDF_SETTINGS,
  HIDE_PDF_SETINGS,
  SET_QR_CODE,
  SET_AUTHOR,
  TOGGLE_SPECIFIC_FAN_POWER,
  UPDATE_SFP_TABLE,
  DELETE_FROM_SFP_TABLE,
  UPDATE_SFP_FLOW_UNIT,
  TOGGLE_SFP_EXT_PRESSURE,
} from "actions/pdfActionTypes";
import { updateArrayObject } from "utility/Array";
import { convertUnit } from "utility/convertUnit";
import uuid from "uuid";

export type SFP_Table = {
  id: string;
  headline: string;
  flow_unit: string;
  flow_supply: number;
  flow_exhaust: number;
  external_pl_supply: number;
  external_pl_exhaust?: number;
  component_pl_outside: number;
  component_pl_extract: number;
  sfp_max: number;
  sfp_bypass: number;
};

export type PdfState = {
  qrCode: string | null;
  projectName?: string;
  author: string;
  showSFP: boolean;
  sfp_tables: SFP_Table[];
  showPDF: boolean;
  showPDFSettings: boolean;
};

const initialState: PdfState = {
  qrCode: null,
  showPDF: false,
  showPDFSettings: false,
  showSFP: false,
  sfp_tables: [
    {
      id: uuid(),
      headline: "Specific fan power",
      flow_unit: "m³/s",
      flow_supply: 0,
      flow_exhaust: 0,
      external_pl_supply: 0,
      component_pl_outside: 0,
      component_pl_extract: 0,
      sfp_max: 0,
      sfp_bypass: 0,
    },
  ],
  author: "",
};

export function pdfReducer(
  state = initialState,
  action: PdfActionTypes
): PdfState {
  switch (action.type) {
    case SHOW_PDF_SETTINGS:
      return { ...state, showPDFSettings: true };
    case HIDE_PDF_SETINGS:
      return { ...state, showPDFSettings: false };
    case SET_QR_CODE:
      return { ...state, qrCode: action.payload };
    case DISPLAY_PDF:
      return {
        ...state,
        showPDF: true,
        showPDFSettings: false,
      };
    case HIDE_PDF:
      return { ...state, showPDF: false };
    case SET_PROJECT_NAME:
      return { ...state, projectName: action.payload };
    case SET_AUTHOR:
      return { ...state, author: action.payload };
    case TOGGLE_SPECIFIC_FAN_POWER:
      if (!state.sfp_tables.length && action.payload) {
        return {
          ...state,
          showSFP: action.payload,
          sfp_tables: initialState.sfp_tables,
        };
      } else {
        return { ...state, showSFP: action.payload };
      }
    case UPDATE_SFP_TABLE:
      return {
        ...state,
        sfp_tables: updateArrayObject(state.sfp_tables, action.payload),
      };
    case UPDATE_SFP_FLOW_UNIT:
      const currentUnit = action.payload.sfp.flow_unit;
      const updatedUnit = action.payload.updatedUnit;

      const updatedSupply = convertUnit(
        currentUnit,
        updatedUnit,
        action.payload.sfp.flow_supply
      );
      const updatedExhaust = convertUnit(
        currentUnit,
        updatedUnit,
        action.payload.sfp.flow_exhaust
      );

      const updatedSFPTable: SFP_Table = {
        ...action.payload.sfp,
        flow_supply: updatedSupply,
        flow_exhaust: updatedExhaust,
        flow_unit: updatedUnit,
      };

      return {
        ...state,
        sfp_tables: updateArrayObject(state.sfp_tables, updatedSFPTable),
      };
    case TOGGLE_SFP_EXT_PRESSURE:
      let sfpTable = action.payload;
      if (sfpTable.hasOwnProperty("external_pl_exhaust")) {
        delete sfpTable.external_pl_exhaust;
      } else {
        const external_pl_exhaust = sfpTable.external_pl_supply || 0;
        sfpTable = { ...sfpTable, external_pl_exhaust };
      }
      return {
        ...state,
        sfp_tables: updateArrayObject(state.sfp_tables, sfpTable),
      };
    case DELETE_FROM_SFP_TABLE:
      const updatedTable = state.sfp_tables.filter(
        (table) => table.id !== action.payload.id
      );
      const showSFP = !!updatedTable.length;
      return {
        ...state,
        showSFP,
        sfp_tables: updatedTable,
      };
    default:
      return state;
  }
}
