import React, { useState } from "react";
import { authenticateUser } from "../../actions/fsActions";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../reducers";
import LoadingIcon from "../basic/LoadingIcon/LoadingIcon";

const Login: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { loggingIn, loginError } = useSelector((state: StoreState) => state.user);

  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const authenticate = () => {
    dispatch(authenticateUser(email, password));
  };
  return (
    <div className={twStyles.loginContainer}>
      <div className={twStyles.loginBg}>
        <div className="font-medium">Admin Login</div>
        <div className="my-4 flex flex-col">
          <label className={`${twStyles.label}`}>Email:</label>
          <input
            type="email"
            className={`${twStyles.inputBox}`}
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
        </div>
        <div className="my-4 flex flex-col">
          <label className={`${twStyles.label}`}>Password:</label>
          <input
            type="password"
            onKeyDown={(e) => e.key === "Enter" && authenticate()}
            className={`${twStyles.inputBox}`}
            value={password}
            onChange={(e) => setpassword(e.target.value)}
          />
        </div>
        <button
          onClick={() => authenticate()}
          className={`px-4 py-2 bg-cm-blue font-bold text-white w-full rounded shadow relative ${
            loggingIn ? "opacity-50" : ""
          }`}
        >
          Login
          {loggingIn && (
            <span className="absolute right-0 mr-20 top-1/2 -mt-2">
              <LoadingIcon />
            </span>
          )}
        </button>
        {loginError && <div className="italic text-xs text-red-500">{loginError}</div>}
      </div>
    </div>
  );
};

const twStyles = {
  loginContainer:
    "w-full h-screen bg-gray-100 text-gray-800 flex flex-col items-center justify-center",
  loginBg: "px-8 py-4 w-1/2 lg:w-1/3 shadow-xl bg-white rounded-lg",
  inputBox: "px-2 py-1 border border-gray-400 rounded",
  label: "text-xs font-medium",
};

export default Login;
