import { Condition, MachineOption } from "../model/dataTypes";
export const LOAD_MACHINES = "LOAD_MACHINES";
export const LOAD_SAVED_INPUT = "LOAD_SAVED_INPUT";
export const ERROR_LOADING_SAVED_INPUT = "ERROR_LOADING_SAVED_INPUT";
export const ADD_CUSTOM_MACHINE = "ADD_CUSTOM_MACHINE";
export const START_LOAD_SAVED_SIMULATION = "START_LOAD_SAVED_SIMULATION";
export const START_SAVING_SIMULATION = "START_SAVING_SIMULATION";
export const SIMULATION_SAVED = "SIMULATION_SAVED";
export const SIMULATION_SAVED_FAIL = "SIMULATION_SAVED_FAIL";
export const ERROR_LOADING_MACHINES = "ERROR_LOADING_MACHINES";
export const START_REQUESTING_SIMULATION = "START_REQUESTING_SIMULATION";

interface LoadMachinesAction {
  type: typeof LOAD_MACHINES;
  payload: { machines: MachineOption[] };
}
interface ErrorLoadingMachinesAction {
  type: typeof ERROR_LOADING_MACHINES;
}

interface LoadSavedInputAction {
  type: typeof LOAD_SAVED_INPUT;
  payload: {
    machineOptions: MachineOption[];
    conditions: Condition[];
  };
}

interface ErrorLoadingSavedInputAction {
  type: typeof ERROR_LOADING_SAVED_INPUT;
}

interface StartLoadSavedSimulation {
  type: typeof START_LOAD_SAVED_SIMULATION;
  payload: { UUID: string };
}

interface StartSavingSimulationAction {
  type: typeof START_SAVING_SIMULATION;
}
interface SimulationSaveFailedAction {
  type: typeof SIMULATION_SAVED_FAIL;
  payload: { errorMessage: string };
}
interface SimulationSavedAction {
  type: typeof SIMULATION_SAVED;
  payload: { UUID: string };
}
interface StartRequestingSimulation {
  type: typeof START_REQUESTING_SIMULATION;
}

export type FirestoreActionTypes =
  | LoadMachinesAction
  | LoadSavedInputAction
  | ErrorLoadingSavedInputAction
  | StartLoadSavedSimulation
  | StartSavingSimulationAction
  | SimulationSaveFailedAction
  | SimulationSavedAction
  | ErrorLoadingMachinesAction
  | StartRequestingSimulation;
