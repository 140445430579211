import Header from "./Header";
import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../components/footer/Footer";
import Results from "./results/Results";
import { StoreState } from "../reducers";
import SaveView from "./save/SaveView";
import InputWrapper from "./input/InputWrapper";
import ScrollToOnAppear from "../containers/ScrollToOnAppear";
import Modal from "./basic/Modal";
import PdfViewer from "components/pdf/PdfViewer";
import { HIDE_PDF } from "actions/pdfActionTypes";
import PdfSettings from "./pdf/PdfSettings";
import LoggedUseModal from "./loggedUse/LoggedUseModal";

const MainPage: React.FC = () => {
  const dispatch = useDispatch();
  const { showResults } = useSelector((state: StoreState) => state.result);
  const { showPDF, showPDFSettings } = useSelector(
    (state: StoreState) => state.pdf
  );

  const [showAppUsesModal, setShowAppUsesModal] = useState(false);

  const resultTableRef = useRef<HTMLDivElement>(null);

  return (
    <div className="w-screen min-h-screen relative pt-24 overflow-hidden">
      {showPDF && (
        <Modal
          onClose={() => {
            dispatch({ type: HIDE_PDF });
          }}
        >
          <div className="z-50 w-5/6 h-full">
            <PdfViewer />
          </div>
        </Modal>
      )}
      {showPDFSettings && <PdfSettings />}
      {showAppUsesModal && (
        <LoggedUseModal onClose={() => setShowAppUsesModal(false)} />
      )}
      <Header showModal={() => setShowAppUsesModal(true)} />
      <InputWrapper />
      {showResults && (
        <ScrollToOnAppear>
          <div ref={resultTableRef}>
            <Results />
          </div>
        </ScrollToOnAppear>
      )}
      {showResults && <SaveView refToPrint={resultTableRef} />}
      <div className="container mx-auto text-gray-text">
        <Footer />
      </div>
    </div>
  );
};

export default MainPage;
