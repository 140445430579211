import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../reducers";
import { InputActionTypes, TOGGLE_MACHINE } from "../../../actions/inputActionTypes";
import { MachineOption } from "../../../model/dataTypes";
import MachineCard from "./MachineCard";
interface Props {}

const MachineList: React.FC<Props> = () => {
  const dispatch = useDispatch();

  const ref = useRef<HTMLDivElement>(null);

  const { machineOptions } = useSelector((state: StoreState) => state.input);

  const useAdminMode = useSelector((state: StoreState) => state.user.useAdminMode);

  // const toggleSuggestMachine = (active: boolean) =>
  //   dispatch<InputActionTypes>({
  //     type: TOGGLE_AUTO_SUGGEST_MACHINE,
  //     payload: active,
  //   });

  const toggleMachineOption = (activated: boolean, machine: MachineOption) => {
    dispatch<InputActionTypes>({
      type: TOGGLE_MACHINE,
      payload: { activated, machine },
    });
  };

  //If machines are added, scroll to the bottom, to view:
  const [machineOptionsLength, setMachineOptionsLength] = useState(0);
  useEffect(() => {
    if (
      machineOptionsLength > 0 &&
      machineOptions.length > machineOptionsLength &&
      ref.current
    )
      ref.current.scroll({
        top: 2000,
        behavior: "smooth",
      });
    setMachineOptionsLength(machineOptions.length);
  }, [machineOptions, machineOptionsLength]);

  const filteredMachineOptions = machineOptions
    .filter((option) => !option.filtered)
    .sort((a, b) => {
      if (a.machine.maxFlow === b.machine.maxFlow) {
        return a.machine.outerDimensions.height - b.machine.outerDimensions.height;
      } else return a.machine.maxFlow - b.machine.maxFlow;
    });

  return (
    <>
      <div className="overflow-auto h-128 flex items-start content-start flex-wrap" ref={ref}>
        {filteredMachineOptions.map((option, i) => (
          <div
            key={option.id}
            className={`px-3 my-3 w-full ${useAdminMode ? "max-w-3xl" : "max-w-xl lg:w-1/2"}`}
          >
            <MachineCard
              machine={option.machine}
              filtered={option.filtered}
              onSelect={() =>
                !option.filtered && toggleMachineOption(!option.selected, option)
              }
              saved={option.saved}
              allowEdit
              checked={!option.filtered && option.selected}
            />
          </div>
        ))}
        {filteredMachineOptions.length === 0 && (
          <div className="italic">No Machines fit current constraints</div>
        )}
      </div>
    </>
  );
};

export default MachineList;
