import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  InputActionTypes,
  UPDATE_FLOW_FILTER,
  UPDATE_DIMENSION_FILTER,
} from "../../../../actions/inputActionTypes";
import { StoreState } from "reducers";
import { InputNum } from "components/input/basic/Input";
import { InputState } from "reducers/inputReducer";
import dimensionSVG from "resources/DIMENSIONS.svg";
import "./flowSlider.css";

interface Props {}

const MachineFilter: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { flowFilter, dimensionFilter, machineOptions } = useSelector(
    (state: StoreState) => state.input
  );
  const { minFlow, maxFlow } = flowFilter;
  const [showFilter, setshowFilter] = useState<boolean>(true);

  const amountFiltered = machineOptions.filter((o) => !o.filtered).length;

  const updateFlowRange = (minFlow: number, maxFlow: number) => {
    dispatch<InputActionTypes>({
      type: UPDATE_FLOW_FILTER,
      payload: { minFlow, maxFlow },
    });
  };

  const updateDimensions = (
    val: number | null,
    key: keyof InputState["dimensionFilter"]
  ) => {
    dispatch<InputActionTypes>({
      type: UPDATE_DIMENSION_FILTER,
      payload: { ...dimensionFilter, [key]: val },
    });
  };

  return (
    <div
      className={`border-b border-gray-200 overflow-hidden ${
        showFilter ? "h-128 lg:h-72" : "h-10"
      }`}
      style={{ transition: "height 0.3s ease" }}
    >
      <div
        onClick={() => setshowFilter(!showFilter)}
        className="px-4 text-xs font-bold flex items-center cursor-pointer my-2"
      >
        <svg
          style={{
            transform: showFilter ? "rotate(450deg)" : "rotate(0deg)",
            transition: "transform 0.4s ease",
          }}
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M9.29 15.88L13.17 12 9.29 8.12c-.39-.39-.39-1.02 0-1.41.39-.39 1.02-.39 1.41 0l4.59 4.59c.39.39.39 1.02 0 1.41L10.7 17.3c-.39.39-1.02.39-1.41 0-.38-.39-.39-1.03 0-1.42z" />
        </svg>

        <span className="ml-2 underline">
          {amountFiltered < machineOptions.length
            ? `Filter (${amountFiltered}/${machineOptions.length})`
            : "Filter"}
        </span>
      </div>

      {showFilter && (
        <div className="h-full flex flex-wrap px-8 py-2">
          <div className="w-full lg:w-1/2 flex-none px-4">
            <div className="text-lg">Size allowance</div>
            <div className="text-xs w-full flex justify-between items-end">
              Optional
              <span className="text-xs text-gray-500">Machine size</span>
            </div>
            <hr className="" />
            <div className="flex justify-center lg:block">
              <div className="relative w-64 -mt-4 ml-14 text-xs">
                <img alt="spacial dimension" src={dimensionSVG} />
                <div className="absolute bottom-0 left-0 -ml-12 -mb-2">
                  <span className="font-bold mr-4">Max depth</span>
                  <InputNum
                    placeholder="..."
                    className={twStyles.numberInput}
                    unit="m"
                    showButtons={true}
                    incrementBy={0.1}
                    onlyPositive={true}
                    value={dimensionFilter.maxDepth}
                    onChange={(v) => {
                      updateDimensions(v > 0 ? v : null, "maxDepth");
                    }}
                  />
                </div>
                <div className="absolute bottom-0 right-0 mr-6 -mb-2">
                  <span className="font-bold mr-4">Max length </span>

                  <InputNum
                    placeholder="..."
                    className={twStyles.numberInput}
                    unit="m"
                    showButtons={true}
                    incrementBy={0.1}
                    onlyPositive={true}
                    value={dimensionFilter.maxLength}
                    onChange={(v) => {
                      updateDimensions(v > 0 ? v : null, "maxLength");
                    }}
                  />
                </div>
                <div className="absolute right-0 bottom-0 mb-16 -mr-14 text-xs">
                  <span className="font-bold mr-4">Max height </span>
                  <InputNum
                    placeholder="..."
                    className={twStyles.numberInput}
                    showButtons={true}
                    unit="m"
                    incrementBy={0.1}
                    onlyPositive={true}
                    value={dimensionFilter.maxHeight}
                    onChange={(v) => {
                      updateDimensions(v > 0 ? v : null, "maxHeight");
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:w-1/2 px-4">
            <div className="text-lg">Flow range</div>
            <div className="text-xs">Optional</div>
            <hr />
            <div className="text-xs font-bold my-4 flex justify-between">
              <span>Optimal flow rate between</span>
              <span className="italic text-xs font-thin text-gray-500">
                Above 16 m<sup>3</sup>/s, please contact us!
              </span>
            </div>
            <FlowSlider
              minFlow={minFlow}
              maxFlow={maxFlow}
              updateFlowRange={updateFlowRange}
              labels={machineOptions.map((option) => ({
                id: option.machine.id,
                val: option.machine.optimalFlow,
              }))}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MachineFilter;

const twStyles = {
  numberInput: "border border-gray-300 w-20",
};

interface FlowSliderProps {
  minFlow: number;
  maxFlow: number;
  updateFlowRange: (minFlow: number, maxFlow: number) => void;
  labels: { id: string; val: number }[];
}

const FlowSlider: React.FC<FlowSliderProps> = ({
  minFlow,
  maxFlow,
  updateFlowRange,
  labels,
}) => {
  const maxPosible = 16;
  const percentMin = (minFlow / maxPosible) * 100;
  const percentMax = (maxFlow / maxPosible) * 100;

  return (
    <div className="relative w-full flow-slider h-1 bg-gray-200 rounded-full">
      <input
        type="range"
        className="absolute w-full top-0 left-0 mt-1"
        style={{ marginTop: "0.125rem" }}
        value={minFlow}
        min={0}
        max={maxPosible}
        step={0.1}
        onChange={(e) => {
          const newMin = parseFloat(e.target.value);
          newMin <= maxFlow - 1 && updateFlowRange(newMin, maxFlow);
        }}
      />
      <input
        type="range"
        className="absolute w-full top-0 left-0"
        style={{ marginTop: "0.125rem" }}
        value={maxFlow}
        min={0}
        max={maxPosible}
        step={0.1}
        onChange={(e) => {
          const newMax = parseFloat(e.target.value);
          newMax >= minFlow + 1 && updateFlowRange(minFlow, newMax);
        }}
      />

      {/* slider styling stuff: */}
      <div className="px-2 w-full">
        <div className="relative w-full">
          <div
            className="absolute top-0 left-0 h-1"
            style={{
              marginLeft: `${percentMin}%`,
              width: `${percentMax - percentMin}%`,
            }}
          >
            <div className="bg-cm-blue w-full h-full" />
          </div>

          {labels.map((label) => {
            const inRange = label.val >= minFlow && label.val <= maxFlow;
            return (
              <div
                key={label.id}
                className={`absolute top-0 left-0 rounded-full h-2 w-2 bg-white border ${
                  inRange ? "border-cm-blue" : "bg-gray"
                }`}
                style={{
                  marginLeft: `${(label.val / maxPosible) * 100}%`,
                  marginTop: "-0.125rem",
                }}
              />
            );
          })}
          <div
            className="absolute top-0 left-0 mt-10 text-cm-blue w-20"
            style={{ marginLeft: `${percentMin}%` }}
          >
            <div
              className="flex items-center"
              style={{ transform: "rotate(45deg)", marginLeft: "-1rem" }}
            >
              <svg
                className="mr-1"
                xmlns="http://www.w3.org/2000/svg"
                width="11.253"
                height="3.926"
                viewBox="0 0 11.253 3.926"
              >
                <path
                  id="Path_432"
                  data-name="Path 432"
                  d="M6036,2570v7.827l-3.072,3.072"
                  transform="translate(2581.253 -6032.574) rotate(90)"
                  fill="none"
                  stroke="#19ade5"
                  strokeWidth="1"
                />
              </svg>
              <div>{minFlow} m³/s</div>
            </div>
          </div>

          <div
            className="absolute top-0 left-0 mt-10 text-cm-blue w-20"
            style={{ marginLeft: `${percentMax}%` }}
          >
            <div
              className="flex items-center"
              style={{ transform: "rotate(45deg)", marginLeft: "-1rem" }}
            >
              <svg
                className="mr-1"
                xmlns="http://www.w3.org/2000/svg"
                width="11.253"
                height="3.926"
                viewBox="0 0 11.253 3.926"
              >
                <path
                  id="Path_432"
                  data-name="Path 432"
                  d="M6036,2570v7.827l-3.072,3.072"
                  transform="translate(2581.253 -6032.574) rotate(90)"
                  fill="none"
                  stroke="#19ade5"
                  strokeWidth="1"
                />
              </svg>
              <div>{maxFlow} m³/s</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
