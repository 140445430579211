import React from "react";
import InputContainer from "../basic/InputContainer";
import { Table, Column } from "../basic/table/Table";
import EditingRow from "./EditingRow";
import { Condition } from "../../../model/dataTypes";
import { useSelector, useDispatch } from "react-redux";
import { StoreState } from "../../../reducers";
import {
  UPDATE_CONDITION,
  InputActionTypes,
  REMOVE_CONDITION,
  ADD_CONDITION,
} from "../../../actions/inputActionTypes";
import { stdConditions, defaultCondition } from "../../../api/GetConditions";
import uuid from "uuid/v4";

interface Props {}

const ConditionSelector: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const { conditions } = useSelector((state: StoreState) => state.input);

  const updateCondition = (editedCondition: Condition) => {
    dispatch<InputActionTypes>({ type: UPDATE_CONDITION, payload: { editedCondition } });
  };
  const AddCondition = (condition: Condition) => {
    const newCondition = { ...condition, id: uuid() };
    dispatch<InputActionTypes>({ type: ADD_CONDITION, payload: { condition: newCondition } });
  };
  const RemoveCondition = (id: string) => {
    dispatch<InputActionTypes>({ type: REMOVE_CONDITION, payload: { id } });
  };
  const isAdmin = useSelector((state: StoreState) => state.user.useAdminMode);

  const columns = isAdmin ? adminColumns : stdColumns;

  return (
    <InputContainer
      headline="Define cases"
      info={
        <div className="w-48 text-xs">
          Select static cases for which each machine will be simulated.
        </div>
      }
      number={2}
      isActive={conditions.length > 0}
      preventOverflow
    >
      <div className="overflow-auto pb-6">
        <Table columns={columns}>
          {conditions.map((row) => {
            return (
              <EditingRow
                key={row.id}
                conditionData={row}
                remove={RemoveCondition}
                update={updateCondition}
                isAdmin={isAdmin}
              />
            );
          })}
        </Table>
      </div>
      <div className="mb-16">
        <button className={`${tw.addBtn}`} onClick={() => AddCondition(defaultCondition)}>
          Add custom case
        </button>
        <button className={`${tw.addBtn}`} onClick={() => AddCondition(stdConditions[0])}>
          Add summer case
        </button>
        <button className={`${tw.addBtn}`} onClick={() => AddCondition(stdConditions[2])}>
          Add winter case
        </button>
        <button className={`${tw.addBtn}`} onClick={() => AddCondition(stdConditions[1])}>
          Add SS-EN308:1997
        </button>
      </div>
    </InputContainer>
  );
};

export default ConditionSelector;

export const tw = {
  addBtn:
    "px-3 py-1 rounded border border-gray-500 font-medium text-gray-700 text-xs mr-3 mb-3 focus:outline-none hover:border-cm-blue shadow",
};

const stdColumns: Column[] = [
  {
    title: "Case",
    id: "case",
  },
  {
    title: "Outside Air",
    id: "outsideAir",
  },
  {
    title: "Outside humidity",
    id: "outsideHumidity",
  },
  {
    title: "Outside flow",
    id: "outsideFlow",
  },
  {
    title: "Extract air",
    id: "extractAir",
  },
  {
    title: "Extract humidity",
    id: "extractHumidity",
  },
  {
    title: "Extract flow",
    id: "extractFlow",
  },
  {
    title: "",
    id: "edit",
  },
];

const adminColumns: Column[] = [
  {
    title: "Case",
    id: "case",
  },
  {
    title: "Outside Air",
    id: "outsideAir",
  },
  {
    title: "Outside humidity",
    id: "outsideHumidity",
  },
  {
    title: "Outside flow",
    id: "outsideFlow",
  },
  {
    title: "Extract air",
    id: "extractAir",
  },
  {
    title: "Extract humidity",
    id: "extractHumidity",
  },
  {
    title: "Extract flow",
    id: "extractFlow",
  },
  {
    title: "Humidity target",
    id: "evapCooling",
  },
  {
    title: "",
    id: "edit",
  },
];
