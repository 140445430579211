import React from "react";
import { Condition } from "model/dataTypes";

const ConditionInfo: React.FC<{ condition: Condition }> = ({ condition }) => {
  return (
    <table className="text-xxs font-normal w-40">
      <tbody>
        <tr>
          <td className={`${twStyles.infoTd}`}>Outside air</td>
          <td className={`${twStyles.infoTd} ${twStyles.rightInfoTd}`}>
            {condition.outsideAir}°C
          </td>
        </tr>
        <tr>
          <td className={`${twStyles.infoTd}`}>Outside flow</td>
          <td className={`${twStyles.infoTd} ${twStyles.rightInfoTd}`}>
            {condition.outsideFlow}m³/s
          </td>
        </tr>
        <tr>
          <td className={`${twStyles.infoTd}`}>Outside humidity</td>
          <td className={`${twStyles.infoTd} ${twStyles.rightInfoTd}`}>
            {condition.outsideHumidity}%
          </td>
        </tr>
        <tr>
          <td className={`${twStyles.infoTd}`}>Extract air</td>
          <td className={`${twStyles.infoTd} ${twStyles.rightInfoTd}`}>
            {condition.extractAir}°C
          </td>
        </tr>
        <tr>
          <td className={`${twStyles.infoTd}`}>Extract flow</td>
          <td className={`${twStyles.infoTd} ${twStyles.rightInfoTd}`}>
            {condition.extractFlow}m³/s
          </td>
        </tr>
        <tr>
          <td className={`${twStyles.infoTd}`}>Extract humidity</td>
          <td className={`${twStyles.infoTd} ${twStyles.rightInfoTd}`}>
            {condition.extractHumidity}%
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ConditionInfo;

const twStyles = {
  td: "px-3 border border-gray-200 flex items-center",
  tableHead: "font-bold text-sm h-20 flex",
  tr: "bg-white h-12 flex",
  infoTd: "px-3 py-1",
  rightInfoTd: "",
};
