import React, { useState } from "react";
import { ConditionResult } from "../../model/dataTypes";
import parseDisplayValue from "utility/parseDisplayValue";

interface Props {
  simResult: ConditionResult;
  heatExchangerDimensions: {
    depth: number;
    height: number;
    length: number;
  };
  outerDimensions: {
    depth: number;
    height: number;
    length: number;
  };
}

const GraphicSVG: React.FC<Props> = ({
  simResult,
  heatExchangerDimensions,
  outerDimensions,
}) => {
  const [hoverOutside, sethoverOutside] = useState(false);
  const [hoverInside, sethoverInside] = useState(false);

  return (
    <svg viewBox="0 0 960 775" width="100%" height="100%">
      <defs>
        <filter
          filterUnits="userSpaceOnUse"
          height={173.599}
          width={232.558}
          y={165.81}
          x={525.692}
          id="parsedSVG_svg__b"
        >
          <feOffset dy={3} dx={1} />
          <feGaussianBlur result="blur" stdDeviation={7.5} />
          <feFlood floodOpacity={0.161} />
          <feComposite result="result1" in2="blur" operator="in" />
          <feComposite in2="result1" in="SourceGraphic" />
        </filter>
        <filter
          filterUnits="userSpaceOnUse"
          height={173.599}
          width={232.558}
          y={165.81}
          x={176.535}
          id="parsedSVG_svg__c"
        >
          <feOffset dy={3} dx={1} />
          <feGaussianBlur result="blur-2" stdDeviation={7.5} />
          <feFlood floodOpacity={0.161} />
          <feComposite result="result1" in2="blur-2" operator="in" />
          <feComposite in2="result1" in="SourceGraphic" />
        </filter>
        <filter
          filterUnits="userSpaceOnUse"
          height={245.881}
          width={497.303}
          y={518.119}
          x={224}
          id="parsedSVG_svg__d"
        >
          <feOffset />
          <feGaussianBlur result="blur-3" stdDeviation={6} />
          <feFlood floodColor="#e9e9e9" />
          <feComposite result="result1" in2="blur-3" operator="in" />
          <feComposite in2="result1" in="SourceGraphic" />
        </filter>
        <filter
          filterUnits="userSpaceOnUse"
          height={23}
          width={177.193}
          y={233.5}
          x={385.307}
          id="parsedSVG_svg__e"
        >
          <feOffset />
          <feGaussianBlur result="blur-4" stdDeviation={3.5} />
          <feFlood floodColor="#897277" />
          <feComposite result="result1" in2="blur-4" operator="in" />
          <feComposite in2="result1" in="SourceGraphic" />
        </filter>
        <filter
          filterUnits="userSpaceOnUse"
          height={23}
          width={244.045}
          y={233.5}
          x={-32.545}
          id="parsedSVG_svg__g"
        >
          <feOffset />
          <feGaussianBlur result="blur-5" stdDeviation={3.5} />
          <feFlood floodOpacity={0.502} floodColor="#37c1ff" />
          <feComposite result="result1" in2="blur-5" operator="in" />
          <feComposite in2="result1" in="SourceGraphic" />
        </filter>
        <filter
          filterUnits="userSpaceOnUse"
          height={23}
          width={244.045}
          y={233.5}
          x={734.455}
          id="parsedSVG_svg__i"
        >
          <feOffset />
          <feGaussianBlur result="blur-6" stdDeviation={3.5} />
          <feFlood floodColor="#ea4614" />
          <feComposite result="result1" in2="blur-6" operator="in" />
          <feComposite in2="result1" in="SourceGraphic" />
        </filter>
        <filter
          filterUnits="userSpaceOnUse"
          height={153}
          width={23}
          y={32.5}
          x={233.5}
          id="parsedSVG_svg__k"
        >
          <feOffset />
          <feGaussianBlur result="blur-7" stdDeviation={3.5} />
          <feFlood floodOpacity={0.502} floodColor="#72b146" />
          <feComposite result="result1" in2="blur-7" operator="in" />
          <feComposite in2="result1" in="SourceGraphic" />
        </filter>
        <filter
          filterUnits="userSpaceOnUse"
          height={23}
          width={209}
          y={32.5}
          x={47.5}
          id="parsedSVG_svg__m"
        >
          <feOffset />
          <feGaussianBlur result="blur-8" stdDeviation={3.5} />
          <feFlood floodOpacity={0.51} floodColor="#59b64a" />
          <feComposite result="result1" in2="blur-8" operator="in" />
          <feComposite in2="result1" in="SourceGraphic" />
        </filter>
        <filter
          filterUnits="userSpaceOnUse"
          height={23}
          width={318}
          y={33.5}
          x={581.5}
          id="parsedSVG_svg__o"
        >
          <feOffset />
          <feGaussianBlur result="blur-9" stdDeviation={3.5} />
          <feFlood floodColor="#ee9937" />
          <feComposite result="result1" in2="blur-9" operator="in" />
          <feComposite in2="result1" in="SourceGraphic" />
        </filter>
        <filter
          filterUnits="userSpaceOnUse"
          height={151}
          width={23}
          y={34.5}
          x={581.5}
          id="parsedSVG_svg__q"
        >
          <feOffset />
          <feGaussianBlur result="blur-10" stdDeviation={3.5} />
          <feFlood floodOpacity={0.502} floodColor="#d49f3b" />
          <feComposite result="result1" in2="blur-10" operator="in" />
          <feComposite in2="result1" in="SourceGraphic" />
        </filter>
        <filter
          filterUnits="userSpaceOnUse"
          height={165}
          width={23}
          y={299.5}
          x={233.5}
          id="parsedSVG_svg__r"
        >
          <feOffset />
          <feGaussianBlur result="blur-11" stdDeviation={3.5} />
          <feFlood floodOpacity={0.51} floodColor="#8dab43" />
          <feComposite result="result1" in2="blur-11" operator="in" />
          <feComposite in2="result1" in="SourceGraphic" />
        </filter>
        <filter
          filterUnits="userSpaceOnUse"
          height={163}
          width={23}
          y={299.5}
          x={581.5}
          id="parsedSVG_svg__s"
        >
          <feOffset />
          <feGaussianBlur result="blur-12" stdDeviation={3.5} />
          <feFlood floodOpacity={0.502} floodColor="#bca33d" />
          <feComposite result="result1" in2="blur-12" operator="in" />
          <feComposite in2="result1" in="SourceGraphic" />
        </filter>
        <filter
          filterUnits="userSpaceOnUse"
          height={23}
          width={371}
          y={441.5}
          x={233.5}
          id="parsedSVG_svg__t"
        >
          <feOffset />
          <feGaussianBlur result="blur-13" stdDeviation={3.5} />
          <feFlood floodOpacity={0.502} floodColor="#aaa640" />
          <feComposite result="result1" in2="blur-13" operator="in" />
          <feComposite in2="result1" in="SourceGraphic" />
        </filter>
        <linearGradient
          gradientUnits="objectBoundingBox"
          y2={0.5}
          x2={1}
          y1={0.5}
          id="parsedSVG_svg__f"
        >
          <stop stopColor="#777a88" offset={0} />
          <stop stopColor="#9b6a64" offset={1} />
        </linearGradient>
        <linearGradient
          gradientUnits="objectBoundingBox"
          y2={0.5}
          x2={1}
          y1={0.5}
          x1={0.325}
          id="parsedSVG_svg__h"
        >
          <stop stopOpacity={0} stopColor="#00b0ff" offset={0} />
          <stop stopColor="#01affd" offset={0.36} />
          <stop stopColor="#2a9dd5" offset={1} />
        </linearGradient>
        <linearGradient
          gradientUnits="objectBoundingBox"
          y2={0.5}
          x2={1}
          y1={0.5}
          x1={0.325}
          id="parsedSVG_svg__j"
        >
          <stop stopOpacity={0} stopColor="#eb4513" offset={0} />
          <stop stopColor="#eb4614" offset={0.36} />
          <stop stopColor="#ea4614" offset={1} />
        </linearGradient>
        <linearGradient
          gradientUnits="objectBoundingBox"
          y2={1}
          x2={0.5}
          x1={0.5}
          id="parsedSVG_svg__l"
        >
          <stop stopColor="#59b64a" offset={0} />
          <stop stopColor="#8dab43" offset={1} />
        </linearGradient>
        <linearGradient
          gradientUnits="objectBoundingBox"
          y2={1}
          x2={-0.015}
          y1={1}
          x1={0.125}
          id="parsedSVG_svg__n"
        >
          <stop stopColor="#59b64a" offset={0} />
          <stop stopOpacity={0} stopColor="#59b64a" offset={1} />
        </linearGradient>
        <linearGradient
          gradientUnits="objectBoundingBox"
          y2={0.5}
          x2={0.006}
          y1={0.5}
          x1={1}
          id="parsedSVG_svg__p"
        >
          <stop stopColor="#d49f3b" offset={0} />
          <stop stopColor="#ee9937" offset={0.827} />
          <stop stopOpacity={0} stopColor="#ee9937" offset={1} />
        </linearGradient>
        <linearGradient
          gradientUnits="objectBoundingBox"
          y2={0.5}
          x2={0.986}
          y1={0.5}
          x1={0.03}
          id="parsedSVG_svg__u"
        >
          <stop stopColor="#8dab44" offset={0} />
          <stop stopColor="#bca33d" offset={1} />
        </linearGradient>
        <clipPath id="parsedSVG_svg__a">
          <path d="M0 0h960v775H0z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#parsedSVG_svg__a)"
        data-name="Web 1920 \xE2\u20AC\u201C 63"
        className="parsedSVG_svg__group_busy"
      >
        <path
          className="parsedSVG_svg__rect191"
          fill="#fff"
          d="M0 0h960v775H0z"
        />
        <path
          className="parsedSVG_svg__background"
          fill="#f7fafc"
          d="M0 0h960v775H0z"
        />
        <g filter="url(#parsedSVG_svg__b)">
          <path
            data-name="Rectangle 47"
            fill="#fff"
            d="M547.19 185.31h187.558v128.598H547.19z"
          />
        </g>
        <path
          data-name="Rectangle 46"
          fill="#fff"
          d="M557.406 175.096h187.558v128.598H557.406z"
        />
        <path
          d="M557.405 175.096l-10.211 10.271v128.5l10.286-10.285z"
          data-name="Path 81"
          fill="#d4d4d4"
        />
        <path
          d="M547.192 313.87l10.26-10.26h187.472l-10.3 10.3z"
          data-name="Path 80"
          fill="#ebebeb"
        />
        <g filter="url(#parsedSVG_svg__c)">
          <path
            data-name="Rectangle 47"
            fill="#fff"
            d="M198.04 185.31h187.558v128.598H198.04z"
          />
        </g>
        <path
          data-name="Rectangle 46"
          fill="#fff"
          d="M208.249 175.096h187.558v128.598H208.249z"
        />
        <path
          d="M208.248 175.096l-10.211 10.271v128.5l10.286-10.285z"
          data-name="Path 81"
          fill="#d4d4d4"
        />
        <path
          d="M198.035 313.87l10.26-10.26h187.472l-10.3 10.3z"
          data-name="Path 80"
          fill="#ebebeb"
        />
        <g data-name="Path 168" fill="#efefef">
          <path d="M167 166.986H63c-2.481 0-4.5-1.761-4.5-3.927V98.427c0-2.165 2.019-3.927 4.5-3.927h104c2.481 0 4.5 1.762 4.5 3.927v64.632c0 2.166-2.019 3.927-4.5 3.927z" />
          <path
            d="M63 95c-2.206 0-4 1.537-4 3.427v64.632c0 1.89 1.794 3.427 4 3.427h104c2.206 0 4-1.537 4-3.427V98.427c0-1.89-1.794-3.427-4-3.427H63m0-1h104c2.761 0 5 1.982 5 4.427v64.632c0 2.445-2.239 4.427-5 4.427H63c-2.761 0-5-1.982-5-4.427V98.427C58 95.982 60.239 94 63 94z"
            fill="#dcdcdc"
          />
        </g>
        <path data-name="Rectangle 203" fill="#efefef" d="M64 94h88v9H64z" />
        <text
          fontWeight={700}
          fontSize={12.5}
          transform="translate(73 86)"
          fontFamily="Roboto-Bold,Roboto"
          fill="#707070"
        >
          <tspan y={2} x={0}>
            {" Heat exchanger "}
          </tspan>
        </text>
        <text
          fontWeight={700}
          fontSize={12.5}
          transform="translate(73 86)"
          fontFamily="Roboto-Bold,Roboto"
          fill="#707070"
        >
          <tspan y={16} x={0}>
            {" dimensions "}
          </tspan>
        </text>
        <text
          fontWeight={700}
          fontSize={12.5}
          transform="translate(285 279)"
          data-name="Stage 1"
          fontFamily="Roboto-Bold,Roboto"
          fill="#707070"
        >
          <tspan y={12} x={59.214}>
            {" Stage 1 "}
          </tspan>
        </text>
        <text
          fontWeight={700}
          fontSize={12.5}
          transform="translate(635 279)"
          data-name="Stage 2"
          fontFamily="Roboto-Bold,Roboto"
          fill="#707070"
        >
          <tspan y={12} x={59.214}>
            {" Stage 2 "}
          </tspan>
        </text>
        <text
          style={{
            textAlign: "end",
          }}
          y={121}
          x={239.661}
          fontWeight={500}
          fontSize={11}
          className="parsedSVG_svg__sim_txt"
          fontFamily="Roboto-Medium,Roboto"
          textAnchor="end"
          fill="#707070"
        >
          <tspan
            style={{
              textAlign: "end",
            }}
            y={121}
            x={159.728}
          >
            {heatExchangerDimensions.depth}
          </tspan>
        </text>

        <text
          fontWeight={300}
          fontSize={11}
          transform="translate(73 111)"
          fontFamily="Roboto-Light,Roboto"
          fill="#707070"
        >
          <tspan y={10} x={0}>
            {" Depth: "}
          </tspan>
        </text>
        <text
          y={137}
          x={233.216}
          fontWeight={500}
          fontSize={11}
          className="parsedSVG_svg__sim_txt"
          fontFamily="Roboto-Medium,Roboto"
          fill="#707070"
        >
          <tspan
            style={{
              textAlign: "end",
            }}
            y={137}
            x={159.605}
            textAnchor="end"
          >
            {heatExchangerDimensions.length}
          </tspan>
        </text>
        <text
          fontWeight={300}
          fontSize={11}
          transform="translate(73 127)"
          fontFamily="Roboto-Light,Roboto"
          fill="#707070"
        >
          <tspan y={10} x={0}>
            {" Length: "}
          </tspan>
        </text>
        <text
          y={153}
          x={252.535}
          fontWeight={500}
          fontSize={11}
          className="parsedSVG_svg__sim_txt"
          fontFamily="Roboto-Medium,Roboto"
          fill="#707070"
        >
          <tspan
            style={{
              textAlign: "end",
            }}
            y={153}
            x={159.84}
            textAnchor="end"
          >
            {heatExchangerDimensions.height}
          </tspan>
        </text>
        <text
          fontWeight={300}
          fontSize={11}
          transform="translate(73 143)"
          fontFamily="Roboto-Light,Roboto"
          fill="#707070"
        >
          <tspan y={10} x={0}>
            {" Height: "}
          </tspan>
        </text>
        <path
          d="M198.494 185.03l10.419-10.257"
          data-name="Path 163"
          opacity={0.45}
          fill="none"
          stroke="#707070"
          strokeDasharray="3,2"
        />
        <path
          data-name="Line 156"
          opacity={0.45}
          fill="none"
          stroke="#707070"
          strokeDasharray="3,2"
          d="M198.725 184.799v128.288"
        />
        <path
          d="M208.416 175.109H744.5"
          data-name="Path 167"
          opacity={0.45}
          fill="none"
          stroke="#707070"
          strokeDasharray="3,2"
        />
        <path
          d="M162.662 133.805h42.337v47.693"
          data-name="Path 164"
          opacity={0.45}
          fill="none"
          stroke="#707070"
          strokeDasharray="3,2"
        />
        <path
          d="M162.972 150.158h31.032V195.5h5"
          data-name="Path 165"
          opacity={0.45}
          fill="none"
          stroke="#707070"
          strokeDasharray="3,2"
        />
        <path
          d="M213.5 175.492V117.62h-50.336"
          data-name="Path 166"
          opacity={0.45}
          fill="none"
          stroke="#707070"
          strokeDasharray="3,2"
        />
        <g filter="url(#parsedSVG_svg__d)">
          <g data-name="Path 219" fill="#fff">
            <path d="M702.803 745.5H242.5V536.62h460.303z" />
            <path
              d="M243 537.12V745h459.303V537.12H243m-1-1h461.303V746H242z"
              fill="#e9e9e9"
            />
          </g>
        </g>
        <text
          fontWeight={700}
          fontSize={16}
          transform="translate(266.997 555.119)"
          fontFamily="Roboto-Bold,Roboto"
          fill="#707070"
        >
          <tspan y={15} x={0}>
            {" Summary "}
          </tspan>
        </text>
        <text
          fontWeight={300}
          fontSize={12.5}
          transform="translate(592 596)"
          data-name="Stage 2"
          fontFamily="Roboto-Light,Roboto"
          fill="#747474"
        >
          <tspan y={0} x={-41.473}>
            {" Stage 2 "}
          </tspan>
        </text>
        <text
          fontWeight={300}
          fontSize={12.5}
          transform="translate(671 596)"
          fontFamily="Roboto-Light,Roboto"
          fill="#747474"
        >
          <tspan y={0} x={-27.374}>
            {" Total "}
          </tspan>
        </text>
        <path
          fill="rgba(226,226,226,0.57)"
          data-name="Rectangle 274"
          d="M267 622h404v1H267z"
        />
        <path
          fill="rgba(226,226,226,0.57)"
          data-name="Rectangle 275"
          d="M267 646h404v1H267z"
        />
        <path
          fill="rgba(226,226,226,0.57)"
          data-name="Rectangle 277"
          d="M267 694h404v1H267z"
        />
        <path
          fill="rgba(226,226,226,0.57)"
          data-name="Rectangle 276"
          d="M267 670h404v1H267z"
        />
        <text
          fontWeight={300}
          fontSize={12.5}
          transform="translate(499 596)"
          data-name="Stage 1"
          fontFamily="Roboto-Light,Roboto"
          fill="#747474"
        >
          <tspan y={0} x={-41.473}>
            {" Stage 1 "}
          </tspan>
        </text>
        <g className="parsedSVG_svg__group-sum-extract">
          <text
            style={{
              textAlign: "end",
            }}
            y={628}
            x={676.792}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#747474"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={640}
              x={669.04}
            >
              {parseDisplayValue(
                simResult["sum_extract_eff_total"],
                "sum_extract_eff_total"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={628}
            x={597.792}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#747474"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={640}
              x={590.04}
            >
              {parseDisplayValue(
                simResult["sum_extract_eff_stage2"],
                "sum_extract_eff_stage2"
              )}
            </tspan>
          </text>
          <text
            fontWeight={300}
            fontSize={12.5}
            transform="translate(267 628)"
            fontFamily="Roboto-Light,Roboto"
            fill="#747474"
          >
            <tspan y={12} x={0}>
              {" Efficiency Extract "}
            </tspan>
          </text>
          <text
            fontWeight={300}
            fontSize={12.5}
            transform="translate(267 676)"
            data-name="Pressure Drop Extract"
            fontFamily="Roboto-Light,Roboto"
            fill="#747474"
          >
            <tspan y={12} x={0}>
              {" Pressure Drop Extract "}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={628}
            x={504.792}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#747474"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={640}
              x={497.04}
            >
              {parseDisplayValue(
                simResult["sum_extract_eff_stage1"],
                "sum_extract_eff_stage1"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={676}
            x={508.246}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#747474"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={688}
              x={496.421}
            >
              {parseDisplayValue(
                simResult["sum_extract_pd_stage1"],
                "sum_extract_pd_stage1"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={676}
            x={601.246}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#747474"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={688}
              x={589.421}
            >
              {parseDisplayValue(
                simResult["sum_extract_pd_stage2"],
                "sum_extract_pd_stage2"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={676}
            x={680.246}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#747474"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={688}
              x={668.421}
            >
              {parseDisplayValue(
                simResult["sum_extract_pd_total"],
                "sum_extract_pd_total"
              )}
            </tspan>
          </text>
        </g>
        <g className="parsedSVG_svg__group-sum-outside">
          <text
            style={{
              textAlign: "end",
            }}
            y={604}
            x={677.22}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#747474"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={616}
              x={669.468}
            >
              {parseDisplayValue(
                simResult["sum_outside_eff_total"],
                "sum_outside_eff_total"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={604}
            x={598.22}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#747474"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={616}
              x={590.468}
            >
              {parseDisplayValue(
                simResult["sum_outside_eff_stage2"],
                "sum_outside_eff_stage2"
              )}
            </tspan>
          </text>
          <text
            fontWeight={300}
            fontSize={12.5}
            transform="translate(267 604)"
            data-name="Efficiency Outside"
            fontFamily="Roboto-Light,Roboto"
            fill="#747474"
          >
            <tspan y={12} x={0}>
              {" Efficiency Outside "}
            </tspan>
          </text>
          <text
            fontWeight={300}
            fontSize={12.5}
            transform="translate(267 652)"
            data-name="Pressure Drop Outside"
            fontFamily="Roboto-Light,Roboto"
            fill="#747474"
          >
            <tspan y={12} x={0}>
              {" Pressure Drop Outside "}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={604}
            x={505.22}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#747474"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={616}
              x={497.468}
            >
              {parseDisplayValue(
                simResult["sum_outside_eff_stage1"],
                "sum_outside_eff_stage1"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={652}
            x={508.032}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#747474"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={664}
              x={496.206}
            >
              {parseDisplayValue(
                simResult["sum_outside_pd_stage1"],
                "sum_outside_pd_stage1"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={652}
            x={601.032}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#747474"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={664}
              x={589.206}
            >
              {parseDisplayValue(
                simResult["sum_outside_pd_stage2"],
                "sum_outside_pd_stage2"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={652}
            x={680.032}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#747474"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={664}
              x={668.206}
            >
              {parseDisplayValue(
                simResult["sum_outside_pd_total"],
                "sum_outside_pd_total"
              )}
            </tspan>
          </text>
        </g>
        <g className="parsedSVG_svg__group-sum-power">
          <text
            fontWeight={300}
            fontSize={12.5}
            transform="translate(267 700)"
            fontFamily="Roboto-Light,Roboto"
            fill="#747474"
          >
            <tspan y={12} x={0}>
              {" Power "}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={700}
            x={514.794}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#747474"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={712}
              x={496.377}
            >
              {parseDisplayValue(
                simResult["sum_power_stage1"],
                "sum_power_stage1"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={700}
            x={607.794}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#747474"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={712}
              x={589.377}
            >
              {parseDisplayValue(
                simResult["sum_power_stage2"],
                "sum_power_stage2"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={700}
            x={724.373}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#747474"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={712}
              x={668.377}
            >
              {parseDisplayValue(
                simResult["sum_power_total"],
                "sum_power_total"
              )}
              <tspan
                style={{
                  textAlign: "end",
                }}
              ></tspan>
            </tspan>
          </text>
        </g>
        <g
          className="parsedSVG_svg__group-outside"
          style={{
            opacity: hoverInside ? 0.25 : 1,
            transition: "opacity 0.3s ease",
          }}
          onMouseEnter={() => {
            sethoverOutside(true);
          }}
          onMouseLeave={() => {
            sethoverOutside(false);
          }}
        >
          <g filter="url(#parsedSVG_svg__e)">
            <path
              transform="translate(395.81 244)"
              d="M0 0h156.193v2H0z"
              data-name="Path 128"
              fill="url(#parsedSVG_svg__f)"
            />
          </g>
          <g filter="url(#parsedSVG_svg__g)">
            <path
              transform="translate(-22.04 244)"
              d="M0 0h223.045v2H0z"
              data-name="Path 125"
              fill="url(#parsedSVG_svg__h)"
            />
          </g>
          <g filter="url(#parsedSVG_svg__i)">
            <path
              transform="rotate(180 484 123)"
              d="M0 0h223.045v2H0z"
              data-name="Path 135"
              fill="url(#parsedSVG_svg__j)"
            />
          </g>
          <path
            d="M130.025 239.5l5 5.5-5 5.5"
            data-name="Path 130"
            fill="none"
            stroke="#11a8ee"
            strokeWidth={2}
          />
          <path
            d="M471.5 239.5l5 5.5-5 5.5"
            data-name="Path 129"
            fill="none"
            stroke="#8a7275"
            strokeWidth={2}
          />
          <path
            d="M855.043 239.5l5 5.5-5 5.5"
            data-name="Path 136"
            fill="none"
            stroke="#ed7249"
            strokeWidth={2}
          />
          <path
            opacity={0.1}
            fill="none"
            stroke="#289dd6"
            strokeWidth={2}
            strokeDasharray="8,4"
            d="M201 245h195"
          />
          <path
            opacity={0.1}
            fill="none"
            stroke="#986c68"
            strokeWidth={2}
            strokeDasharray="8,4"
            d="M552 245h193"
          />
          <g transform="translate(68 181.9)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <text
            style={{
              textAlign: "end",
            }}
            y={187.899}
            x={198.096}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={199.899}
              x={156.541}
            >
              {parseDisplayValue(simResult["outside_w_val"], "outside_w_val")}
            </tspan>
          </text>
          <g
            transform="matrix(1 0 0 .90177 58 267.028)"
            data-name="Rectangle 155"
            fill="none"
            stroke="#16a6ea"
          >
            <rect rx={5} height={83} width={114} stroke="none" />
            <rect rx={4.5} height={82} width={113} y={0.5} x={0.5} />
          </g>
          <g
            transform="matrix(1 0 0 .94961 416 266)"
            data-name="Rectangle 163"
            fill="#f5f5f5"
            stroke="#916e6e"
          >
            <rect rx={5} height={83} width={114} stroke="none" />
            <rect
              rx={4.501}
              height={77.354}
              width={113.02}
              y={1.898}
              x={0.49}
              fill="none"
              strokeWidth={0.971}
            />
          </g>
          <g
            transform="matrix(1 0 0 .91534 772 266)"
            data-name="Rectangle 170"
            fill="none"
            stroke="#eb4614"
          >
            <rect rx={5} height={83} width={114} stroke="none" />
            <rect
              rx={4.5}
              height={80.337}
              width={113.002}
              y={1.786}
              x={0.499}
              strokeWidth={0.99}
            />
          </g>
          <g transform="translate(64 274)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <g transform="translate(422 274)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <g transform="translate(778 274)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <g transform="translate(64 307)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <g transform="translate(422 307)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <g transform="translate(778 307)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <text
            style={{
              textAlign: "end",
            }}
            y={281}
            x={181.053}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={293}
              x={151.687}
            >
              {parseDisplayValue(
                simResult["outside_temp_val"],
                "outside_temp_val"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={281}
            x={539.454}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={293}
              x={510.087}
            >
              {parseDisplayValue(
                simResult["intsup1_temp_val"],
                "intsup1_temp_val"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={281}
            x={896.311}
            fontWeight={500}
            fontSize={12.5}
            data-name="#supply-temp-val"
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={293}
              x={866.944}
            >
              {parseDisplayValue(
                simResult["supply_temp_val"],
                "supply_temp_val"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={313}
            x={140.223}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={325}
              x={152.068}
            >
              {parseDisplayValue(
                simResult["outside_hum_val"],
                "outside_hum_val"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={313}
            x={498.785}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={325}
              x={510.629}
            >
              {parseDisplayValue(
                simResult["intsup1_hum_val"],
                "intsup1_hum_val"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={313}
            x={856.499}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={325}
              x={868.344}
            >
              {parseDisplayValue(simResult["supply_hum_val"], "supply_hum_val")}
            </tspan>
          </text>
          <g
            transform="translate(437 279)"
            data-name="Rectangle 166"
            fill="#fff"
            stroke="#707070"
          >
            <rect rx={3} height={12} width={6} stroke="none" />
            <rect rx={2.5} height={11} width={5} y={0.5} x={0.5} fill="none" />
          </g>
          <g
            transform="translate(793 279)"
            data-name="Rectangle 173"
            fill="#fff"
            stroke="#707070"
          >
            <rect rx={3} height={12} width={6} stroke="none" />
            <rect rx={2.5} height={11} width={5} y={0.5} x={0.5} fill="none" />
          </g>
          <g
            transform="translate(435 287)"
            data-name="Ellipse 16"
            fill="#fff"
            stroke="#707070"
          >
            <circle r={5} cy={5} cx={5} stroke="none" />
            <circle r={4.5} cy={5} cx={5} fill="none" />
          </g>
          <g
            transform="translate(791 287)"
            data-name="Ellipse 18"
            fill="#fff"
            stroke="#707070"
          >
            <circle r={5} cy={5} cx={5} stroke="none" />
            <circle r={4.5} cy={5} cx={5} fill="none" />
          </g>
          <path data-name="Rectangle 167" fill="#fff" d="M438 284h4v8h-4z" />
          <path data-name="Rectangle 174" fill="#fff" d="M794 284h4v8h-4z" />
          <circle
            r={3}
            cy={292}
            cx={440}
            data-name="Ellipse 17"
            fill="#707070"
          />
          <circle
            r={3}
            cy={292}
            cx={796}
            data-name="Ellipse 19"
            fill="#707070"
          />
          <g
            transform="translate(79 279)"
            data-name="Rectangle 160"
            fill="#fff"
            stroke="#707070"
          >
            <rect rx={3} height={12} width={6} stroke="none" />
            <rect rx={2.5} height={11} width={5} y={0.5} x={0.5} fill="none" />
          </g>
          <g
            transform="translate(77 287)"
            data-name="Ellipse 14"
            fill="#fff"
            stroke="#707070"
          >
            <circle r={5} cy={5} cx={5} stroke="none" />
            <circle r={4.5} cy={5} cx={5} fill="none" />
          </g>
          <path data-name="Rectangle 161" fill="#fff" d="M80 284h4v8h-4z" />
          <circle
            r={3}
            cy={292}
            cx={82}
            data-name="Ellipse 15"
            fill="#707070"
          />
          <g
            transform="translate(81 284)"
            data-name="Rectangle 162"
            fill="#707070"
            stroke="#707070"
          >
            <rect rx={1} height={7} width={2} stroke="none" />
            <rect rx={0.5} height={6} width={1} y={0.5} x={0.5} />
          </g>
          <g
            transform="translate(439 284)"
            data-name="Rectangle 168"
            fill="#707070"
            stroke="#707070"
          >
            <rect rx={1} height={7} width={2} stroke="none" />
            <rect rx={0.5} height={6} width={1} y={0.5} x={0.5} />
          </g>
          <g
            transform="translate(795 284)"
            data-name="Rectangle 176"
            fill="#707070"
            stroke="#707070"
          >
            <rect rx={1} height={7} width={2} stroke="none" />
            <rect rx={0.5} height={6} width={1} y={0.5} x={0.5} />
          </g>
          <path
            d="M82 317.5c1.829 2.718 3 4.36 3 6a3 3 0 01-6 0c0-1.64 1.17-3.281 3-6zm0-2c-2.435 3.6-4 5.812-4 8a4 4 0 108 0c0-2.188-1.565-4.4-4-8z"
            fill="#707070"
          />
          <path
            d="M440 317.5c1.829 2.718 3 4.36 3 6a3 3 0 01-6 0c0-1.64 1.17-3.281 3-6zm0-2c-2.435 3.6-4 5.812-4 8a4 4 0 108 0c0-2.188-1.565-4.4-4-8zM796 317.5c1.829 2.718 3 4.36 3 6a3 3 0 01-6 0c0-1.64 1.17-3.281 3-6zm0-2c-2.435 3.6-4 5.812-4 8a4 4 0 108 0c0-2.188-1.565-4.4-4-8z"
            data-name="iconmonstr-drop-2"
            fill="#707070"
          />
          <path
            d="M81.979 318l-2.268 3.484-.62 2.4 1.687 2.414 3 .08 1.105-2.781-1.105-3z"
            data-name="Path 146"
            fill="#707070"
            stroke="#707070"
          />
          <path
            d="M439.979 318l-2.268 3.484-.62 2.4 1.687 2.414 3 .08 1.105-2.781-1.105-3z"
            data-name="Path 148"
            fill="#707070"
            stroke="#707070"
          />
          <path
            d="M795.979 318l-2.268 3.484-.62 2.4 1.687 2.414 3 .08 1.105-2.781-1.105-3z"
            data-name="Path 150"
            fill="#707070"
            stroke="#707070"
          />
          <path
            d="M185 245.998v252.615h573V245.998"
            data-name="Path 143"
            fill="none"
            stroke="#707070"
          />
          <path
            d="M157.504 267.497v-21.5"
            data-name="Path 147"
            fill="none"
            stroke="#13a6ea"
            strokeWidth={1.037}
          />
          <path
            d="M144.537 243.999v-34.13"
            data-name="Path 155"
            fill="none"
            stroke="#0ca6ea"
            strokeWidth={1.62}
          />
          <path
            d="M509.494 267.72v-21.723"
            data-name="Path 149"
            fill="none"
            stroke="#916e6e"
            strokeWidth={1.042}
          />
          <g transform="translate(422 483.615)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={97} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={96}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <text
            style={{
              textAlign: "end",
            }}
            y={489.615}
            x={525.426}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={501.615}
              x={505.904}
            >
              {parseDisplayValue(simResult["outside_pd_val"], "outside_pd_val")}
            </tspan>
          </text>
          <g
            transform="translate(434 490)"
            data-name="Ellipse 58"
            fill="none"
            stroke="#707070"
          >
            <circle r={6} cy={6} cx={6} stroke="none" />
            <circle r={5.5} cy={6} cx={6} />
          </g>
          <g data-name="Rectangle 215" fill="#fff" stroke="#707070">
            <path stroke="none" d="M434 504h12v2h-12z" />
            <path fill="none" d="M434.5 504.5h11v1h-11z" />
          </g>
          <path
            d="M440 496l2.868-2.867"
            data-name="Path 175"
            fill="none"
            stroke="#707070"
          />
          <path
            d="M451.461 491.873v12.754"
            data-name="Path 176"
            fill="none"
            stroke="#707070"
          />
          <path
            d="M449.039 490.5h5"
            data-name="Path 177"
            fill="none"
            stroke="#707070"
          />
          <path
            d="M449.039 505.5h5"
            data-name="Path 178"
            fill="none"
            stroke="#707070"
          />
          <path
            d="M448.858 502.4l2.6 2.6 2.6-2.6"
            data-name="Path 179"
            fill="none"
            stroke="#707070"
          />
          <path
            d="M454.062 493.998l-2.6-2.6-2.6 2.6"
            data-name="Path 180"
            fill="none"
            stroke="#707070"
          />
          <path
            d="M440 504.125v-2.895"
            data-name="Path 181"
            fill="none"
            stroke="#707070"
          />
          <path
            strokeMiterlimit={10}
            d="M83.62 191.898a1.88 1.88 0 111.88 1.88h-7.65"
            data-name="Path 190"
            fill="none"
            stroke="#6a6a6a"
          />
          <path
            strokeMiterlimit={10}
            d="M88.065 200.414a1.485 1.485 0 101.485-1.485l-9.147.02"
            data-name="Path 191"
            fill="none"
            stroke="#6a6a6a"
          />
          <path
            strokeMiterlimit={10}
            d="M89.3 194.45a1.88 1.88 0 111.88 1.88H77"
            data-name="Path 192"
            fill="none"
            stroke="#6a6a6a"
          />
          <path
            d="M789.976 267.566v-21.569"
            data-name="Path 151"
            fill="none"
            stroke="#ea4614"
            strokeWidth={1.038}
          />
          <text
            x={71.207}
            y={238.891}
            fontWeight={700}
            fontSize={12.5}
            fontFamily="Roboto-Medium,Roboto"
            fill="#06b0fc"
          >
            <tspan x={71.207} y={238.891}>
              {" Outside "}
            </tspan>
          </text>
          <text
            x={800.188}
            y={238.538}
            fontWeight={700}
            fontSize={12.5}
            fontFamily="Roboto-Medium,Roboto"
            fill="#ea440c"
          >
            <tspan x={800.188} y={238.538}>
              {" Supply "}
            </tspan>
          </text>
        </g>
        <g
          className="parsedSVG_svg__group-extract"
          style={{
            opacity: hoverOutside ? 0.25 : 1,
            transition: "opacity 0.3s ease",
          }}
          onMouseEnter={() => {
            sethoverInside(true);
          }}
          onMouseLeave={() => {
            sethoverInside(false);
          }}
        >
          <path
            d="M476.499 458.5l-5-5.5 5-5.5"
            data-name="Path 204"
            fill="none"
            stroke="#aca640"
            strokeWidth={2}
          />
          <g filter="url(#parsedSVG_svg__k)">
            <path
              transform="translate(244 43)"
              data-name="Rectangle 123"
              fill="url(#parsedSVG_svg__l)"
              d="M0 0h2v132H0z"
            />
          </g>
          <g filter="url(#parsedSVG_svg__m)">
            <path
              transform="translate(58 43)"
              data-name="Rectangle 129"
              fill="url(#parsedSVG_svg__n)"
              d="M0 0h188v2H0z"
            />
          </g>
          <g filter="url(#parsedSVG_svg__o)">
            <path
              transform="rotate(180 444.5 23)"
              data-name="Rectangle 137"
              fill="url(#parsedSVG_svg__p)"
              d="M0 0h297v2H0z"
            />
          </g>
          <path
            d="M239.5 136.499l5.5-5 5.5 5"
            data-name="Path 132"
            fill="none"
            stroke="#72b146"
            strokeWidth={2}
          />
          <path
            d="M598.5 131.501l-5.5 5-5.5-5"
            data-name="Path 134"
            fill="none"
            stroke="#d49f3b"
            strokeWidth={2}
          />
          <g filter="url(#parsedSVG_svg__q)">
            <path
              data-name="Rectangle 136"
              fill="#d49f3b"
              d="M592 45h2v130h-2z"
            />
          </g>
          <g filter="url(#parsedSVG_svg__r)">
            <path
              data-name="Rectangle 139"
              fill="#8dab43"
              d="M244 310h2v144h-2z"
            />
          </g>
          <g filter="url(#parsedSVG_svg__s)">
            <path
              data-name="Rectangle 140"
              fill="#bca33d"
              d="M592 310h2v142h-2z"
            />
          </g>
          <g filter="url(#parsedSVG_svg__t)">
            <path
              transform="translate(244 452)"
              data-name="Rectangle 138"
              fill="url(#parsedSVG_svg__u)"
              d="M0 0h350v2H0z"
            />
          </g>
          <g transform="translate(359 439)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={95} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={94}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <g transform="translate(617 31)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={95} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={94}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <text
            style={{
              textAlign: "end",
            }}
            y={445}
            x={472.856}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={457}
              x={440.749}
            >
              {parseDisplayValue(
                simResult["intextract1_vap_val"],
                "intextract1_vap_val"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={37}
            x={731.917}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={49}
              x={699.809}
            >
              {parseDisplayValue(
                simResult["extract_vap_val"],
                "extract_vap_val"
              )}
            </tspan>
          </text>
          <g transform="translate(660 70)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <text
            style={{
              textAlign: "end",
            }}
            y={76}
            x={790.551}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={88}
              x={748.995}
            >
              {parseDisplayValue(simResult["extract_w_val"], "extract_w_val")}
            </tspan>
          </text>
          <g
            transform="matrix(1 0 0 .9028 261 77.942)"
            data-name="Rectangle 146"
            fill="none"
            stroke="#72b146"
          >
            <rect rx={4} height={83} width={114} stroke="none" />
            <rect rx={3.5} height={82} width={113} y={0.5} x={0.5} />
          </g>
          <g
            transform="matrix(1 0 0 .8991 454.394 353.137)"
            data-name="Rectangle 187"
            fill="none"
            stroke="#b0a43d"
          >
            <rect rx={4} height={83} width={114} />
            <rect rx={3.5} height={82} width={113} y={0.5} x={0.5} />
          </g>
          <path
            d="M261 101h-15"
            data-name="Path 141"
            fill="none"
            stroke="#72b146"
          />
          <path
            d="M500.894 427.002v25"
            data-name="Path 160"
            fill="none"
            stroke="#b3a33d"
            strokeWidth={0.806}
          />
          <g transform="translate(267 85)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <g transform="translate(460.394 360)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <g transform="translate(267 118)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <g transform="translate(460.394 393)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <text
            y={92}
            x={370.282}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={104}
              x={355.124}
              textAnchor="end"
            >
              {parseDisplayValue(
                simResult["exhaust_temp_val"],
                "exhaust_temp_val"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={367}
            x={577.588}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={379}
              x={548.221}
            >
              {parseDisplayValue(
                simResult["intextract1_temp_val"],
                "intextract1_temp_val"
              )}
            </tspan>
          </text>
          <text
            y={124}
            x={343.223}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={136}
              x={355.068}
              textAnchor="end"
            >
              {parseDisplayValue(
                simResult["exhaust_hum_val"],
                "exhaust_hum_val"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={399}
            x={536.643}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={411}
              x={548.487}
            >
              {parseDisplayValue(
                simResult["intextract1_hum_val"],
                "intextract1_hum_val"
              )}
            </tspan>
          </text>
          <g
            transform="translate(282 90)"
            data-name="Rectangle 151"
            fill="#fff"
            stroke="#707070"
          >
            <rect rx={3} height={12} width={6} stroke="none" />
            <rect rx={2.5} height={11} width={5} y={0.5} x={0.5} fill="none" />
          </g>
          <g
            transform="translate(475.394 365)"
            data-name="Rectangle 192"
            fill="#fff"
            stroke="#707070"
          >
            <rect rx={3} height={12} width={6} stroke="none" />
            <rect rx={2.5} height={11} width={5} y={0.5} x={0.5} fill="none" />
          </g>
          <g
            transform="translate(280 98)"
            data-name="Ellipse 12"
            fill="#fff"
            stroke="#707070"
          >
            <circle r={5} cy={5} cx={5} stroke="none" />
            <circle r={4.5} cy={5} cx={5} fill="none" />
          </g>
          <g
            transform="translate(473.394 373)"
            data-name="Ellipse 22"
            fill="#fff"
            stroke="#707070"
          >
            <circle r={5} cy={5} cx={5} stroke="none" />
            <circle r={4.5} cy={5} cx={5} fill="none" />
          </g>
          <path data-name="Rectangle 152" fill="#fff" d="M283 95h4v8h-4z" />
          <path
            data-name="Rectangle 193"
            fill="#fff"
            d="M476.394 370h4v8h-4z"
          />
          <circle
            r={3}
            cy={103}
            cx={285}
            data-name="Ellipse 13"
            fill="#707070"
          />
          <circle
            r={3}
            cy={378}
            cx={478.394}
            data-name="Ellipse 23"
            fill="#707070"
          />
          <g
            transform="translate(284 95)"
            data-name="Rectangle 153"
            fill="#707070"
            stroke="#707070"
          >
            <rect rx={1} height={7} width={2} stroke="none" />
            <rect rx={0.5} height={6} width={1} y={0.5} x={0.5} />
          </g>
          <path
            d="M285 128.5c1.829 2.718 3 4.36 3 6a3 3 0 01-6 0c0-1.64 1.17-3.281 3-6zm0-2c-2.435 3.6-4 5.812-4 8a4 4 0 108 0c0-2.188-1.565-4.4-4-8zM478.394 403.5c1.829 2.718 3 4.36 3 6a3 3 0 01-6 0c0-1.64 1.17-3.281 3-6zm0-2c-2.435 3.6-4 5.812-4 8a4 4 0 108 0c0-2.188-1.565-4.4-4-8z"
            data-name="iconmonstr-drop-2"
            fill="#707070"
          />
          <path
            d="M284.979 129l-2.268 3.484-.62 2.4 1.687 2.414 3 .08 1.105-2.781-1.105-3z"
            data-name="Path 140"
            fill="#707070"
            stroke="#707070"
          />
          <path
            d="M478.373 404l-2.268 3.484-.62 2.4 1.687 2.414 3 .08 1.105-2.781-1.105-3z"
            data-name="Path 159"
            fill="#707070"
            stroke="#707070"
          />
          <path
            d="M246 51.115h346"
            data-name="Path 152"
            fill="none"
            stroke="#707070"
          />
          <path
            d="M736.494 69.999v-24"
            data-name="Path 156"
            fill="none"
            stroke="#d99e3a"
          />
          <g
            transform="matrix(1 0 0 .9138 772 77)"
            data-name="Rectangle 179"
            fill="none"
            stroke="#eb9a38"
          >
            <rect rx={5} height={83} width={114} stroke="none" />
            <rect
              rx={4.5}
              height={80.81}
              width={113}
              y={1.69}
              x={0.5}
              strokeWidth={0.993}
            />
          </g>
          <g transform="translate(778 85)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <g transform="translate(778 118)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <text
            style={{
              textAlign: "end",
            }}
            y={92}
            x={896.265}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={104}
              x={866.899}
            >
              {parseDisplayValue(
                simResult["extract_temp_val"],
                "extract_temp_val"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={124}
            x={854.678}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={136}
              x={866.522}
            >
              {parseDisplayValue(
                simResult["extract_hum_val"],
                "extract_hum_val"
              )}
            </tspan>
          </text>
          <path
            opacity={0.1}
            fill="none"
            stroke="#72b146"
            strokeWidth={2}
            strokeDasharray="8,4"
            d="M245 310V175"
          />
          <path
            opacity={0.1}
            fill="none"
            stroke="#d49f3b"
            strokeWidth={2}
            strokeDasharray="8,4"
            d="M593 175v135"
          />
          <circle
            r={1.5}
            cy={447.5}
            cx={376.5}
            data-name="Ellipse 50"
            fill="#707070"
          />
          <circle
            r={1.5}
            cy={451.5}
            cx={376.5}
            data-name="Ellipse 51"
            fill="#707070"
          />
          <circle
            r={1.5}
            cy={454.5}
            cx={378.5}
            data-name="Ellipse 52"
            fill="#707070"
          />
          <circle
            r={1.5}
            cy={454.5}
            cx={374.5}
            data-name="Ellipse 53"
            fill="#707070"
          />
          <circle
            r={1.5}
            cy={457.5}
            cx={381.5}
            data-name="Ellipse 54"
            fill="#707070"
          />
          <circle
            r={1.5}
            cy={457.5}
            cx={371.5}
            data-name="Ellipse 55"
            fill="#707070"
          />
          <circle
            transform="translate(633 38)"
            r={1.5}
            cy={1.5}
            cx={1.5}
            data-name="Ellipse 50"
            fill="#707070"
          />
          <circle
            transform="translate(633 42)"
            r={1.5}
            cy={1.5}
            cx={1.5}
            data-name="Ellipse 51"
            fill="#707070"
          />
          <circle
            transform="translate(635 45)"
            r={1.5}
            cy={1.5}
            cx={1.5}
            data-name="Ellipse 52"
            fill="#707070"
          />
          <circle
            transform="translate(631 45)"
            r={1.5}
            cy={1.5}
            cx={1.5}
            data-name="Ellipse 53"
            fill="#707070"
          />
          <circle
            transform="translate(638 48)"
            r={1.5}
            cy={1.5}
            cx={1.5}
            data-name="Ellipse 54"
            fill="#707070"
          />
          <circle
            transform="translate(628 48)"
            r={1.5}
            cy={1.5}
            cx={1.5}
            data-name="Ellipse 55"
            fill="#707070"
          />
          <g transform="translate(422 37.615)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={97} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={96}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <text
            style={{
              textAlign: "end",
            }}
            y={43.615}
            x={524.971}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={55.615}
              x={505.45}
            >
              {parseDisplayValue(simResult["extract_pd_val"], "extract_pd_val")}
            </tspan>
          </text>
          <g
            transform="translate(434 44)"
            data-name="Ellipse 58"
            fill="#fff"
            stroke="#707070"
          >
            <circle r={6} cy={6} cx={6} stroke="none" />
            <circle r={5.5} cy={6} cx={6} fill="none" />
          </g>
          <g data-name="Rectangle 215" fill="#fff" stroke="#707070">
            <path stroke="none" d="M434 58h12v2h-12z" />
            <path fill="none" d="M434.5 58.5h11v1h-11z" />
          </g>
          <path
            d="M440 50l2.868-2.867"
            data-name="Path 175"
            fill="none"
            stroke="#707070"
          />
          <path
            d="M451.461 45.873v12.754"
            data-name="Path 176"
            fill="none"
            stroke="#707070"
          />
          <path
            d="M449.039 44.5h5"
            data-name="Path 177"
            fill="none"
            stroke="#707070"
          />
          <path
            d="M449.039 59.5h5"
            data-name="Path 178"
            fill="none"
            stroke="#707070"
          />
          <path
            d="M448.858 56.4l2.6 2.6 2.6-2.6"
            data-name="Path 179"
            fill="none"
            stroke="#707070"
          />
          <path
            d="M454.062 47.998l-2.6-2.6-2.6 2.6"
            data-name="Path 180"
            fill="none"
            stroke="#707070"
          />
          <path
            d="M440 58.125V55.23"
            data-name="Path 181"
            fill="none"
            stroke="#707070"
          />
          <g
            transform="translate(793 90)"
            data-name="Rectangle 184"
            fill="#fff"
            stroke="#707070"
          >
            <rect rx={3} height={12} width={6} stroke="none" />
            <rect rx={2.5} height={11} width={5} y={0.5} x={0.5} fill="none" />
          </g>
          <g
            transform="translate(791 98)"
            data-name="Ellipse 20"
            fill="#fff"
            stroke="#707070"
          >
            <circle r={5} cy={5} cx={5} stroke="none" />
            <circle r={4.5} cy={5} cx={5} fill="none" />
          </g>
          <path data-name="Rectangle 185" fill="#fff" d="M794 95h4v8h-4z" />
          <circle
            r={3}
            cy={103}
            cx={796}
            data-name="Ellipse 21"
            fill="#707070"
          />
          <g
            transform="translate(795 95)"
            data-name="Rectangle 186"
            fill="#707070"
            stroke="#707070"
          >
            <rect rx={1} height={7} width={2} stroke="none" />
            <rect rx={0.5} height={6} width={1} y={0.5} x={0.5} />
          </g>
          <path
            d="M796 128.5c1.829 2.718 3 4.36 3 6a3 3 0 01-6 0c0-1.64 1.17-3.281 3-6zm0-2c-2.435 3.6-4 5.812-4 8a4 4 0 108 0c0-2.188-1.565-4.4-4-8z"
            data-name="iconmonstr-drop-2"
            fill="#707070"
          />
          <path
            d="M795.979 129l-2.268 3.484-.62 2.4 1.687 2.414 3 .08 1.105-2.781-1.105-3z"
            data-name="Path 157"
            fill="#707070"
            stroke="#707070"
          />
          <path
            strokeMiterlimit={10}
            d="M675.62 79.999a1.88 1.88 0 111.88 1.88h-7.65"
            data-name="Path 190"
            fill="none"
            stroke="#6a6a6a"
          />
          <path
            strokeMiterlimit={10}
            d="M680.065 88.515a1.485 1.485 0 101.485-1.485l-9.147.02"
            data-name="Path 191"
            fill="none"
            stroke="#6a6a6a"
          />
          <path
            strokeMiterlimit={10}
            d="M681.3 82.551a1.88 1.88 0 111.88 1.88H669"
            data-name="Path 192"
            fill="none"
            stroke="#6a6a6a"
          />
          <path
            d="M847.494 78.374V45.999"
            data-name="Path 158"
            fill="none"
            stroke="#ec9a38"
            strokeWidth={1.022}
          />
          <g
            data-name="Rectangle 176"
            transform="translate(477.446 369.174)"
            fill="#707070"
            stroke="#707070"
          >
            <rect rx={1} height={7} width={2} stroke="none" />
            <rect rx={0.5} height={6} width={1} y={0.5} x={0.5} />
          </g>
          <g
            transform="matrix(1 0 0 .8991 265.44 353.137)"
            data-name="Rectangle 187"
            fill="none"
            stroke="#9ca841"
          >
            <rect rx={4} height={83} width={114} stroke="none" />
            <rect rx={3.5} height={82} width={113} y={0.5} x={0.5} />
          </g>
          <path
            d="M311.94 427.002v25"
            data-name="Path 160"
            fill="none"
            stroke="#9ca841"
            strokeWidth={0.806}
          />
          <g transform="translate(271.44 360)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <g transform="translate(271.44 393)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <text
            style={{
              textAlign: "end",
            }}
            y={367}
            x={388.634}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={379}
              x={359.268}
            >
              {parseDisplayValue(
                simResult["intextract1vapor_temp_val"],
                "intextract1vapor_temp_val"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={399}
            x={347.689}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={411}
              x={359.534}
            >
              {parseDisplayValue(
                simResult["intextract1vapor_hum_val"],
                "intextract1vapor_hum_val"
              )}
            </tspan>
          </text>
          <g
            transform="translate(286.44 365)"
            data-name="Rectangle 192"
            fill="#fff"
            stroke="#707070"
          >
            <rect rx={3} height={12} width={6} stroke="none" />
            <rect rx={2.5} height={11} width={5} y={0.5} x={0.5} fill="none" />
          </g>
          <g
            transform="translate(284.44 373)"
            data-name="Ellipse 22"
            fill="#fff"
            stroke="#707070"
          >
            <circle r={5} cy={5} cx={5} stroke="none" />
            <circle r={4.5} cy={5} cx={5} fill="none" />
          </g>
          <path data-name="Rectangle 193" fill="#fff" d="M287.44 370h4v8h-4z" />
          <circle
            r={3}
            cy={378}
            cx={289.44}
            data-name="Ellipse 23"
            fill="#707070"
          />
          <path
            d="M289.44 403.5c1.83 2.718 3 4.36 3 6a3 3 0 01-6 0c0-1.64 1.17-3.281 3-6zm0-2c-2.435 3.6-4 5.812-4 8a4 4 0 108 0c0-2.188-1.565-4.4-4-8z"
            data-name="iconmonstr-drop-2"
            fill="#707070"
          />
          <path
            d="M289.42 404l-2.269 3.484-.62 2.4 1.687 2.414 3 .08 1.105-2.781-1.105-3z"
            data-name="Path 159"
            fill="#707070"
            stroke="#707070"
          />
          <g
            data-name="Rectangle 176"
            transform="translate(288.493 369.174)"
            fill="#707070"
            stroke="#707070"
          >
            <rect rx={1} height={7} width={2} stroke="none" />
            <rect rx={0.5} height={6} width={1} y={0.5} x={0.5} />
          </g>
          <g
            transform="matrix(1 0 0 .9138 452.182 76.431)"
            data-name="Rectangle 179"
            fill="none"
            stroke="#eb9a38"
          >
            <rect rx={5} height={83} width={114} stroke="none" />
            <rect
              rx={4.5}
              height={80.81}
              width={113}
              y={1.69}
              x={0.5}
              strokeWidth={0.993}
            />
          </g>
          <g transform="translate(458.182 84.431)" fill="#fff" stroke="#707070">
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <g
            transform="translate(458.182 117.431)"
            fill="#fff"
            stroke="#707070"
          >
            <rect rx={14} height={28} width={102} stroke="none" />
            <rect
              rx={13.5}
              height={27}
              width={101}
              y={0.5}
              x={0.5}
              fill="none"
            />
          </g>
          <text
            style={{
              textAlign: "end",
            }}
            y={91.431}
            x={576.447}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={103.431}
              x={547.081}
            >
              {parseDisplayValue(
                simResult["extractvapor_temp_val"],
                "extractvapor_temp_val"
              )}
            </tspan>
          </text>
          <text
            style={{
              textAlign: "end",
            }}
            y={123.431}
            x={534.859}
            fontWeight={500}
            fontSize={12.5}
            className="parsedSVG_svg__sim_txt"
            fontFamily="Roboto-Medium,Roboto"
            textAnchor="end"
            fill="#707070"
          >
            <tspan
              style={{
                textAlign: "end",
              }}
              y={135.431}
              x={546.704}
            >
              {parseDisplayValue(
                simResult["extractvapor_hum_val"],
                "extractvapor_hum_val"
              )}
            </tspan>
          </text>
          <g
            transform="translate(473.182 89.431)"
            data-name="Rectangle 184"
            fill="#fff"
            stroke="#707070"
          >
            <rect rx={3} height={12} width={6} stroke="none" />
            <rect rx={2.5} height={11} width={5} y={0.5} x={0.5} fill="none" />
          </g>
          <g
            transform="translate(471.182 97.431)"
            data-name="Ellipse 20"
            fill="#fff"
            stroke="#707070"
          >
            <circle r={5} cy={5} cx={5} stroke="none" />
            <circle r={4.5} cy={5} cx={5} fill="none" />
          </g>
          <path
            data-name="Rectangle 185"
            fill="#fff"
            d="M474.182 94.431h4v8h-4z"
          />
          <circle
            r={3}
            cy={102.431}
            cx={476.182}
            data-name="Ellipse 21"
            fill="#707070"
          />
          <g
            transform="translate(475.182 94.431)"
            data-name="Rectangle 186"
            fill="#707070"
            stroke="#707070"
          >
            <rect rx={1} height={7} width={2} stroke="none" />
            <rect rx={0.5} height={6} width={1} y={0.5} x={0.5} />
          </g>
          <path
            d="M476.182 127.931c1.829 2.718 3 4.36 3 6a3 3 0 01-6 0c0-1.64 1.17-3.281 3-6zm0-2c-2.435 3.6-4 5.812-4 8a4 4 0 108 0c0-2.188-1.565-4.4-4-8z"
            data-name="iconmonstr-drop-2"
            fill="#707070"
          />
          <path
            d="M476.16 128.431l-2.267 3.484-.62 2.4 1.687 2.414 3 .08 1.105-2.781-1.105-3z"
            data-name="Path 157"
            fill="#707070"
            stroke="#707070"
          />
          <path
            d="M566.075 99.43h26.875"
            data-name="Path 158"
            fill="none"
            stroke="#d39e3a"
            strokeWidth={0.931}
          />
          <text
            x={99.91}
            y={39.75}
            fontWeight={700}
            fontSize={12.5}
            fontFamily="Roboto-Medium,Roboto"
            fill="#58b549"
          >
            <tspan x={99.91} y={39.75}>
              {" Exhaust "}
            </tspan>
          </text>
          <text
            x={811.472}
            y={39.841}
            fontWeight={700}
            fontSize={12.5}
            fontFamily="Roboto-Medium,Roboto"
            fill="#ea9934"
          >
            <tspan x={811.472} y={39.841}>
              {" Extract "}
            </tspan>
          </text>
          <path
            d="M801.252 50.267l-5-5.5 5-5.5"
            data-name="Path 134"
            fill="none"
            stroke="#e99937"
            strokeWidth={2}
          />
          <path
            d="M87.81 49.35l-5-5.5 5-5.5"
            data-name="Path 134"
            fill="none"
            stroke="#59b64d"
            strokeWidth={2}
          />
        </g>
      </g>
    </svg>
  );
};

export default GraphicSVG;
