import React from "react";

interface Props {}

const CMLogo = (props: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-bbox="32.796 31.968 511.804 86.018"
      data-type="color"
      preserveAspectRatio="xMidYMid meet"
      viewBox="32.796 31.968 511.804 86.018"
      style={{ width: "200px" }}
    >
      <g fill="#fff">
        <path
          d="M118.3 85.1c-3-1-6.1-1.5-9.2-1.6-8.8 0-15.3 5.6-15.3 14.7 0 8.4 5.5 13.1 13.8 13.1 3.4-.1 6.8-.7 10.1-1.7l-1 6.8c-3.4.6-6.9 1-10.4 1-11.4 0-21.2-6.2-21.2-18.8 0-13.8 9.6-21.3 22.9-21.3 3.4.1 6.7.4 10 1.1l.3 6.7zM123.1 116.7c.8-11.9 1.6-23.2 1.9-38.7h8.4c-1 12.9-1.5 25.8-1.9 38.7h-8.4zM148.6 87.8c-.8 8.6-1.1 17.2-1.1 28.8h-8.6c.9-9.6 1.3-19.2 1.3-28.8h8.4zm-8.2-4.8l.1-5.4h8.4l-.2 5.4h-8.3zM163.4 92.3l.1.1c1.9-3.4 5.5-5.4 9.4-5.2 3.9 0 7.3 1.4 8.6 5.3 2.1-3.8 5.6-5.3 9.8-5.3 5.1 0 9.3 2.3 9.3 7.8 0 1.7-.9 11.6-1 21.7h-8.1c.4-4.4 1-16.3 1-18.2 0-2.9-1.1-4.8-4.3-4.8-3.8 0-5.6 2.9-6.2 6.2-.6 3.5-.8 13.9-.9 16.8H173c.4-6 .9-12 .9-18.1 0-2.8-1-5-4.2-5-3.9 0-5.6 3.1-6.2 6.6-.7 4.1-.8 13.8-.8 16.5h-8.2c.2-2.4 1.2-17.4 1.3-28.8h7.7l-.1 4.4zM220.8 116.9c-1.8.4-3.5.5-5.3.5-9.3 0-10.3-5.6-10.3-8.2 0-11.7 20.4-8 20.4-14.2 0-2.8-3.1-3.2-5.3-3.2-3.9.1-7.7.9-11.3 2.4l.9-5.4c4.1-1 8.3-1.6 12.5-1.7 5.1 0 11.3 1.2 11.3 7.6 0 4.1-1 13.3-1 22h-8.1c.3-2.6.5-5.2 1-14.9-3 2.7-12.7 2.4-12.7 7.5 0 2.7 2.6 3.4 4.9 3.4 1.5 0 3.1-.3 4.5-.9l-1.5 5.1z"
          data-color="1"
        ></path>
        <path
          d="M220.8 116.9c-1.8.4-3.5.5-5.3.5-9.3 0-10.3-5.6-10.3-8.2 0-11.7 20.4-8 20.4-14.2 0-2.8-3.1-3.2-5.3-3.2-3.9.1-7.7.9-11.3 2.4l.9-5.4c4.1-1 8.3-1.6 12.5-1.7 5.1 0 11.3 1.2 11.3 7.6 0 4.1-1 13.3-1 22h-8.1c.3-2.6.5-5.2 1-14.9-3 2.7-12.7 2.4-12.7 7.5 0 2.7 2.6 3.4 4.9 3.4 1.5 0 3.1-.3 4.5-.9l-1.5 5.1z"
          data-color="1"
          opacity="0.6"
        ></path>
        <path
          d="M250.2 93.5c-.1 2.5-.6 11.3-.6 13.3 0 2.4.5 4.4 3 4.4 1.6 0 3.1-.2 4.6-.7l-1 6c-2.1.6-4.3.9-6.5.9-5.5 0-8.2-1.8-8.2-7.5 0-3 .8-15.1.9-16.4h-4.1l.2-5.7h4.3l.3-5.3 7.5-1.7-.2 6.9h7.2l-.2 5.7-7.2.1z"
          data-color="1"
        ></path>
        <path
          d="M250.2 93.5c-.1 2.5-.6 11.3-.6 13.3 0 2.4.5 4.4 3 4.4 1.6 0 3.1-.2 4.6-.7l-1 6c-2.1.6-4.3.9-6.5.9-5.5 0-8.2-1.8-8.2-7.5 0-3 .8-15.1.9-16.4h-4.1l.2-5.7h4.3l.3-5.3 7.5-1.7-.2 6.9h7.2l-.2 5.7-7.2.1z"
          data-color="1"
          opacity="0.6"
        ></path>
        <path
          d="M268.5 102.7v.9c0 5.4 3.7 8.4 8.9 8.4 3.3-.1 6.5-.7 9.6-1.8l-1.1 6.1c-3.1.7-6.2 1.1-9.4 1.1-14.9 0-16.1-10.1-16.1-14.1 0-5.3 2-16.1 15.7-16.1 7.8 0 13.5 4 13.5 12.2 0 1.1-.1 2.3-.4 3.4l-20.7-.1zm13.4-3.9c0-4.5-1.6-6.8-6-6.8-4.1 0-6.7 3.1-7.2 6.8h13.2z"
          data-color="1"
        ></path>
        <path
          d="M268.5 102.7v.9c0 5.4 3.7 8.4 8.9 8.4 3.3-.1 6.5-.7 9.6-1.8l-1.1 6.1c-3.1.7-6.2 1.1-9.4 1.1-14.9 0-16.1-10.1-16.1-14.1 0-5.3 2-16.1 15.7-16.1 7.8 0 13.5 4 13.5 12.2 0 1.1-.1 2.3-.4 3.4l-20.7-.1zm13.4-3.9c0-4.5-1.6-6.8-6-6.8-4.1 0-6.7 3.1-7.2 6.8h13.2z"
          data-color="1"
          opacity="0.6"
        ></path>
        <path
          d="M319.5 116.7h-7.9l-5.5-19.8c-1-3.7-2.4-7.8-3.1-10.8h-.1c-.9 10-1.6 20.3-1.9 30.6h-7.4c1.5-11.9 3.1-36.2 3.3-38.7h11.4c.8 3.9 1.8 7.7 3.2 11.5 1.9 5.6 3.3 11.4 4.8 16.8h.1c2-5.4 4.1-11.2 6.5-16.7 1.7-3.7 3.2-7.6 4.4-11.5H339c-.5 11-.8 21.3-.8 38.7h-7.7c.4-9.1.8-18.3.8-27.5v-3.2h-.1l-11.7 30.6z"
          data-color="1"
        ></path>
        <path
          d="M319.5 116.7h-7.9l-5.5-19.8c-1-3.7-2.4-7.8-3.1-10.8h-.1c-.9 10-1.6 20.3-1.9 30.6h-7.4c1.5-11.9 3.1-36.2 3.3-38.7h11.4c.8 3.9 1.8 7.7 3.2 11.5 1.9 5.6 3.3 11.4 4.8 16.8h.1c2-5.4 4.1-11.2 6.5-16.7 1.7-3.7 3.2-7.6 4.4-11.5H339c-.5 11-.8 21.3-.8 38.7h-7.7c.4-9.1.8-18.3.8-27.5v-3.2h-.1l-11.7 30.6z"
          data-color="1"
          opacity="0.6"
        ></path>
        <path
          d="M355.6 116.9c-1.8.4-3.5.5-5.3.5-9.3 0-10.3-5.6-10.3-8.2 0-11.7 20.4-8 20.4-14.2 0-2.8-3.1-3.2-5.3-3.2-3.9.1-7.7.9-11.3 2.4l.9-5.4c4.1-1 8.3-1.6 12.5-1.7 5.1 0 11.3 1.2 11.3 7.6 0 4.1-1 13.3-1 22h-8.1c.3-2.6.5-5.2 1-14.9-3 2.7-12.7 2.4-12.7 7.5 0 2.7 2.5 3.4 4.9 3.4 1.5 0 3.1-.3 4.5-.9l-1.5 5.1z"
          data-color="1"
        ></path>
        <path
          d="M355.6 116.9c-1.8.4-3.5.5-5.3.5-9.3 0-10.3-5.6-10.3-8.2 0-11.7 20.4-8 20.4-14.2 0-2.8-3.1-3.2-5.3-3.2-3.9.1-7.7.9-11.3 2.4l.9-5.4c4.1-1 8.3-1.6 12.5-1.7 5.1 0 11.3 1.2 11.3 7.6 0 4.1-1 13.3-1 22h-8.1c.3-2.6.5-5.2 1-14.9-3 2.7-12.7 2.4-12.7 7.5 0 2.7 2.5 3.4 4.9 3.4 1.5 0 3.1-.3 4.5-.9l-1.5 5.1z"
          data-color="1"
          opacity="0.6"
        ></path>
        <path
          d="M393.7 116.4c-2.6.7-5.3 1-8 1-9.2 0-14.4-5.2-14.4-14.4 0-2.3 0-15.8 15.4-15.8 2.9 0 5.7.4 8.5 1.1l.5 6.3c-2.4-.9-4.9-1.3-7.5-1.4-5.9 0-8.8 4-8.8 9.6 0 5.3 2.7 8.6 8.2 8.6 2.4-.1 4.8-.5 7.1-1.3l-1 6.3z"
          data-color="1"
        ></path>
        <path
          d="M393.7 116.4c-2.6.7-5.3 1-8 1-9.2 0-14.4-5.2-14.4-14.4 0-2.3 0-15.8 15.4-15.8 2.9 0 5.7.4 8.5 1.1l.5 6.3c-2.4-.9-4.9-1.3-7.5-1.4-5.9 0-8.8 4-8.8 9.6 0 5.3 2.7 8.6 8.2 8.6 2.4-.1 4.8-.5 7.1-1.3l-1 6.3z"
          data-color="1"
          opacity="0.6"
        ></path>
        <path
          d="M408.2 78c-.4 4.9-.8 9.9-.9 14.6l.1.1c2.4-4.3 6.3-5.6 11.4-5.6 6.1 0 10.8 2.4 10.8 9.2 0 3.5-.8 11.7-.8 20.4h-8.6c.8-5.6 1.3-11.2 1.3-16.8 0-4.2-1.9-6.2-6.1-6.2-4.6 0-7.1 3.4-7.9 7.6-.9 4.6-.9 12.9-1 15.5h-8.3c1-12.9 1.6-25.8 1.8-38.7l8.2-.1z"
          data-color="1"
        ></path>
        <path
          d="M408.2 78c-.4 4.9-.8 9.9-.9 14.6l.1.1c2.4-4.3 6.3-5.6 11.4-5.6 6.1 0 10.8 2.4 10.8 9.2 0 3.5-.8 11.7-.8 20.4h-8.6c.8-5.6 1.3-11.2 1.3-16.8 0-4.2-1.9-6.2-6.1-6.2-4.6 0-7.1 3.4-7.9 7.6-.9 4.6-.9 12.9-1 15.5h-8.3c1-12.9 1.6-25.8 1.8-38.7l8.2-.1z"
          data-color="1"
          opacity="0.6"
        ></path>
        <path
          d="M441.8 87.8c-.8 8.6-1 17.2-1.1 28.8H432c.9-9.6 1.3-19.2 1.3-28.8h8.5zm-8.3-4.8l.1-5.4h8.3l-.2 5.4h-8.2z"
          data-color="1"
        ></path>
        <path
          d="M441.8 87.8c-.8 8.6-1 17.2-1.1 28.8H432c.9-9.6 1.3-19.2 1.3-28.8h8.5zm-8.3-4.8l.1-5.4h8.3l-.2 5.4h-8.2z"
          data-color="1"
          opacity="0.6"
        ></path>
        <path
          d="M453.7 92.6h.1c2.4-4 6.4-5.4 11.1-5.4 6.1 0 10.8 2.4 10.8 9.2 0 3.5-.8 11.7-.8 20.4h-8.6c.8-5.6 1.3-11.2 1.4-16.8 0-4.2-1.9-6.2-6.1-6.2-4.6 0-7.1 3.4-7.9 7.6-.9 4.6-.9 12.9-1 15.5h-8.3c.8-8.8 1.4-17.6 1.6-28.8h8.1l-.4 4.5z"
          data-color="1"
        ></path>
        <path
          d="M453.7 92.6h.1c2.4-4 6.4-5.4 11.1-5.4 6.1 0 10.8 2.4 10.8 9.2 0 3.5-.8 11.7-.8 20.4h-8.6c.8-5.6 1.3-11.2 1.4-16.8 0-4.2-1.9-6.2-6.1-6.2-4.6 0-7.1 3.4-7.9 7.6-.9 4.6-.9 12.9-1 15.5h-8.3c.8-8.8 1.4-17.6 1.6-28.8h8.1l-.4 4.5z"
          data-color="1"
          opacity="0.6"
        ></path>
        <path
          d="M485.5 102.7v.9c0 5.4 3.6 8.4 8.9 8.4 3.3-.1 6.5-.7 9.6-1.9l-1 6.1c-3.1.7-6.2 1.1-9.4 1.1-14.9 0-16.1-10.1-16.1-14.1 0-5.3 2-16.1 15.7-16.1 7.8 0 13.5 4 13.5 12.2 0 1.1-.1 2.3-.3 3.4h-20.9zm13.4-3.9c0-4.5-1.6-6.8-6-6.8-4.1 0-6.7 3.1-7.2 6.8h13.2z"
          data-color="1"
        ></path>
        <path
          d="M485.5 102.7v.9c0 5.4 3.6 8.4 8.9 8.4 3.3-.1 6.5-.7 9.6-1.9l-1 6.1c-3.1.7-6.2 1.1-9.4 1.1-14.9 0-16.1-10.1-16.1-14.1 0-5.3 2-16.1 15.7-16.1 7.8 0 13.5 4 13.5 12.2 0 1.1-.1 2.3-.3 3.4h-20.9zm13.4-3.9c0-4.5-1.6-6.8-6-6.8-4.1 0-6.7 3.1-7.2 6.8h13.2z"
          data-color="1"
          opacity="0.6"
        ></path>
        <path
          d="M507.3 109.9c2.6 1.2 5.4 2 8.3 2.1 1.8 0 4.4-.6 4.4-3 0-4.1-11-7.3-11-13.9 0-4.8 3.3-8 11-8 2.9.1 5.8.4 8.6.9l.4 6c-2-.9-4.1-1.4-6.3-1.5-2.4 0-4.8.3-4.8 2.8 0 3.7 11 7.1 11 14.3 0 6-6.7 7.8-11.5 7.8-3.1-.1-6.3-.5-9.3-1.2l-.8-6.3z"
          data-color="1"
        ></path>
        <path
          d="M507.3 109.9c2.6 1.2 5.4 2 8.3 2.1 1.8 0 4.4-.6 4.4-3 0-4.1-11-7.3-11-13.9 0-4.8 3.3-8 11-8 2.9.1 5.8.4 8.6.9l.4 6c-2-.9-4.1-1.4-6.3-1.5-2.4 0-4.8.3-4.8 2.8 0 3.7 11 7.1 11 14.3 0 6-6.7 7.8-11.5 7.8-3.1-.1-6.3-.5-9.3-1.2l-.8-6.3z"
          data-color="1"
          opacity="0.6"
        ></path>
        <path
          d="M531.1 88.7h5.5v1h-1.9l-.2 4.9h-1.8c.1-1.6.2-3.3.2-4.9H531l.1-1zm11.8 5.9c.1-1.5.1-3 .1-4.6l-1.8 4.6h-1.3l-1.4-4.6c-.1 1.5-.2 3-.3 4.6h-1.4c.2-2 .4-4 .5-5.9h2.2l1.2 3.9 1.6-3.9h2.3v5.9h-1.7zM86.2 116.8s-19.6 5.7-36.9-7.9C28.8 92.7 26.9 60.8 46.4 44c28.6-24.6 54.3-4.4 57.8-1.1 2.4 2.3 25.5 25.5 25.5 25.5l-6 5.7s-17.2-17.5-20.4-20.4c-5.9-5.3-21.5-9-31.8-1.6-.1.1 19.2 19.6 20.4 20.8 4.4 4.4-4.5 13.2-9 8.7C80.8 79.5 62 61.2 62 61.2S50.3 77 64.1 94.8c.2 0 22.1 22 22.1 22zM528.8 91.1v-.3l-.1.2.1.1z"
          data-color="1"
        ></path>
        <path d="M528.8 91.1v-.3l-.1.2.1.1z" data-color="1"></path>
      </g>
    </svg>
  );
};

export default CMLogo;
