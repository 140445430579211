import React from "react";
import { TimeSlot, Setting } from "./ScheduleInput";
import TimeIcon from "./TimeIcon";
import moment from "moment";
import HoverTooltip from "components/basic/HoverTooltip";

interface Props {
  timeslots: TimeSlot[];
  settings: Setting[];
}

const DayViewer: React.FC<Props> = ({ timeslots, settings }) => {
  const dayhours = [...Array(24).keys()];
  return (
    <div className="w-full relative flex items-center" style={{ height: "32px" }}>
      <div className="w-full flex" style={{ paddingLeft: "22px", paddingRight: "22px" }}>
        {dayhours.map(k => {
          const midTime = moment(`${k}:30`, "HH:mm");
          const ts = timeslots.find(ts => midTime.isBetween(ts.startTime, ts.endTime));
          const setting = settings.find(s => s.id === ts?.settingID);
          const color = setting ? setting.color : "#555555";
          return (
            <HoverTooltip
              key={k}
              className="flex-1 h-1"
              text={`${setting ? setting.name : "Off"} ${
                ts ? `${ts.startTime.format("HH:mm")}-${ts.endTime.format("HH:mm")}` : ""
              }
            `}
            >
              <div style={{ backgroundColor: color }} className="w-full h-1"></div>
            </HoverTooltip>
          );
        })}
      </div>
      <div
        className="absolute top-0 left-0 w-full flex justify-between"
        style={{ marginTop: "-6px" }}
      >
        <TimeIcon time={0} />
        <TimeIcon time={6} />
        <TimeIcon time={12} />
        <TimeIcon time={18} />
        <TimeIcon time={24} flipped />
      </div>
    </div>
  );
};

export default DayViewer;
