import React, { useState, useMemo } from "react";
import { MachineIcon } from "../../basic/MachineIcon";
import {
  Condition,
  ConditionResult,
  Machine,
  StaticSimResult,
} from "../../../model/dataTypes";
import InfoTag from "../../basic/InfoTag";

import InlineDropdown from "./InlineDropdown";
import LoadingIcon from "components/basic/LoadingIcon/LoadingIcon";
import parseDisplayValue from "utility/parseDisplayValue";
import CoolingIcon from "components/input/basic/icons/CoolingIcon";
import CheckECool, {
  hasManualEcool,
  manualEcoolActive,
} from "utility/CheckECool";
import EvapCoolingInfo from "./EvapCoolingInfo";
import ConditionInfo from "./ConditionInfo";

interface OverallResultsTableProps {
  staticSimResults: StaticSimResult[];
  machines: Machine[];
  conditions: Condition[];
  selectedParameter: { display: string; key: keyof ConditionResult };
  selectParamOptions: { display: string; key: keyof ConditionResult }[];
  onSelectParam: (newParam: {
    display: string;
    key: keyof ConditionResult;
  }) => void;
}

export const OverallResultsTable: React.FC<OverallResultsTableProps> = ({
  staticSimResults,
  machines,
  conditions,
  selectedParameter,
  selectParamOptions,
  onSelectParam,
}) => {
  const hasSomeProductSheet = useMemo(() => {
    return machines.some((m) => !!m.productSheet);
  }, [machines]);

  const renderResManualEcool = (condition: Condition, machine: Machine) => {
    const result = staticSimResults.find(
      (sr) => sr.machineID === machine.id && sr.conditionID === condition.id
    );
    return (
      <OverallResultManualEcool
        staticRes={result}
        selectedParameter={selectedParameter}
      />
    );
  };
  const renderResults = (condition: Condition, machine: Machine) => {
    const normalRes = staticSimResults.find(
      (sr) =>
        sr.machineID === machine.id &&
        sr.conditionID === condition.id &&
        !sr.ECOOL
    );
    const ecoolRes = staticSimResults.find(
      (sr) =>
        sr.machineID === machine.id &&
        sr.conditionID === condition.id &&
        sr.ECOOL
    );
    return (
      <OverallResult
        key={machine.id + condition.id}
        ecoolRes={ecoolRes}
        normalRes={normalRes}
        selectedParameter={selectedParameter}
      />
    );
  };

  return (
    <div className="">
      <div className={`font-bold text-sm h-20 flex`}>
        <div className={`w-16 flex-none bg-gray-100 ${twStyles.td}`}></div>
        <div className={`w-32 flex-none bg-gray-100 ${twStyles.td}`}>
          Machine
        </div>
        <div className={`${twStyles.td} bg-gray-100 w-32 flex-none`}>
          Dimensions
        </div>

        {conditions.map((condition) => {
          let options = selectParamOptions;
          const useECool =
            manualEcoolActive(condition) ||
            (!hasManualEcool(condition) && CheckECool(condition));
          let display = selectedParameter.display;
          if (selectedParameter.key === "sum_power_total")
            display = useECool ? "Cooling capacity" : "Heat recovered";
          return (
            <div
              style={{ flex: `1 0 8rem` }}
              key={condition.id}
              className={`flex flex-col bg-gray-100`}
            >
              <div
                className={`flex-1  ${twStyles.td} w-full justify-between relative`}
              >
                <span className="mr-2 truncate">{condition.name}</span>
                <InfoTag mt={20} rightAlign className="no-print flex-none">
                  <ConditionInfo condition={condition} />
                </InfoTag>
              </div>
              <div
                className={`w-full flex-1 h-1/2 font-bold justify-end text-right text-xxs ${twStyles.td}`}
              >
                <InlineDropdown
                  options={options.map((option) => ({
                    id: option.key,
                    display: option.display,
                    val: option,
                  }))}
                  selectedDisplay={display}
                  onSelect={(option) => {
                    onSelectParam(option.val);
                  }}
                />
              </div>
            </div>
          );
        })}

        {hasSomeProductSheet && (
          <div className={`w-24 flex-none bg-gray-100 ${twStyles.td}`}>
            Product sheet
          </div>
        )}
      </div>

      {machines.map((machine) => (
        <div key={machine.id} className={`${twStyles.tr}`}>
          <div
            className={`border border-gray-200 flex items-center px-2 w-16 flex-none justify-center`}
          >
            <MachineIcon machineID={machine.id} className="w-14 -mx-2" />
          </div>
          <div className={`${twStyles.td} w-32 flex-none justify-between`}>
            <span className="px-2">{machine.name}</span>
          </div>
          <div
            className={`${twStyles.td} whitespace-no-wrap w-32 flex-none`}
          >{`${machine.outerDimensions["length"]} x ${machine.outerDimensions["height"]} x ${machine.outerDimensions["depth"]}`}</div>

          {conditions.map((condition) => {
            if (hasManualEcool(condition))
              return renderResManualEcool(condition, machine);
            else return renderResults(condition, machine);
          })}
          {hasSomeProductSheet && (
            <div
              className={`${twStyles.td} w-24 flex-none justify-end text-blue-600 underline`}
            >
              {machine.productSheet && (
                <a
                  href={machine.productSheet}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  View
                </a>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const twStyles = {
  td: "px-3 border border-gray-200 flex items-center",
  tr: "bg-white h-12 flex",
  infoTd: "px-3 py-1",
  rightInfoTd: "",
};

interface OverallResultsProps {
  normalRes?: StaticSimResult;
  ecoolRes?: StaticSimResult;
  selectedParameter: { display: string; key: keyof ConditionResult };
}

const OverallResult = ({
  normalRes,
  ecoolRes,
  selectedParameter,
}: OverallResultsProps) => {
  const [hideECool, setHideECool] = useState(false);
  const showingECool = !!ecoolRes && !hideECool;
  const staticRes = showingECool ? ecoolRes : normalRes;
  const res = staticRes?.job?.result;

  if (staticRes)
    return (
      <div
        style={{ flex: `1 0 8rem` }}
        className={`${
          twStyles.td
        } flex-row-reverse whitespace-no-wrap flex-none ${
          res ? "justify-between" : "justify-center bg-gray-200"
        }`}
      >
        {res ? (
          <>
            <span className="">
              {parseDisplayValue(
                showingECool && selectedParameter.key === "sum_power_total"
                  ? res[selectedParameter.key] * -1
                  : res[selectedParameter.key],
                selectedParameter.key
              )}
            </span>
            {ecoolRes?.job.result && (
              <EvapCoolingInfo
                active={showingECool}
                onClick={() => setHideECool(false)}
                ecoolResult={ecoolRes.job.result}
                normalResult={normalRes?.job.result}
              />
            )}
          </>
        ) : (
          <SimulationStatus status={staticRes.job.status} />
        )}
      </div>
    );
  else return null;
};

interface OverallAdminResultsProps {
  staticRes?: StaticSimResult;
  selectedParameter: { display: string; key: keyof ConditionResult };
}

const OverallResultManualEcool = ({
  staticRes,
  selectedParameter,
}: OverallAdminResultsProps) => {
  const res = staticRes?.job?.result;
  console.log(res);
  const ecoolWaterTarget = !!res && res?.extract_vap_val;
  if (staticRes)
    return (
      <div
        style={{ flex: `1 0 8rem` }}
        className={`${twStyles.td} flex-row-reverse whitespace-no-wrap w-32 ${
          res ? "justify-between" : "justify-center bg-gray-200"
        }`}
      >
        {res ? (
          <>
            <span className="">
              {parseDisplayValue(
                ecoolWaterTarget && selectedParameter.key === "sum_power_total"
                  ? res[selectedParameter.key] * -1
                  : res[selectedParameter.key],
                selectedParameter.key
              )}
            </span>
            {!!ecoolWaterTarget && (
              <InfoTag
                mt={20}
                rightAlign
                icon={<CoolingIcon className="text-blue-600" />}
              >
                <>
                  <div className="font-medium text-xs">Evaporative cooling</div>
                  <div className="text-xs">
                    <b>{ecoolWaterTarget.toFixed(2)} g/kg</b> injected water
                  </div>
                </>
              </InfoTag>
            )}
          </>
        ) : (
          <SimulationStatus status={staticRes.job.status} />
        )}
      </div>
    );
  else return null;
};

const SimulationStatus = ({
  status,
}: {
  status: "submitted" | "leased" | "completed" | "failed";
}) => {
  if (status === "leased") return <LoadingIcon />;
  else if (status === "submitted")
    return <div className="text-gray-600">waiting</div>;
  else if (status === "failed") return <div>simulation failed</div>;
  else return null;
};
