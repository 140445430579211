import { Condition } from "../model/dataTypes";
import uuid from "uuid";

export const getExtraCondition = (id: string): Condition => {
  return {
    id,
    name: "New case",
    outsideAir: 10,
    outsideHumidity: 60,
    outsideFlow: 10,
    extractAir: 28,
    extractHumidity: 30,
    extractFlow: 11,
  };
};

export const defaultCondition: Condition = {
  id: "1",
  name: "Condition",
  outsideAir: 15,
  outsideHumidity: 60,
  outsideFlow: 5,
  extractAir: 22,
  extractHumidity: 60,
  extractFlow: 5,
};

export const stdConditions: Condition[] = [
  {
    id: uuid(),
    name: "Summer",
    outsideAir: 25,
    outsideHumidity: 60,
    outsideFlow: 5,
    extractAir: 24,
    extractHumidity: 30,
    extractFlow: 5,
  },
  {
    id: uuid(),
    name: "SS-EN308:1997",
    outsideAir: +5,
    outsideHumidity: 70,
    outsideFlow: 5,
    extractAir: 25,
    extractHumidity: 27,
    extractFlow: 5,
  },
  {
    id: uuid(),
    name: "Winter",
    outsideAir: -5,
    outsideHumidity: 70,
    outsideFlow: 5,
    extractAir: 22,
    extractHumidity: 30,
    extractFlow: 5,
  },
];
