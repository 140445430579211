import React, { useState, useEffect, useRef, useCallback } from "react";
import { TimeSlot, Setting } from "./ScheduleInput";
import { TimePicker } from "./Timepicker";
import { useClickOutsideEffect } from "utility/useClickOutside";

interface Props {
  slot: TimeSlot;
  setting: Setting;
  onAdd: (slot: TimeSlot) => void;
  settings: Setting[];
}

export const TimeSlotButton: React.FC<Props> = ({ slot, setting, onAdd, settings }) => {
  const [isOpen, setisOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleClickoutside = (ev: MouseEvent) => {
    if (
      ref.current &&
      ev.target &&
      ev.target instanceof Node &&
      !ref.current.contains(ev.target)
    )
      setisOpen(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickoutside);
    return () => {
      document.removeEventListener("mousedown", handleClickoutside);
    };
  }, []);
  return (
    <div className="relative my-2">
      <div className="flex cursor-pointer" onClick={() => setisOpen(true)}>
        <div className={`w-1/2 ${tw.timeslotBtn}`}>
          {`${slot.startTime.format("HH:mm")} - ${slot.endTime.format("HH:mm")}`}
        </div>
        <div className={`w-1/2 ${tw.timeslotBtn}`} style={{ borderColor: setting.color }}>
          {setting.name}
        </div>
      </div>
      {isOpen && (
        <div ref={ref} className="absolute top-0 z-10 mt-8 w-full">
          <TimePicker
            onAdd={(newTimeslot) => {
              onAdd(newTimeslot);
              setisOpen(false);
            }}
            settings={settings}
            preset={slot}
          />
        </div>
      )}
    </div>
  );
};

interface AddTimeslotProps {
  onAdd: (slot: TimeSlot) => void;
  settings: Setting[];
}

export const AddTimeslot: React.FC<AddTimeslotProps> = ({ onAdd, settings }) => {
  const [isOpen, setisOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleClickOutside = useCallback(() => {
    setisOpen(false);
  }, []);
  useClickOutsideEffect(ref, handleClickOutside);

  return (
    <div className="relative w-full">
      <button onClick={() => setisOpen(true)} className={`w-full ${tw.timeslotBtn} font-bold`}>
        Add new time range
      </button>
      {isOpen && (
        <div ref={ref} className="absolute z-10 mt-2 w-full">
          <TimePicker
            onAdd={(newTimeslot) => {
              onAdd(newTimeslot);
              setisOpen(false);
            }}
            settings={settings}
          />
        </div>
      )}
    </div>
  );
};

const tw = {
  dayBtn: "px-4 py-2 text-xs font-bold focus:outline-none",
  activeDayBtn: "border-b-2 border-cm-blue",
  inactiveDayBtn: "opacity-75",
  timeslotBtn:
    "w-1/2 border border-gray-300 bg-white px-2 py-1 text-center text-xs focus:outline-none cursor-pointer",
};
