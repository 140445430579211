export const convertUnit = (
  fromUnit: string,
  toUnit: string,
  prevVal: number
) => {
  if (prevVal === 0) return prevVal;

  let updatedVal = 0;

  switch (fromUnit) {
    case "m³/s":
      if (toUnit === "m³/h") {
        updatedVal = prevVal * 3600;
      } else if (toUnit === "l/s") {
        updatedVal = prevVal * 1000;
      }
      break;
    case "m³/h":
      if (toUnit === "m³/s") {
        updatedVal = prevVal / 3600;
      } else if (toUnit === "l/s") {
        updatedVal = prevVal / 3.6;
      }
      break;
    case "l/s":
      if (toUnit === "m³/s") {
        updatedVal = prevVal / 1000;
      } else if (toUnit === "m³/h") {
        updatedVal = prevVal * 3.6;
      }
      break;
    default:
      console.error("fromUnit type not supported");
  }

  const res = parseFloat(updatedVal.toFixed(2));
  return res;
};
