import React, { useEffect, useRef, useState } from "react";
import { Setting } from "./ScheduleInput";
import { InputNum, InputText } from "components/input/basic/Input";
import uuid from "uuid";

interface Props {
  settings: Setting[];
  updateSettings: (newSettings: Setting[]) => void;
}

const SettingsInput: React.FC<Props> = ({ settings, updateSettings }) => {
  return (
    <div className="px-4 py-2">
      <label className="text-xs font-bold">Flow</label>
      {settings.map((setting, i) => {
        return (
          <div
            key={setting.id}
            className="border border-gray-300 px-2 flex justify-between items-center my-2"
            style={{ borderColor: setting.color }}
          >
            <div>{setting.name}</div>
            <InputNum
              className="border-l border-gray-300 w-20"
              value={setting.flow}
              unit={"l/s"}
              onChange={newVal => {
                updateSettings([
                  ...settings.slice(0, i),
                  { ...setting, flow: newVal },
                  ...settings.slice(i + 1),
                ]);
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export const AddFlowButton: React.FC<Props> = ({ updateSettings, settings }) => {
  const [isOpen, setisOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleClickoutside = (ev: MouseEvent) => {
    if (
      ref.current &&
      ev.target &&
      ev.target instanceof Node &&
      !ref.current.contains(ev.target)
    )
      setisOpen(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickoutside);
    return () => {
      document.removeEventListener("mousedown", handleClickoutside);
    };
  }, []);
  return (
    <div className="relative">
      <button className={`w-full ${tw.timeslotBtn} font-bold`} onClick={() => setisOpen(true)}>
        Add new flow setting
      </button>
      {isOpen && (
        <div ref={ref} className="absolute z-10 left-0 mt-2 w-full">
          <AddFlowSetting
            onAdd={newSetting => {
              updateSettings([...settings, newSetting]);
            }}
          />
        </div>
      )}
    </div>
  );
};

const AddFlowSetting: React.FC<{ onAdd: (setting: Setting) => void }> = ({ onAdd }) => {
  const [flow, setflow] = useState(0);
  const [name, setname] = useState("");
  const isValid = flow > 0 && name.length > 0;
  return (
    <div className="bg-white text-xs rounded-lg shadow-lg px-4 py-2 w-full border border-gray-400">
      <div className=" font-bold my-2">Add flow setting</div>
      <div>
        <div className={tw.inputDiv}>
          <label className={tw.label}>Setting name:</label>
          <InputText
            className={tw.inputBox}
            value={name}
            onChange={newName => {
              setname(newName);
            }}
          />
        </div>
        <div className={tw.inputDiv}>
          <label className={tw.label}>Flow:</label>
          <InputNum
            className={tw.inputBox}
            unit={"l/s"}
            value={flow}
            onChange={newVal => setflow(newVal)}
          />
        </div>
      </div>
      <button
        onClick={() => {
          if (isValid) onAdd({ flow, name, id: uuid(), color: "#a8a8a8" });
        }}
        className={`w-full border rounded shadow py-1 my-2 ${
          isValid ? "" : "opacity-50 cursor-default"
        }`}
      >
        ADD
      </button>
    </div>
  );
};

export default SettingsInput;

const tw = {
  timeslotBtn:
    "w-1/2 border border-gray-300 bg-white px-2 py-1 text-center text-xs focus:outline-none",
  inputBox: "border border-gray-200 w-2/3",
  inputDiv: "flex my-2 items-center",
  label: "w-1/3 text-right pr-4",
};
