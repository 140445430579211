import React, { useState } from "react";
import moment, { Moment } from "moment";
import uuid from "uuid";
import { TimeSlot, Setting } from "./ScheduleInput";

interface Props {
  onAdd: (slot: TimeSlot) => void;
  settings: Setting[];
  preset?: TimeSlot;
}
export const TimePicker: React.FC<Props> = ({ onAdd, settings, preset }) => {
  const [selectedFromTime, setselectedFromTime] = useState<Moment | null>(
    preset ? preset.startTime : null
  );
  const [selectedToTime, setselectedToTime] = useState<Moment | null>(
    preset ? preset.endTime : null
  );
  const [hoveredTime, sethoveredTime] = useState<Moment | null>(null);
  const [settingID, setsettingID] = useState<string | null>(preset ? preset.settingID : null);

  const onSelectTime = (time: Moment) => {
    if (selectedFromTime && selectedToTime) {
      setselectedToTime(null);
      setselectedFromTime(time);
    } else if (!selectedFromTime) setselectedFromTime(time);
    else if (selectedFromTime.isSame(time)) {
      setselectedFromTime(null);
      setselectedToTime(null);
    } else {
      if (time.isBefore(selectedFromTime)) {
        setselectedToTime(selectedFromTime);
        setselectedFromTime(time);
      } else setselectedToTime(time);
    }
  };
  const id = preset ? preset.id : uuid();
  const valid = !!(selectedFromTime && selectedToTime && settingID);

  return (
    <div className=" bg-white text-xs rounded-lg shadow-lg px-4 py-2 w-full border border-gray-400">
      <div className="text-xs font-bold my-2">Select From - To</div>
      <div className="flex flex-wrap">
        {[...Array(25).keys()].map(h => {
          const time = moment(`${h}:00`, "HH:mm");
          const isSelectedTo = selectedFromTime && selectedFromTime.isSame(time, "hour");
          const isSelected =
            isSelectedTo ||
            (selectedFromTime &&
              selectedToTime &&
              time.isBetween(selectedFromTime, selectedToTime, undefined, "[]"));
          const isInHoverRange =
            selectedFromTime &&
            !selectedToTime &&
            hoveredTime &&
            ((hoveredTime >= time && selectedFromTime < time) ||
              (hoveredTime <= time && selectedFromTime > time));

          return (
            <button
              key={h}
              onMouseEnter={() => {
                sethoveredTime(time);
              }}
              className={`px-1 py-1 bg-gray-100 w-1/5 focus:outline-none bg-${
                isSelected
                  ? "blue-400 text-white"
                  : isInHoverRange
                  ? "blue-200 text-gray-700"
                  : "white text-gray-700"
              }`}
              onClick={() => onSelectTime(time)}
            >
              {time.format("HH:mm")}
            </button>
          );
        })}
      </div>
      <div className="flex my-2">
        {settings.map(setting => {
          const isSelected = setting.id === settingID;
          return (
            <button
              key={setting.id}
              className={`px-2 py-1 mx-2 rounded focus:outline-none shadow rounded ${
                isSelected ? "bg-cm-blue text-white" : "text-gray-700"
              }`}
              onClick={() => setsettingID(setting.id)}
            >
              {setting.name}
            </button>
          );
        })}
      </div>

      <button
        onClick={() => {
          if (selectedFromTime && selectedToTime && settingID)
            onAdd({
              startTime: selectedFromTime,
              endTime: selectedToTime,
              id,
              settingID,
            });
        }}
        className={`w-full border rounded shadow py-1 my-2 ${
          valid ? "" : "opacity-50 cursor-default"
        }`}
      >
        {preset ? "UPDATE" : "ADD"}
      </button>
    </div>
  );
};
