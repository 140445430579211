import React from "react";
import InfoTag from "components/basic/InfoTag";
import CoolingIcon from "components/input/basic/icons/CoolingIcon";
import parseDisplayValue from "utility/parseDisplayValue";
import { ConditionResult } from "model/dataTypes";

interface EvapCoolingInfoProps {
  normalResult?: ConditionResult;
  ecoolResult: ConditionResult;
  active: boolean;
  onClick: () => void;
}

const EvapCoolingInfo = ({
  active,
  onClick,
  normalResult,
  ecoolResult,
}: EvapCoolingInfoProps) => {
  return (
    <div onClick={onClick} className={`${active ? "text-blue-600" : "text-gray-500"}`}>
      <InfoTag mt={20} className="no-print flex-none" icon={<CoolingIcon />}>
        <div className="text-gray-700 text-xs p-1">
          <div className="font-bold mb-2">Evaporative Cooling</div>
          {normalResult && (
            <table>
              <thead>
                <tr className="font-medium">
                  <td className={twStyles.infoTd}></td>
                  <td className={`${twStyles.infoTd} text-right`}>Supply temp</td>
                  <td className={`${twStyles.infoTd} text-right`}>Efficiency</td>
                  <td className={`${twStyles.infoTd} text-right`}>Cooling capacity</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={twStyles.infoTd}>Enabled</td>
                  <td className={`${twStyles.infoTd} text-right`}>
                    {parseDisplayValue(ecoolResult.supply_temp_val, "supply_temp_val")}
                  </td>
                  <td className={`${twStyles.infoTd} text-right`}>
                    {parseDisplayValue(
                      ecoolResult.sum_outside_eff_total,
                      "sum_outside_eff_total"
                    )}
                  </td>
                  <td className={`${twStyles.infoTd} text-right`}>
                    {parseDisplayValue(ecoolResult.sum_power_total * -1, "sum_power_total")}
                  </td>
                </tr>
                <tr>
                  <td className={twStyles.infoTd}>Disabled</td>
                  <td className={`${twStyles.infoTd} text-right`}>
                    {parseDisplayValue(normalResult.supply_temp_val, "supply_temp_val")}
                  </td>
                  <td className={`${twStyles.infoTd} text-right`}>
                    {parseDisplayValue(
                      normalResult.sum_outside_eff_total,
                      "sum_outside_eff_total"
                    )}
                  </td>
                  <td className={`${twStyles.infoTd} text-right`}>
                    {parseDisplayValue(normalResult.sum_power_total * -1, "sum_power_total")}
                  </td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      </InfoTag>
    </div>
  );
};

export default EvapCoolingInfo;

const twStyles = {
  td: "px-3 border border-gray-200 flex items-center",
  tableHead: "font-bold text-sm h-20 flex",
  tr: "bg-white h-12 flex",
  infoTd: "px-3 py-1",
  rightInfoTd: "",
};
