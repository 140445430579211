import React, { useMemo } from "react";
import { View, StyleSheet, Text, Image } from "@react-pdf/renderer";
import { Condition } from "model/dataTypes";
import ECoolIcon from "resources/ECoolIcon.png";
import CheckECool, {
  hasManualEcool,
  manualEcoolActive,
} from "utility/CheckECool";

interface Props {
  condition: Condition;
}

const ConditionTablePDF = ({ condition }: Props) => {
  const styles = useMemo(() => {
    return StyleSheet.create({
      td: {
        width: "33%",
        paddingHorizontal: 5,
        paddingVertical: 5,
      },
    });
  }, []);
  const usingEcool = useMemo(
    () =>
      manualEcoolActive(condition) ||
      (!hasManualEcool(condition) && CheckECool(condition)),
    [condition]
  );

  return (
    <View style={{ width: "100%", fontSize: 7 }}>
      <View style={{ flexDirection: "row" }}>
        <Text style={styles.td}></Text>
        <Text style={{ ...styles.td, fontWeight: "medium" }}>Outside</Text>
        <Text style={{ ...styles.td, fontWeight: "medium" }}>Extract</Text>
      </View>
      <View style={{ flexDirection: "row", backgroundColor: "#fff" }}>
        <Text style={{ ...styles.td, fontWeight: "medium" }}>Air</Text>
        <Text style={styles.td}>{condition.outsideAir} °C</Text>
        <Text style={styles.td}>{condition.extractAir} °C</Text>
      </View>
      <View style={{ flexDirection: "row" }}>
        <Text style={{ ...styles.td, fontWeight: "medium" }}>Humidity</Text>
        <Text style={styles.td}>{condition.outsideHumidity} %</Text>
        <Text style={styles.td}>{condition.extractHumidity} %</Text>
      </View>
      <View style={{ flexDirection: "row", backgroundColor: "#fff" }}>
        <Text style={{ ...styles.td, fontWeight: "medium" }}>Flow</Text>
        <Text style={styles.td}>{condition.outsideFlow} m³/s</Text>
        <Text style={styles.td}>{condition.extractFlow} m³/s</Text>
      </View>
      {usingEcool && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: "10px",
            paddingHorizontal: 6,
            paddingVertical: 4,
            borderRadius: 10,
            backgroundColor: "#fff",
          }}
        >
          <Image src={ECoolIcon} style={{ width: "10px" }} />
          <Text style={{ marginLeft: 6, fontWeight: "medium" }}>
            Using evaporative cooling
          </Text>
        </View>
      )}
    </View>
  );
};

export default ConditionTablePDF;
