import React from "react";
import { MachineIcon } from "../../basic/MachineIcon";
import {
  Condition,
  ConditionResult,
  Machine,
  StaticSimResult,
  SimulationJob,
} from "../../../model/dataTypes";
import InfoTag from "../../basic/InfoTag";
import GraphicSVG from "components/resultDiagram/GraphicSVG";
import { useMaxHeightTransition } from "utility/useMaxHeightTransition";
import LoadingIcon from "components/basic/LoadingIcon/LoadingIcon";
import parseDisplayValue from "utility/parseDisplayValue";
import CoolingIcon from "components/input/basic/icons/CoolingIcon";
import CheckECool from "utility/CheckECool";
import ConditionInfo from "components/results/tables/ConditionInfo";

const twStyles = {
  td: "px-3 border border-gray-200 flex items-center",
  tableHead: "font-bold text-sm h-20 flex",
  tr: "bg-white h-12 flex",
  infoTd: "px-3 py-1",
  rightInfoTd: "",
};

export const ConditionResultsTable: React.FC<{
  staticSimResults: StaticSimResult[];
  condition: Condition;
  machines: Machine[];
}> = ({ staticSimResults, condition, machines }) => {
  const showECool = CheckECool(condition);

  let headers: ConditionResHeader[] = [
    {
      col: showECool ? "Cooling capacity" : "Heat recovered",
      id: "sum_power_total",
    },
    { col: "Supply temperature", id: "supply_temp_val" },
    { col: "Efficiency", id: "sum_outside_eff_total" },
    {
      col: "Outside pressure drop (heat exchanger)",
      id: "sum_outside_pd_total",
    },
    {
      col: "Extract pressure drop (heat exchanger)",
      id: "sum_extract_pd_total",
    },
    { col: "Supply humidity", id: "supply_hum_val" },
  ];

  const renderResults = (machine: Machine) => {
    const normalRes = staticSimResults.find(
      (staticSim) =>
        staticSim.conditionID === condition.id &&
        staticSim.machineID === machine.id &&
        !staticSim.ECOOL
    );
    const ecoolRes = staticSimResults.find(
      (staticSim) =>
        staticSim.conditionID === condition.id &&
        staticSim.machineID === machine.id &&
        staticSim.ECOOL
    );
    if (showECool)
      return (
        <div key={machine.id + "render"}>
          {normalRes?.job.result ? (
            <ConditionResultRowEcool
              machineID={machine.id}
              machineName={machine.name}
              eCoolEnabled={false}
              headers={headers}
              result={normalRes.job.result}
              heatExchangerDimensions={machine.heatExhangerDimensions}
              outerDimensions={machine.outerDimensions}
            />
          ) : (
            <ConditionResultStatusRow
              status={normalRes?.job.status}
              machineID={machine.id}
              machineName={machine.name}
              ecool={true}
            />
          )}
          {ecoolRes?.job.result ? (
            <ConditionResultRowEcool
              machineID={machine.id}
              machineName={machine.name}
              eCoolEnabled={true}
              headers={headers}
              result={ecoolRes.job.result}
              heatExchangerDimensions={machine.heatExhangerDimensions}
              outerDimensions={machine.outerDimensions}
            />
          ) : (
            <ConditionResultStatusRow
              ecool={true}
              status={ecoolRes?.job.status}
              machineID={machine.id}
              machineName={machine.name}
            />
          )}
        </div>
      );
    else
      return normalRes?.job.result ? (
        <ConditionResultRow
          machineID={machine.id}
          machineName={machine.name}
          headers={headers}
          result={normalRes.job.result}
          heatExchangerDimensions={machine.heatExhangerDimensions}
          outerDimensions={machine.outerDimensions}
        />
      ) : (
        <ConditionResultStatusRow
          ecool={false}
          status={normalRes?.job.status}
          machineID={machine.id}
          machineName={machine.name}
        />
      );
  };

  return (
    <div className="">
      <div className={`${twStyles.tableHead}`}>
        <div
          style={{ width: `${7}%` }}
          className={`flexsnone ${twStyles.td} bg-gray-100`}
        ></div>
        <div
          style={{ width: `${13}%` }}
          className={`${twStyles.td} bg-gray-100`}
        >
          Machine
        </div>
        {showECool && (
          <div
            style={{ width: `${10}%` }}
            className={`${twStyles.td} bg-gray-100`}
          >
            Evaporative cooling
          </div>
        )}

        <div
          style={{ width: `${showECool ? 70 : 80}%` }}
          className={`flex flex-col bg-gray-100`}
        >
          <div
            className={`flex-1 ${twStyles.td} w-full justify-between relative`}
          >
            <span className="mr-2">{condition.name}</span>
            <InfoTag mt={20} rightAlign className="no-print flex-none">
              <ConditionInfo condition={condition} />
            </InfoTag>
          </div>
          <div className={`flex-1 flex bg-gray-100`}>
            {headers.map((h) => (
              <div
                key={h.id}
                style={{ width: `${200 / (headers.length * 2 + 1)}%` }}
                className={`font-bold flex-row-reverse text-right text-xxs ${twStyles.td}`}
              >
                {h.col}
              </div>
            ))}
            <div
              style={{ width: `${100 / (headers.length * 2 + 1)}%` }}
              className={`font-bold flex-row-reverse text-right text-xxs ${twStyles.td} `}
            >
              Details
            </div>
          </div>
        </div>
      </div>
      {machines.map(renderResults)}
    </div>
  );
};

//different table for when manual E cool mode is enabled for condition:
export const ConditionResultsTableManual: React.FC<{
  staticSimResults: StaticSimResult[];
  condition: Condition;
  machines: Machine[];
}> = ({ staticSimResults, condition, machines }) => {
  let headers: ConditionResHeader[] = [
    {
      col: "Energy",
      id: "sum_power_total",
    },
    { col: "Supply temperature", id: "supply_temp_val" },
    { col: "Efficiency", id: "sum_outside_eff_total" },
    { col: "Outside pressure drop", id: "sum_outside_pd_total" },
    { col: "Extract pressure drop", id: "sum_extract_pd_total" },
    { col: "Supply humidity", id: "supply_hum_val" },
  ];

  const renderManualResults = (machine: Machine) => {
    const staticRes = staticSimResults.find(
      (staticSim) =>
        staticSim.conditionID === condition.id &&
        staticSim.machineID === machine.id
    );

    const result = staticRes?.job.result;
    const useEcool = !!result && result.extract_vap_val > 0;
    if (!result)
      return (
        <ConditionResultStatusRow
          ecool={true}
          status={staticRes?.job.status}
          machineID={machine.id}
          machineName={machine.name}
        />
      );
    else
      return (
        <ConditionResultRowEcool
          machineID={machine.id}
          machineName={machine.name}
          eCoolEnabled={useEcool}
          headers={headers}
          result={result}
          heatExchangerDimensions={machine.heatExhangerDimensions}
          outerDimensions={machine.outerDimensions}
        />
      );
  };

  return (
    <div className="">
      <div className={`${twStyles.tableHead}`}>
        <div
          style={{ width: `${7}%` }}
          className={`${twStyles.td} bg-gray-100`}
        ></div>
        <div
          style={{ width: `${13}%` }}
          className={`${twStyles.td} bg-gray-100`}
        >
          Machine
        </div>

        <div
          style={{ width: `${10}%` }}
          className={`${twStyles.td} bg-gray-100`}
        >
          Evaporative cooling
        </div>

        <div
          style={{ width: `${70}%` }}
          className={`flex flex-col bg-gray-100`}
        >
          <div
            style={{ height: "50%" }}
            className={`${twStyles.td} w-full justify-between relative`}
          >
            <span className="mr-2">{condition.name}</span>
            <InfoTag mt={20} rightAlign className="no-print flex-none">
              <ConditionInfo condition={condition} />
            </InfoTag>
          </div>
          <div style={{ height: "50%" }} className={`flex bg-gray-100`}>
            {headers.map((h) => (
              <div
                key={h.id}
                style={{ width: `${200 / (headers.length * 2 + 1)}%` }}
                className={`font-bold flex-row-reverse text-right text-xxs overflow-auto ${twStyles.td}`}
              >
                {h.col}
              </div>
            ))}
            <div
              style={{ width: `${100 / (headers.length * 2 + 1)}%` }}
              className={`font-bold flex-row-reverse text-right text-xxs ${twStyles.td} `}
            >
              Details
            </div>
          </div>
        </div>
      </div>
      {machines.map(renderManualResults)}
    </div>
  );
};

//Shows the status of the simulation
const ConditionResultStatusRow: React.FC<{
  machineID: string;
  machineName: string;
  status?: SimulationJob["status"];
  ecool: boolean;
}> = ({ machineID, machineName, status, ecool }) => {
  const renderStatus = () => {
    if (!status) return <div>Error loading</div>;
    else if (status === "leased") return <LoadingIcon />;
    else if (status === "failed") return <div>Simulation Failed</div>;
    else return <div className="text-gray-600">waiting</div>;
  };
  return (
    <div key={machineID + "status"}>
      <div className={`${twStyles.tr}`}>
        <div
          style={{ width: `${7}%` }}
          className={`${twStyles.td} flex justify-center`}
        >
          <MachineIcon machineID={machineID} className="w-14 -mx-2" />
        </div>
        <div
          style={{ width: `${13}%` }}
          className={`${twStyles.td} cursor-pointer flex justify-between`}
        >
          <span className="px-2">{machineName}</span>
        </div>
        {ecool && (
          <div
            style={{ width: `${10}%` }}
            className={`${twStyles.td} flex justify-between`}
          ></div>
        )}
        <div
          style={{ width: ecool ? `70%` : `80%` }}
          className={`${twStyles.td} flex items-center justify-center bg-gray-200`}
        >
          {renderStatus()}
        </div>
      </div>
    </div>
  );
};

const ConditionResultRowEcool: React.FC<{
  headers: ConditionResHeader[];
  result: ConditionResult;
  eCoolEnabled: boolean;
  machineID: string;
  machineName: string;
  heatExchangerDimensions: {
    depth: number;
    height: number;
    length: number;
  };
  outerDimensions: {
    depth: number;
    height: number;
    length: number;
  };
}> = ({
  headers,
  result,
  eCoolEnabled,
  machineID,
  machineName,
  heatExchangerDimensions,
  outerDimensions,
}) => {
  const { open, setOpen, style } = useMaxHeightTransition("0", "600px", 330);
  return (
    <div key={machineID + "eCool"}>
      <div className={`${twStyles.tr}`}>
        <div
          style={{ width: `${7}%` }}
          className={`${twStyles.td} flex justify-center`}
        >
          <MachineIcon machineID={machineID} className="w-14 -mx-2" />
        </div>
        <div
          style={{ width: `${13}%` }}
          className={`${twStyles.td} flex justify-between`}
        >
          <span className="px-2">{machineName}</span>
        </div>
        <div style={{ width: `${10}%` }} className={`${twStyles.td} flex`}>
          {
            <div
              className={`${
                eCoolEnabled ? "text-blue-600" : "text-gray-500"
              } flex`}
            >
              <CoolingIcon />
              <span className="ml-2">
                {eCoolEnabled ? "active" : "disabled"}
              </span>
            </div>
          }
        </div>
        {headers.map((h) => {
          let value = result[h.id];
          if (h.id === "sum_power_total") {
            value = value * -1;
          }
          return (
            <div
              style={{ width: `${140 / (headers.length * 2 + 1)}%` }}
              key={h.id}
              className={`${twStyles.td} flex-row-reverse`}
            >
              {parseDisplayValue(value, h.id)}
            </div>
          );
        })}
        <div
          style={{ width: `${70 / (headers.length * 2 + 1)}%` }}
          onClick={() => setOpen(!open)}
          className={`${twStyles.td} justify-center cursor-pointer`}
        >
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            style={{
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.2s ease",
            }}
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
      {open && (
        <div
          style={style}
          className="bg-gray-100 border-gray-200 w-full flex justify-center overflow-hidden"
        >
          <div className="w-full max-w-3xl" style={{ height: "600px" }}>
            <GraphicSVG
              simResult={result}
              heatExchangerDimensions={heatExchangerDimensions}
              outerDimensions={outerDimensions}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const ConditionResultRow: React.FC<{
  headers: ConditionResHeader[];
  result: ConditionResult;
  machineID: string;
  machineName: string;
  heatExchangerDimensions: {
    depth: number;
    height: number;
    length: number;
  };
  outerDimensions: {
    depth: number;
    height: number;
    length: number;
  };
}> = ({
  headers,
  result,
  machineID,
  machineName,
  heatExchangerDimensions,
  outerDimensions,
}) => {
  const { open, setOpen, style } = useMaxHeightTransition("0", "600px", 330);
  return (
    <div key={machineID + "condition"}>
      <div className={`${twStyles.tr}`}>
        <div
          style={{ width: `${7}%` }}
          className={`${twStyles.td} flex justify-center`}
        >
          <MachineIcon machineID={machineID} className="w-14 -mx-2" />
        </div>
        <div
          style={{ width: `${13}%` }}
          className={`${twStyles.td} flex justify-between`}
        >
          <span className="px-2">{machineName}</span>
        </div>

        {headers.map((h) => (
          <div
            style={{ width: `${160 / (headers.length * 2 + 1)}%` }}
            key={h.id}
            className={`${twStyles.td} flex-row-reverse`}
          >
            {parseDisplayValue(result[h.id], h.id)}
          </div>
        ))}
        <div
          style={{ width: `${80 / (headers.length * 2 + 1)}%` }}
          onClick={() => setOpen(!open)}
          className={`${twStyles.td} justify-center cursor-pointer`}
        >
          <svg
            className="fill-current h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            style={{
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.2s ease",
            }}
          >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
          </svg>
        </div>
      </div>
      {open && (
        <div
          style={style}
          className="bg-gray-100 border border-gray-200 w-full flex justify-center"
        >
          <div className="w-full max-w-3xl" style={{ height: "600px" }}>
            <GraphicSVG
              simResult={result}
              heatExchangerDimensions={heatExchangerDimensions}
              outerDimensions={outerDimensions}
            />
          </div>
        </div>
      )}
    </div>
  );
};

type ConditionResHeader = {
  col: string;
  id: keyof ConditionResult;
};
