import React, { createContext } from "react";
import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { fbConfig } from "./fbconfig";

export const FirebaseContext = createContext(app);

const FirebaseProvider: React.FC = ({ children }) => {
  if (!app.apps.length) {
    app.initializeApp(fbConfig);
  }
  return <FirebaseContext.Provider value={app}>{children}</FirebaseContext.Provider>;
};
export default FirebaseProvider;
