import React, { useEffect, useRef } from "react";

interface Props {}

const ScrollToOnAppear: React.FC<Props> = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref &&
      ref.current &&
      window.scrollTo({
        top: ref.current.offsetTop,
        left: 0,
        behavior: "smooth",
      });
  }, []);

  return <div ref={ref}>{children}</div>;
};

export default ScrollToOnAppear;
