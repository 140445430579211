import React from "react";
import Modal from "components/basic/Modal";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "reducers";
import {
  DELETE_FROM_SFP_TABLE,
  DISPLAY_PDF,
  HIDE_PDF_SETINGS,
  TOGGLE_SFP_EXT_PRESSURE,
  TOGGLE_SPECIFIC_FAN_POWER,
  UPDATE_SFP_FLOW_UNIT,
  UPDATE_SFP_TABLE,
} from "actions/pdfActionTypes";
import { gtw } from "globalTWStyles";
import ToggleButton from "components/basic/ToggleButton";
import { InputNum } from "components/input/basic/Input";
import uuid from "uuid";
import CloseIcon from "components/basic/Icons/CloseIcon";
import InlineDropdown from "components/results/tables/InlineDropdown";
import { Option } from "components/results/tables/InlineDropdown";

interface Props {}

const PdfSettings = (props: Props) => {
  const dispatch = useDispatch();
  const { projectName, author, showSFP, sfp_tables } = useSelector(
    (state: StoreState) => state.pdf
  );

  return (
    <Modal onClose={() => dispatch({ type: HIDE_PDF_SETINGS })}>
      <div className="bg-white max-h-3/4 w-1/2 shadow-xl z-40 px-4 py-4 overflow-auto rounded">
        <div className="font-bold">PDF Settings</div>
        <div className="italic text-sm">Admin settings</div>
        <div className="flex items-center">
          <div className="text-xs font-medium w-40 pr-2">Project Name</div>
          <input
            type="text"
            className={`${gtw.input} flex-grow`}
            value={projectName}
            onChange={(e) =>
              dispatch({
                type: "SET_PROJECT_NAME",
                payload: e.target.value.length > 0 ? e.target.value : undefined,
              })
            }
          />
        </div>
        <div className="flex items-center my-2">
          <div className="text-xs font-medium w-40 pr-2">Author</div>
          <input
            type="text"
            className={`${gtw.input} flex-grow`}
            value={author}
            onChange={(e) =>
              dispatch({
                type: "SET_AUTHOR",
                payload: e.target.value,
              })
            }
          />
        </div>
        <div className="flex items-center my-2">
          <div className="text-xs font-medium w-40 pr-2">
            Show specific fan power
          </div>
          <ToggleButton
            active={showSFP}
            onChange={() => {
              dispatch({ type: TOGGLE_SPECIFIC_FAN_POWER, payload: !showSFP });
            }}
          />
        </div>
        {showSFP &&
          sfp_tables.map((sfp) => {
            const showExtPressureExhaust =
              sfp.external_pl_exhaust !== undefined;
            return (
              <div
                className="py-2 border-t border-gray-300 relative w-full"
                key={sfp.id}
              >
                <div
                  className="absolute right-0 top-0 mt-3 cursor-pointer"
                  onClick={() =>
                    dispatch({ type: DELETE_FROM_SFP_TABLE, payload: sfp })
                  }
                >
                  <CloseIcon className="w-5 h-5 text-gray-500 hover:text-red-500 transition-colors duration-200" />
                </div>
                <div className="flex items-center my-2 text-xs">
                  <div className="font-medium w-1/2 pr-2">Table headline</div>
                  <input
                    type="text"
                    className={`w-[10%] pt-2 pb-2 ${gtw.input}`}
                    value={sfp.headline}
                    onChange={(e) => {
                      dispatch({
                        type: UPDATE_SFP_TABLE,
                        payload: { ...sfp, headline: e.target.value },
                      });
                    }}
                  />
                </div>

                <ListInputUnitSelector
                  title="Flow (supply)"
                  value={sfp.flow_supply}
                  units={[
                    { id: "m³/s", display: "m³/s" },
                    { id: "m³/h", display: "m³/h" },
                    { id: "l/s", display: "l/s" },
                  ]}
                  selectedUnit={sfp.flow_unit}
                  onChange={(val) => {
                    dispatch({
                      type: UPDATE_SFP_TABLE,
                      payload: { ...sfp, flow_supply: val },
                    });
                  }}
                  onSelectDropdown={(val: Option) => {
                    dispatch({
                      type: UPDATE_SFP_FLOW_UNIT,
                      payload: { sfp, updatedUnit: val.display },
                    });
                  }}
                />
                <ListInputUnitSelector
                  title="Flow (exhaust)"
                  value={sfp.flow_exhaust}
                  units={[
                    { id: "m³/s", display: "m³/s" },
                    { id: "m³/h", display: "m³/h" },
                    { id: "l/s", display: "l/s" },
                  ]}
                  selectedUnit={sfp.flow_unit}
                  onChange={(val) => {
                    dispatch({
                      type: UPDATE_SFP_TABLE,
                      payload: { ...sfp, flow_exhaust: val },
                    });
                  }}
                  onSelectDropdown={(val: Option) => {
                    dispatch({
                      type: UPDATE_SFP_FLOW_UNIT,
                      payload: { sfp, updatedUnit: val.display },
                    });
                  }}
                />

                <div className="flex items-center my-2 text-xs">
                  <div className="font-medium w-1/4 pr-2">
                    External Pressure drop{" "}
                    {showExtPressureExhaust && "(supply)"}
                  </div>
                  <div
                    className="w-1/4 text-blue-500"
                    onClick={() =>
                      dispatch({
                        type: TOGGLE_SFP_EXT_PRESSURE,
                        payload: sfp,
                      })
                    }
                  >
                    {!showExtPressureExhaust && (
                      <span className="cursor-pointer">add exhaust</span>
                    )}
                  </div>
                  <InputNum
                    className={`${gtw.input}`}
                    value={sfp.external_pl_supply}
                    unit={""}
                    onChange={(val) =>
                      dispatch({
                        type: UPDATE_SFP_TABLE,
                        payload: { ...sfp, external_pl_supply: val },
                      })
                    }
                  />
                  <div className="pl-2">Pa</div>
                </div>

                {showExtPressureExhaust && (
                  <>
                    <div className="flex items-center my-2 text-xs">
                      <div className="font-medium w-1/4 pr-2">
                        External Pressure drop (exhaust)
                      </div>
                      <div
                        className="w-1/4 text-red-500"
                        onClick={() =>
                          dispatch({
                            type: TOGGLE_SFP_EXT_PRESSURE,
                            payload: sfp,
                          })
                        }
                      >
                        <span className="cursor-pointer">remove</span>
                      </div>
                      <InputNum
                        className={`${gtw.input}`}
                        value={sfp.external_pl_exhaust!}
                        unit={""}
                        onChange={(val) =>
                          dispatch({
                            type: UPDATE_SFP_TABLE,
                            payload: { ...sfp, external_pl_exhaust: val },
                          })
                        }
                      />
                      <div className="pl-2">Pa</div>
                    </div>
                  </>
                )}
                <ListInput
                  title="Component pressure drop outside"
                  value={sfp.component_pl_outside}
                  unit="Pa"
                  onChange={(val) => {
                    dispatch({
                      type: UPDATE_SFP_TABLE,
                      payload: { ...sfp, component_pl_outside: val },
                    });
                  }}
                />
                <ListInput
                  title="Component pressure drop extract"
                  value={sfp.component_pl_extract}
                  unit="Pa"
                  onChange={(val) => {
                    dispatch({
                      type: UPDATE_SFP_TABLE,
                      payload: { ...sfp, component_pl_extract: val },
                    });
                  }}
                />
                <ListInput
                  title="SFP max"
                  value={sfp.sfp_max}
                  unit="kW/(m³/s)"
                  onChange={(val) => {
                    dispatch({
                      type: UPDATE_SFP_TABLE,
                      payload: { ...sfp, sfp_max: val },
                    });
                  }}
                />
                <ListInput
                  title="SFP Bypass (yearly mean)"
                  value={sfp.sfp_bypass}
                  unit="kW/(m³/s)"
                  onChange={(val) => {
                    dispatch({
                      type: UPDATE_SFP_TABLE,
                      payload: { ...sfp, sfp_bypass: val },
                    });
                  }}
                />
              </div>
            );
          })}

        {showSFP && (
          <div className="border-b py-2 border-gray-300">
            <button
              onClick={() => {
                dispatch({
                  type: UPDATE_SFP_TABLE,
                  payload: {
                    id: uuid(),
                    headline: "Specific fan power",
                    flow_supply: 0,
                    flow_exhaust: 0,
                    flow_unit: "m³/s",
                    external_pl_supply: 0,
                    component_pl_outside: 0,
                    component_pl_extract: 0,
                    sfp_max: 0,
                    sfp_bypass: 0,
                  },
                });
              }}
              className={`${gtw.btn}`}
            >
              Add extra Table
            </button>
          </div>
        )}

        <div className="mt-2 flex">
          <button
            className={`${gtw.btn} mr-1 flex-1`}
            onClick={() => dispatch({ type: DISPLAY_PDF })}
          >
            Generate pdf
          </button>
          <button
            className={`${gtw.btn} ml-1 flex-1`}
            onClick={() => dispatch({ type: HIDE_PDF_SETINGS })}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PdfSettings;

const ListInput: React.FC<{
  title: string;
  value: number;
  unit: string;
  onChange: (val: number) => void;
}> = ({ title, value, unit, onChange }) => {
  return (
    <div className="flex items-center my-2 text-xs">
      <div className="font-medium w-1/2 pr-2">{title}</div>
      <InputNum
        className={`${gtw.input}`}
        value={value}
        unit={""}
        onChange={(val) => onChange(val)}
      />
      <div className="pl-2">{unit}</div>
    </div>
  );
};

const ListInputUnitSelector: React.FC<{
  title: string;
  value: number;
  units: Option[];
  selectedUnit: string;
  onChange: (val: number) => void;
  onSelectDropdown: (selected: Option) => void;
}> = ({ title, value, units, selectedUnit, onChange, onSelectDropdown }) => {
  return (
    <div className="flex items-center my-2 text-xs">
      <div className="font-medium w-1/2 pr-2">{title}</div>
      <InputNum
        className={`${gtw.input}`}
        value={value}
        unit={""}
        onChange={(val) => onChange(val)}
      />
      <InlineDropdown
        options={units}
        selectedDisplay={selectedUnit}
        onSelect={onSelectDropdown}
        className="ml-1"
        arrowRightAlign
      />
    </div>
  );
};
