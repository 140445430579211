import React from "react";
import StatusNumber from "../statusNumber/StatusNumber";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../../reducers";
import { useHistory } from "react-router";
import LoadingIcon from "../../basic/LoadingIcon/LoadingIcon";
import { ResultActionTypes, ERROR_LOADING_SIMULATION } from "actions/resultActionTypes";
import { requestSimulationAction } from "actions/fsActions";

interface Props {}

const SubmitSimulation: React.FC<Props> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { showResults, requestingSimulation } = useSelector(
    (state: StoreState) => state.result
  );
  const { useAdminMode } = useSelector((state: StoreState) => state.user);
  const { conditions, machineOptions, autoSuggestMachine } = useSelector(
    (state: StoreState) => state.input
  );
  const conditionsValid = conditions.length > 0;
  const machinesValid =
    machineOptions.filter((m) => m.selected).length > 0 || autoSuggestMachine;
  const validInput = conditionsValid && machinesValid;
  const error = useSelector((state: StoreState) => state.result.simulationError);

  const startSimulation = () => {
    if (!validInput) {
      const errorMessage = `Please select atleast one ${
        !machinesValid ? (!conditionsValid ? "machine and case" : "machine") : "case"
      }`;
      dispatch<ResultActionTypes>({
        type: ERROR_LOADING_SIMULATION,
        payload: { errorMessage },
      });
    } else {
      if (!requestingSimulation) {
        useAdminMode ? history.push("/admin") : history.push("/");
        //start requesting the simulation:
        dispatch(requestSimulationAction());
      }
    }
  };

  return (
    <div className="flex items-center">
      <StatusNumber number={3} isActive={validInput} hideHr />
      <div className="flex-grow flex items-center">
        <hr className="flex-1 border-gray-400" />
        <button
          onClick={() => startSimulation()}
          className={`flex-none relative ${twStyle.btn}
          ${showResults ? twStyle.btnLoaded : twStyle.btnOrig}
        `}
        >
          <span className="z-10">{requestingSimulation ? "SIMULATING" : "SIMULATE"}</span>
          {requestingSimulation && (
            <span className="absolute right-0 mr-24 top-1/2 -mt-2">
              <LoadingIcon />
            </span>
          )}

          {error && (
            <div className="absolute left-0 bottom-0 text-center w-full -mb-6 text-red-600 italic font-xxs">
              {error}
            </div>
          )}
        </button>
        <hr className="flex-1 border-none" />
      </div>
    </div>
  );
};

const twStyle = {
  btn: "px-32 h-12 text-xs font-bold border-2 focus:outline-none",
  btnOrig: "border-gray-400 text-gray-700",
  btnLoaded: "bg-cm-blue border-cm-blue text-white",
};

export default SubmitSimulation;
