import { combineReducers } from "redux";
import { inputReducer, InputState } from "./inputReducer";
import { ResultState, resultReducer } from "./resultReducer";
import { userReducer, UserState } from "./userReducer";
import { PdfState, pdfReducer } from "./pdfReducer";

export interface StoreState {
  input: InputState;
  result: ResultState;
  user: UserState;
  pdf: PdfState;
}

export const rootReducer = combineReducers<StoreState>({
  input: inputReducer,
  result: resultReducer,
  user: userReducer,
  pdf: pdfReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
